import React, { useRef } from "react";
import JoditEditor from "jodit-react";

const TextEditor = ({
  inputValue,
  setValue,
  readonly,
  height,
  setInputValue,
}) => {
  const [editorValue, setEditorValue] = React.useState(inputValue);
  const [defaultValue, setDefaultValue] = React.useState(inputValue);
  const [bool, setBool] = React.useState(false);
  const editor = useRef(null);

  React.useEffect(() => {
    FullHeightAuto();
  }, [editor.current]);

  React.useEffect(() => {
    setValue(editorValue);
  }, [editorValue]);

  
  React.useEffect(() => {
    setDefaultValue(inputValue);
  }, [inputValue]);

  const DeleteLink = () => {
    const link = document.getElementsByClassName("jodit-status-bar-link");
    if (link && link.length > 0) {
      link[0].style.display = "none";
    }
  };

  const FullHeightAuto = (elem) => {
    const JWorkPlace = elem
      ? elem.parentElement
      : document.getElementsByClassName("jodit-workplace")[0];
    if (JWorkPlace) {
      setTimeout(() => {
        JWorkPlace.style.height = `calc(100% - ${"33px"})`;
      }, 1000);
      JWorkPlace.classList.add("joditHeight");
      JWorkPlace.firstChild.style.height = "100%";
      if (!elem) {
        JWorkPlace.firstChild.onfocus = function (e) {
          FullHeightAuto(e.target);
          DeleteLink();
        };
      }
    }
  };

  return (
    <div style={{ height: `calc(100% - ${height})` }}>
      <JoditEditor
        ref={editor}
        value={defaultValue}
        config={{
          height: `calc(100% - ${height})`,
          // className: "testsas",
          // iframe: true,
          // statusbar: false,
          readonly: readonly?readonly:false,
          addNewLine: false,
          showCharsCounter: false,
          showWordsCounter: false,
          showXPathInStatusbar: false,
          // autoFocus: true,
          // height:"100%",
          // maxHeight:"100",
          saveModeInStorage: true,
          // iframeStyle: 'body{margin: 0px;}',
          allowResizeX: false,
          allowResizeY: false,
          toolbarButtonSize: "small",
          toolbarAdaptive: false,
          saveModeInStorage: false,
          saveHeightInStorage: false,
          disablePlugins:
            "add-new-line,about,clean-html,placeholder,class-span,inline-popup",
          buttons:
            "bold,italic,underline,font,fontsize,indent,outdent,left,brush,undo,redo,source,print",
          uploader: {
            insertImageAsBase64URI: true,
          },
        }}
        tabIndex={1} // tabIndex of textarea
        onChange={(newContent) => {
          if (bool) {
            if (defaultValue !== newContent) {
              setInputValue(newContent);
            }
          } else {
            setBool(true);
            setDefaultValue(newContent);
          }
          //
        }}
        onBlur={(newContent, e) => {
          // if (defaultValue !== newContent) {
          // DeleteLink()
          if(e.relatedTarget.className.includes("CloseButtonForGrid"))
            return
          FullHeightAuto();
          setEditorValue(newContent);
          // }
        }} // preferred to use only this option to update the content for performance reasons
      />
    </div>
  );
};

export default TextEditor;
