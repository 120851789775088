import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { ModalProgressProps } from "../../ComponentInterface";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Modal from "@mui/material/Modal";
import Paper, { PaperProps } from "@mui/material/Paper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import URL, { AxiosRequest } from "../../Url";
import axios from "axios";
import Draggable from "react-draggable";
import ReactDOM from "react-dom";
import { CheckModalBackGrounds, tokenProcessingTest } from "../TokenProcessing";
import ModalContainer from "../../MainPage/Module/ModalContainer";
// import ChangeStatusProgressFooter from "../../MainPage/NotWorkArea(Side&Head)/ChangeStatusProgress";
import { isEmptyObject } from "../../MainPage/Tools/Tools";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props: PaperProps) {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function ChildModal(props: any) {
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    setOpen(true);
  }, [props.Path]);

  const handleClose = () => {
    setOpen(false);
  };

  async function handleCloseButton() {
    props.Cancel();
    setOpen(false);
  }

  return (
    <React.Fragment>
      <Draggable>
        <Modal hideBackdrop open={open}>
          <Box sx={{ ...style, width: "20%" }}>
            <h2 id="child-modal-title">Подтверждение</h2>
            <p id="child-modal-description">Остановить процесс?</p>
            <Button
              size="small"
              variant="outlined"
              style={{ textTransform: "none", marginRight: "10px" }}
              onClick={handleCloseButton}
            >
              Да
            </Button>
            <Button
              size="small"
              variant="outlined"
              style={{ textTransform: "none" }}
              onClick={handleClose}
            >
              Нет
            </Button>
          </Box>
        </Modal>
      </Draggable>
    </React.Fragment>
  );
}

export default function ModalProgress(props: any) {
  const [open, setOpen] = React.useState(true);
  const [activeStep, setActiveStep] = React.useState(0);
  const [count, setCount] = React.useState<number>(0);
  const [data, setData] = React.useState<any>({});
  const [Title, setTitle] = React.useState();
  const [AllSteps, setAllSteps] = React.useState<any>([]);
  // const [path, setPath] = React.useState("");
  const [PrevToken, setPrevToken] = React.useState("");
  const [TextLoad, setTextLoad] = React.useState("");
  const [currentLoad, setCurrentLoad] = React.useState("Load");
  const dialogref: any = React.useRef(null);

  React.useEffect(() => {
    if (data.error) {
      const Message = `Ошибка выполнения операции на сервере: ${data.error.content}`;
      ReactDOM.render(
        <ModalContainer dlgType={"Ошибка"} content={Message} />,
        document.getElementById("RenderDefault")
      );
    }
    tokenProcessing(data);
    if (dialogref.current) {
      dialogref.current.hideDialog = () => {
        setOpen(false);
        const footerProcess = document.getElementById("footerProgress");
        footerProcess?.firstChild?.remove();
        ReactDOM.render(<></>, footerProcess);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    tokenProcessing(props.Json);
    OpenDialog();
    setActiveStep(0);
    setCurrentLoad("Load");
    setTextLoad("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.Json]);

  function setCharAt(str: string, index: number, chr: any) {
    if (index > str.length - 1) return str;
    return str.substring(0, index) + chr + str.substring(index + 1);
  }

  async function EmptyRequest(RequestID: string, load?: boolean) {
    let params = new Map(),
      data;
    data = { Result: "" };
    params.set("prefix", "project");
    params.set("comand", "ResumeRequest");
    params.set("RequestID", RequestID);
    params.set("WSM", "1");
    AxiosRequest(load ? load : false, params, data).then((res: any) => {
      setData(res);
    });
  }

  const normalise = (value: any, MAX: any) =>
    ((Number(value) - 0) * 100) / (Number(MAX) - 0);

  async function tokenProcessing(json: any) {
    // (json,props)
    if (json.Break !== undefined) {
      let returnSmth = [],
        Token,
        RequestID: any;
      Token = json.Token;
      RequestID = json.Params.RequestID;

      if (Token === "ShowProgressDialog") {
        // let Path
        let Sections, Title, SectionsArray, Section: any;
        // Path = json.Params.Path;
        Sections = json.Params.Sections;
        Title = json.Params.Title;

        if (Sections !== undefined) {
          SectionsArray = Sections.split(",");
          for (const value of Object.values(SectionsArray)) {
            let FixedSection: any;
            FixedSection = value;
            Section = value;

            for (const [key, value] of Object.entries(Section)) {
              if (value === Section[Number(key) + 1] && value === '"') {
                FixedSection = setCharAt(FixedSection, Number(key), "");
              }
            }
            if (
              FixedSection[0] === '"' &&
              FixedSection[FixedSection.length - 1] === '"'
            ) {
              FixedSection = setCharAt(FixedSection, 0, "");
              FixedSection = setCharAt(
                FixedSection,
                FixedSection.length - 1,
                ""
              );
            }
            returnSmth.push(FixedSection);
          }
        }
        setOpen(true);
        setAllSteps(returnSmth);
        setTitle(Title);
        setPrevToken(Token);
        EmptyRequest(RequestID);
      } else if (Token === "SetProgressSection") {
        let Index;
        Index = Number(json.Params.Index);
        if (isNaN(Index)) Index = 0;
        setActiveStep(Index);
        setPrevToken(Token);
        EmptyRequest(RequestID);
      } else if (Token === "StepProgress") {
        let Index, MAX;
        Index = json.Params.Index;
        if (isNaN(Index)) Index = 0;
        MAX = json.Params.Count;
        setCount(normalise(Index, MAX));
        setPrevToken(Token);
        EmptyRequest(RequestID);
      } else if (Token === "SetProgressLabel") {
        let Text;
        Text = json.Params.Text;
        if (PrevToken === "StepProgress") {
          setCurrentLoad("Mix");
          setTextLoad(Text);
        } else if (
          PrevToken === "SetProgressSection" ||
          PrevToken === "SetProgressLabel" ||
          PrevToken === "ShowProgressDialog"
        ) {
          setTextLoad(Text);
          setCurrentLoad("Text");
        }
        setPrevToken(Token);
        EmptyRequest(RequestID);
      } else if (Token === "HideProgressDialog") {
        EmptyRequest(RequestID, true);
        setPrevToken(Token);
        setOpen(false);
        ReactDOM.render(<></>, document.getElementById("RenderModalSub"));
        // ReactDOM.render(<></>, document.getElementById('RenderModal'));
      } else if (Token === "ClearOutPut") {
        EmptyRequest(RequestID);
      } else if (Token === "OutPutText") {
        EmptyRequest(RequestID);
      } else {
        // if (Token === "ChangeStatusProgress") {
          tokenProcessingTest(json, { func: setData, from: "ModalProgress" });
        // } else {
        //   tokenProcessingTest(json, {
        //     func: props.setReturnValue,
        //     from: props.from,
        //   });
        // }
      }
    } else {
      if (props.setReturnValue !== undefined && !isEmptyObject(json)) {
        setOpen(false);
        if (props.from !== "Forms") {
          ReactDOM.render(<></>, document.getElementById("footerProgress"));
          props.setReturnValue(json);
        } else if (props.from === "Forms" && json.Form !== undefined)
          props.setReturnValue(json);
      }
    }
  }

  function RenderCancelModal() {
    ReactDOM.render(
      <ChildModal Path={props.path} openIt={open} Cancel={Cancel} />,
      document.getElementById("RenderModalSub")
    );
  }

  function OpenDialog() {
    if (props.open) {
      setOpen(true);
    }
  }

  function Cancel() {
    let params = new Map();
    params.set("prefix", "programs");
    params.set("comand", "StopProcess");
    params.set("smart", "1");
    params.set("Path", props.path);
    AxiosRequest(true, params).then((data) => {
      // setData(res.data)
      setOpen(false);
      const footerProcess = document.getElementById("footerProgress");
      footerProcess?.firstChild?.remove();
      ReactDOM.render(<></>, footerProcess);
      if (data.Params) {
        EmptyRequest(data.Params.RequestID);
      }
    });
    // XMLrequest(params);
  }

  return (
    <div>
      <Dialog
        maxWidth={"sm"}
        id={"ProgressDialog" + props.Json.Params.RequestID}
        fullWidth
        style={{ overflow: "hidden", height: "100%" }}
        open={open}
        ref={dialogref}
        TransitionComponent={Transition}
        keepMounted
        PaperComponent={PaperComponent}
        sx={{
          "&.MuiDialog-container": {
            zIndex: 1000 + CheckModalBackGrounds(),
          },
          zIndex: 1000 + CheckModalBackGrounds(),
        }}
      >
        <DialogTitle>{Title}</DialogTitle>
        <DialogContent style={{ paddingLeft: "35px" }}>
          <Grid
            style={{ display: "block" }}
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
          >
            <Grid item id="Steps">
              <Stepper
                activeStep={activeStep}
                orientation="vertical"
                connector={<></>}
              >
                {AllSteps.map((step: any) => (
                  <Step key={step}>
                    <StepLabel>{step}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ height: "60px", paddingRight: "4px" }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              style={{ width: "78%", paddingRight: "26px", paddingTop: "4px" }}
            >
              <Grid style={{}}>
                {currentLoad === "Text" || currentLoad === "Mix" ? (
                  TextLoad
                ) : (
                  <></>
                )}
              </Grid>
              <Grid
                item
                style={{
                  marginBottom: currentLoad === "Mix" ? "21px" : "01px",
                }}
              >
                {currentLoad === "Load" || currentLoad === "Mix" ? (
                  <LinearProgress
                    variant="determinate"
                    value={count}
                    sx={{ ".MuiLinearProgress-bar": { transition: "none" } }}
                  />
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
            <Grid item style={{ paddingLeft: "20px" }}>
              <Button
                size="small"
                variant="outlined"
                style={{ textTransform: "none" }}
                onClick={RenderCancelModal}
              >
                Отмена
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
