import { handleParameters } from "../../../Tools/Requests";
import { getParamsObject } from "../../../Tools/Tools";
import AcoountsTableForTabs from "./detailComponents/AccountsTableForTabs";
import AddressForTabs from "./detailComponents/AddressForTabs";
import ClsBookTableForTabs from "./detailComponents/ClsBookTableForTabs";
import DocumentsForTabs from "./detailComponents/DocumentsForTabs";
import ParamsForTabs from "./detailComponents/ParamsForTabs";

export function getDetailContent(selector, props, data) {
  switch (selector) {
    case "accounts":
      return <AcoountsTableForTabs props={{ ...props, ...data }} />;
    case "documents":
      return <DocumentsForTabs props={{ ...props, ...data }} />;
    case "params":
      return <ParamsForTabs props={{ ...props, ...data }} />;
    case "addressParams":
      return <AddressForTabs props={{ ...props, ...data }} />;
    case "clsbook":
      return <ClsBookTableForTabs props={{ ...props, ...data }} />;
    default:
      return;
  }
}

export function updateTabContentFunc(data) {
  switch (data.selector) {
    case "params":
      data.onRequest(data).then((res) => {
        const parambox = data.element.querySelector("#paramsBox");
        if (parambox && parambox.update) {
          parambox.update(res);
        }
      });
      break;

    case "addressParams":
      data.onRequest(data).then((res) => {
        const parambox = data.element.querySelector("#paramsBox");
        parambox.update(
          getParamsObject(res, data.selectedRecordID, res.Caption, data.Module)
        );
      });
      break;
    case "documents":
      const docDiv = data.element.querySelector(`#Documents${data.SectionID}`);
      if (docDiv && docDiv.refreshMainGrid) {
        docDiv.refreshMainGrid({
          DocCfgID: data.detailItem.DocCfgID
            ? data.detailItem.DocCfgID
            : data.detailItem.ObjType,
          ParentID: data.selectedRecordID,
        });
      }
      break;
    case "accounts":
      const accBox = data.element.querySelector(`#accBox`);
      if (accBox && accBox.refreshMainGrid) {
        accBox.refreshMainGrid({
          ObjType: data.detailItem.ObjType
            ? data.detailItem.ObjType
            : data.detailItem.Params.ObjType,
          GroupID: data.selectedRecordID,
        });
      }
      break;

    case "clsbook":
      const clsbookBox = data.element.querySelector(`#clsBookBox`);
      if (clsbookBox && clsbookBox.refreshMainGrid) {
        clsbookBox.refreshMainGrid({
          ObjType: data.detailItem.ObjType
            ? data.detailItem.ObjType
            : data.detailItem.Params.ObjType,
          GroupID: data.selectedRecordID,
        });
      }
      break;
  }
}
