import { AxiosRequest } from "../../../Url";
import { PostHandleTable } from "../../Tools/Requests";
import {
  defaultFieldInfo,
  getModalSectionID,
  getRightsData,
} from "../../Tools/Tools";
import { CreateCollapsibleRecords } from "../GridTools/collapsibleRecordsTools";
import { parseColumns } from "../GridTools/parseColumnTools";

export async function clsBookGrid(grid, source, props, data) {
  source.onHandleRequest = HandleRequest(props);
  //создание стилей для сворачивающихся записей
  source.CreateCollapsibleRecords = CreateCollapsibleRecords;

  await source.open();

  const codeField = source.fields[source.getFieldIndex("CODE")];
  //создание дефолтных полей()
  let columns = { title: [] },
    dest = {};
  dest.fieldName = "CODE";
  dest.title = "Код";
  dest.width = codeField ? codeField.displayWidth * 15 : 120;
  dest.level = 0;
  dest.fieldInfo = { ...defaultFieldInfo };
  columns.title.push(dest);
  dest = {};
  dest.fieldName = "$CheckBoxx";
  dest.title = "Множественный выбор";
  dest.type = "3";
  dest.width = 21;
  dest.level = 0;
  dest.hide = props.MultiSel ? false : true;
  dest.fieldInfo = {
    DataType: "",
    EditStyle: "4",
    Options: "",
    TitleAjust: "",
    TextAjust: "",
    Module: "",
    Values: [],
    Image: 21,
    NoTitle: true,
  };
  columns.title.push(dest);
  dest = {};
  dest.fieldName = "NAME";
  dest.title = "Наименование";
  dest.width = 300;
  dest.level = 0;
  dest.fieldInfo = { ...defaultFieldInfo };
  columns.title.push(dest);
  //парсинг полей запроса GetTableLayout
  if (data.json) {
    source.rights = getRightsData(data.json.Rights);

    columns = parseColumns(data.json, columns);
    grid.setColumns(columns);
    source.setKeyFieldName(
      data.json.keyField ? data.json.keyField.toUpperCase() : "CODE"
    );
    source.rights = getRightsData(data.json.Rights);
  } else grid.setColumns(columns);

  source.MultiCLsSelect = props.MultiSel ? true : false;
  const Selection = {};
  if (props.Selection) {
    const arrSelection = props.Selection.split(",");
    arrSelection.forEach((item) => {
      Selection[item.match(/\d+/)[0]] = item;
    });
  }
  source.ClsSelection = Selection;

  if (data.setSelectedRecord) {
    source.onRecordIndexChanged = async function (source) {
      data.setSelectedRecord(source.getFieldTextSync("ID"));
    };
  }
  grid.setImageFields(["ID", "$CfgID"]);
  source.booksGetImage = (value, field) => {
    if (value < 0) return 3;
    if (field === "$CfgID" && value > 0 && source.getFieldTextSync("ID") > 0)
      return 2;
  };

  await grid.setSource(source);
  source.onRecordCollapsed = async function (event) {
    let RecordIndex;
    if (event.currentTarget !== undefined) {
      RecordIndex = event.currentTarget.getAttribute("recordindex");
    } else {
      RecordIndex = event;
    }
    let params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "RecordCollapsed")
      .set("SectionID", props.SectionID)
      .set("ObjType", props.ObjType)
      .set("RecordIndex", RecordIndex);
    await AxiosRequest(true, params);
    await grid.refreshSource("!");
    if (data.setCountRecords) data.setCountRecords(source.recordCount);
    return;
  };
  if (props.ID) {
    await grid.refreshSource(props.ID);
  } else await grid.refreshSource();
  grid.updateGridSize();
  if (data.setCountRecords) data.setCountRecords(source.recordCount);
  if (data.setSelectedRecord) {
    data.setSelectedRecord(source.getFieldTextSync("ID"));
  }
}

function HandleRequest(props) {
  return function onHandleRequest(request) {
    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "HandleTable")
      .set("ObjType", props.ObjType)
      .set("SectionID", props.SectionID)
      .set("CanChangeActual", "1");
    return AxiosRequest(true, params, request);
  };
}
