import {
  Autocomplete,
  Grid,
  Tooltip,
  autocompleteClasses,
} from "@mui/material";
import React from "react";

import { isEmptyObject } from "../MainPage/Tools/Tools";
import { IMaskInput } from "react-imask";
import { AxiosRequest } from "../Url";

import DateCalendarEditor from "./EditorComponents/DateCalendarEditor";
import CalendarButton from "./EditorComponents/CalendarButton";
import UpDownButtons from "./EditorComponents/UpDownButtons";
import PickListEditor from "./EditorComponents/PickListEditor";
import ModalButton from "./EditorComponents/ModalButton";
import CheckBoxMultiCheck from "../MainPage/Sections/ElementsSections/CheckBoxMultiCheck";
import CheckboxList from "./EditorComponents/CheckBoxList";
import { SetLoadingCursor } from "../TokenProcessing/TokenProcessing";
import DetailsButton from "./EditorComponents/DetailsButton";

// запрос для выпадающего списка
async function getParamValues(props, editor, checkList) {
  // внешний запрос
  if (props.onDropDownList) {
    const result = await props.onDropDownList(
      {
        module: props.type,
        events: props.onEvents,
        name: props.name,
        fieldname: props.fieldname,
        datatype: props.datatype,
        objref: props.objref,
        path: props.path,
        ID: props.id,
        current: props.current,
        editor: editor,
        record: props.record,
        onlist: props.onlist,
      },
      checkList
    );
    if (isEmptyObject(result)) {
      return [{ label: "Нет данных", id: "-1", disabled: true }];
    }

    return result;
  } else return [{ label: "Нет данных", id: "-1", disabled: true }];
}

// Функция для открытия модального окна
function openModalWindow(props, json, subData) {
  if (props.onEditButtonClick) {
    props.onEditButtonClick({
      events: props.onEvents,
      name: props.name,
      datatype: props.datatype,
      objref: subData.props.ObjRef ? subData.props.ObjRef : props.objref,
      path: props.path,
      json: json,
      subData: subData,
      styleWindow: props.styleWindow,
      setValue: subData.props.setValue,
      setInputValue: subData.props.setInputValue,
      addInfo: props.addInfo,
    });
  }
}
// запрос для данных модального окна
const GetDialogParam = (module, ObjType, objref, multisel) => {
  let param = new Map(),
    json;
  param.set("prefix", module);
  param.set("comand", "GetDialogParams");
  param.set("ObjType", ObjType);
  param.set("ObjRef", objref);
  if (multisel) param.set("MultiSel", "1");
  json = AxiosRequest(true, param);
  return json;
};

// функции для кнопок инкремента и декремента
function incValue(inputValue, setValue, valueChanged) {
  let val = Number(inputValue);
  if (Number.isNaN(val)) return;
  setValue(`${val + 1}`);
  valueChanged.current = true;
}
function decValue(value, setValue, valueChanged) {
  let val = Number(value);
  if (Number.isNaN(val)) return;
  setValue(`${val - 1}`);
  valueChanged.current = true;
}

function fixValue(value) {
  return value.replaceAll("-", "");
}

// Входные данные:
// editStyle - свойство определяющее какие кнопки будут отрисованы
// objref - id элемента нужен для позиционирования в справочниках списках и тд
// value - входное значение инпута
// onEdit - функция которая вызывается на любое редактирование
// onDropDownList - функция вызывается на клик по кнопке списка(editstyle === 1) и должна возвращать список в виде [{ label: "название", id: "objref или другой идентификатор", disabled: true(опционально делает элемент недоступным в списке) }]
// onEditButtonClick - функция для клика на кнопку открытия диалогового окна
// onEditList - функция вызывается при не дефолтном поведении на клик элемента выпадающего списка
// onChange - функция вызывется при изменении значения inputValue
// addInfo - добавочные данные для редактирования таблиц
// list - список в виде Elemen1\r\nElement2\r\n...(если список получается не по запросу а от внешнего компонента)
// placeholder - не стандартный заголовок
// editor - объект в который запишется функция редактирования
// getEdition - функция в который запишутся хуки управления состоянием значения инпута
// grid - отменяет обычное редактирование и создает отдельный слушатель событий на clickAway
// clickAway - функция которая вызывается в отдельном слушателе событий при отмене стандартной
// disabled - свойство отключает полностью весь функционал редактора
// readOnly - отключает ввод с клавиатуры (кнопки работают)
// record - элемент в котором хранятся данные для внешнего редактирования(Например в параметрах)
// остальные параметры используются только для передачи в функции onEdit, onEditButtonClick, onDropDownList (при необходимости добавить новые, их нужно добавлять в ручную везде, где в них есть потребность)

// Общий прицип работы: Внешние данные и значение -> Editor -> редактирование(включая выбор из списка) -> Вызов функции onEdit(Новое значение и внешние данных переданные изначально)
//                                                          -> редактирование через модальное окно -> Вызов функций onEditButtonClick(пердача всех нужных данных, включая функцию onEdit) -> редактирование -> Вызов функции onEdit внутри компонента с модальным окном(Новое значение и внешние данных переданные изначально)

export default function TestEditor(props) {
  const EditStyle_PickList = 1 & props.editStyle; // Выпадающий список реализован
  const EditStyle_Calendar = 2 & props.editStyle; // Календарь реализован
  const EditStyle_Check = 4 & props.editStyle; // Чекбокс реализован
  const EditStyle_UpDown = 8 & props.editStyle; // Инкремент и Декремент реализован
  const EditStyle_Ellipsis = 16 & props.editStyle; // Кнопка открытия модального окна реализована
  const EditStyle_CheckList = 32 & props.editStyle; // Список с множественным выбором реализован
  const EditStyle_ReadOnly = 64 & props.editStyle; // Только для чтения(работает только для ввода с клавиатуры, кнопки будут работать штатно)
  const EditStyle_EditList = 128 & props.editStyle; // не реализовано
  const EditStyle_ColorBox = 256 & props.editStyle; // не реализовано
  const EditStyle_AutoList = 512 & props.editStyle; // не реализовано
  const EditStyle_Password = 1024 & props.editStyle; // не реализовано

  const buttonCalendar = React.useRef(null);
  const buttonPickList = React.useRef(null);

  const [styleInput, setStyleInput] = React.useState({});

  const [openTooltip, setOpenTooltip] = React.useState(false);

  function onOpenToolTip() {
    setOpenTooltip(true);
  }

  function onCloseToolTip() {
    setOpenTooltip(false);
  }

  //////////////////////////////////////////////////////////////////////////////
  // регекс проверки правильности написания даты
  const dateRegexp =
    /^(?:(?:31(\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

  // функции для Popover(выпадающим полем) с выбором даты
  const [anchorElDate, setAnchorElDate] = React.useState(null);
  // клик на дату
  const handleClickDate = (event) => {
    if (EditStyle_Ellipsis) {
      modalButton.current.click();
      return;
    }
    setAnchorElDate(event.currentTarget);
    if (EditStyle_PickList) setOpenList(false);
  };
  // функция на закрытие
  const handleCloseDate = () => {
    setAnchorElDate(null);
  };
  // переменные контроля открытия даты
  const openDate = Boolean(anchorElDate);
  const idDate = openDate ? "calendar" : undefined;
  //////////////////////////////////////////////////////////////////////////////

  // функции для Popover с чеклистом
  const [anchorElForCheckList, setAnchorElForCheckList] = React.useState(null);
  // функции на клик и закрытие выпадающего списка
  const handleClickForCheckList = (event) => {
    setAnchorElForCheckList(event.currentTarget);
  };
  const handleCloseForCheckList = async () => {
    // valueChanged.current = true;
    setAnchorElForCheckList(null);

    await props.onEdit({
      record: props.record,
      tag: checkListObjRef.current,
      value: inputValue,
      textchanged: "0",
      setValue: setValue,
      setInputValue: setInputValue,
      addInfo: props.addInfo,
      checkList: true,
    });
  };
  // переменные контроля чеклиста
  const openForCheckList = Boolean(anchorElForCheckList);
  const idForCheckList = openForCheckList ? "checklist" : undefined;

  // состояние элементов списка
  const [checkedList, setCheckedList] = React.useState([]);
  const checkListObjRef = React.useRef();

  const handleToggle = (ev, value) => {
    const currentIndex = checkedList.indexOf(Number(value.id));
    const indexItem = [...ev.target.closest("UL").children].indexOf(
      ev.target.closest("LI")
    );
    const newChecked = [...checkedList];

    if (currentIndex === -1) {
      newChecked[indexItem] = Number(value.id);
      checkListObjRef.current =
        Number(checkListObjRef.current) + Number(value.id);
    } else {
      newChecked[indexItem] = -1;
      checkListObjRef.current =
        Number(checkListObjRef.current) - Number(value.id);
    }

    setInputValue(getCheckListInputValue(newChecked));

    setCheckedList(newChecked);
  };

  function getCheckListInputValue(newChecked) {
    let result = [];
    paramValuesItems.forEach((item, index) => {
      if (newChecked[index] !== -1) {
        result.push(item.label);
      }
    });
    return result.join(",");
  }
  //////////////////////////////////////////////////////////////////////////////

  // состояние открытия выпадающего списка
  const [openList, setOpenList] = React.useState(false);

  // размеры контейнеров внутри editor
  const [width, setWidth] = React.useState({
    input: "100%",
    start: "0%",
    end: "0%",
  });

  // внутреннее значение в виде {label, id}
  const [value, setValue] = React.useState({
    label: props.editMask ? fixValue(props.value) : props.value,
    id: props.objref,
  });

  // значение видимого input в виде string
  const [inputValue, setInputValue] = React.useState(
    props.editMask ? fixValue(props.value) : props.value
  );
  // const [checkState, setCheckState] = React.useState(props.checked);

  const [selection, setSelection] = React.useState(props.selection);

  // ссылка на контейнер с элементами в начале редактора
  const startContentRef = React.useRef(null);
  // ссылка на контейнер с элементами в конце редактора
  const endContentRef = React.useRef(null);
  // ссылка на input
  const input = React.useRef(null);
  // ссылка на маску input
  const maskRef = React.useRef(null);
  // ссылка на корневой элемент
  const autoCompleteValue = React.useRef(null);
  // ссылка на кнопку модального окна
  const modalButton = React.useRef();

  const placeholder = React.useRef("");

  // переменная для контроля изменения в input
  const valueChanged = React.useRef();
  const blurValue = React.useRef();

  // Данные для выпадающего списка
  const [paramValuesItems, setParamValues] = React.useState([
    { label: "Нет данных", id: "-1", disabled: true },
  ]);

  // переменная для контроля фокуса
  const focus = React.useRef();

  // функция для кнопки открытия выпадающего списка
  const onPickListClick = async (ev) => {
    // заполнение выпадающего списка данными
    if (EditStyle_CheckList) {
      // чеклист
      if (!props.onlist && props.list && props.list !== "") {
        const list = props.list.split("\r\n");
        if (list.length && list[list.length - 1] === "") list.pop();
        setParamValues(
          list.map((item, index) => {
            return { label: item, id: Math.pow(2, index) };
          })
        );
      } else {
        setParamValues(
          await getParamValues(props, autoCompleteValue.current, true)
        );
      }
      handleClickForCheckList(ev);
      setAnchorElForCheckList(ev.target);

      return;
    }
    if (EditStyle_PickList) {
      // обычный список
      if (!props.onlist && props.list && props.list !== "") {
        const list = props.list.split("\r\n");
        if (list.length && list[list.length - 1] === "") list.pop();
        setParamValues(
          list.map((item, index) => {
            return { label: item, id: index };
          })
        );
      } else {
        setParamValues(await getParamValues(props, autoCompleteValue.current));
      }
      setOpenList(!openList);
    }
  };

  React.useEffect(() => {
    if (inputValue === undefined) setInputValue("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  // действия после монтирования компонента
  React.useEffect(() => {
    // установка стилей кнопок
    // if (startContent === 0 && endContent === 0) {
    getInnerButtons();
    // }
    // установка заголовка, если он есть
    if (EditStyle_Check) {
      placeholder.current = "";
    } else if (!props.editMask) {
      placeholder.current =
        props.placeholder !== undefined ? props.placeholder : "не выбрано";
    }
    // определение переменной следящей за фокусом на инпуте
    focus.current = false;

    // установка первичного значения для чек листа
    if (EditStyle_CheckList) {
      checkListObjRef.current = props.objref ? props.objref : "0";
    }

    // передача функции редактирования на внешние модули
    if (props.editor) {
      props.editor.onEdit = onEditValue;
    }

    setStyleInput({
      margin: "0",
      height: "100%",
      width: "100%",
      color: "#212529",
      backgroundColor: "#ffffff",
      border: "0",
      borderRadius: "0",
      outline: "none",
      // padding: "0",
      fontSize: "13px",
      fontFamily: "Roboto",
      letterSpacing: "0px",
      backgroundColor: props.background ? "whitesmoke" : "inherit",
      ...props.styleInput,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  // Передача функций установки значений и дополнительных данных на внешние модули
  React.useEffect(() => {
    if (props.getEdition && props.record) {
      props.getEdition({
        setValue,
        setInputValue,
        value: value,
        inputValue: inputValue,
        id: props.record.id,
        key: props.record.dataset.key,
        setSelection,
      });
    } else if (props.getEdition) {
      props.getEdition({
        setValue,
        setInputValue,
        value: value,
        inputValue: inputValue,
        setSelection,
      });
    }
  }, [props.getEdition]);

  // обновление состояния чеклиста
  React.useEffect(() => {
    if (
      paramValuesItems &&
      EditStyle_CheckList &&
      checkedList.length === 0 &&
      !paramValuesItems[0].disabled
    ) {
      let objref = Number(props.objref ? props.objref : "0");

      setCheckedList(
        paramValuesItems.map((item) => {
          if (objref > 0 && objref & Number(item.id)) {
            objref -= Number(item.id);
            return Number(item.id);
          }
          return -1;
        })
      );
    } else if (paramValuesItems && EditStyle_CheckList && value.id) {
      updateCheckList(value.id);
    }
  }, [paramValuesItems, value]);

  // отслеживание изменений значений value и objref снаружи
  React.useEffect(() => {
    setValue({
      label: props.editMask ? fixValue(props.value) : props.value,
      id: props.objref,
    });
    setInputValue(props.editMask ? fixValue(props.value) : props.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, props.objref]);

  function updateCheckList(objref) {
    if (objref === undefined) return;
    let id = Number(objref);
    setCheckedList(
      paramValuesItems.map((item) => {
        if (id > 0 && id & Number(item.id)) {
          id -= Number(item.id);
          return Number(item.id);
        }
        return -1;
      })
    );
    checkListObjRef.current = objref;
  }

  // функция для установки нужного формата для new Date
  // YYYY-MM-DD
  function getDateInFormat(value) {
    let date = value;

    if (date) {
      if (date.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)) return date;
      if (date.match(/[0-9]{2}.[0-9]{2}.[0-9]{4}/)) {
        date = date.split(".");
        date = [date[2], date[1], date[0]];
        return date.join("-");
      }
      return checkDateCompletion(date);
    }
  }

  // функция заполняет данные месяца и года, если они не были указаны
  function checkDateCompletion(date) {
    const now = new Date();
    let value;
    if (date.match(/^[0-9]{4}-__-____$/)) {
      value = date.split("-");
      value = `${now.getFullYear()}-${
        now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1
      }-${value[0]}`;
      return value;
    }
    if (date.match(/^[0-9]+.?\.__\.____$/)) {
      value = date.split(".");
      value = `${now.getFullYear()}-${
        now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1
      }-${value[0]}`;
      return value;
    }
    if (date.match(/^[0-9]+\.\.$/)) {
      value = date.split(".");
      value = `${now.getFullYear()}-${
        now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1
      }-${value[0]}`;
      return value;
    }
    if (date.match(/^[0-9]{4}-[0-9]{2}-____$/)) {
      value = date.split("-");
      value = `${now.getFullYear()}-${value[1]}-${value[0]}`;
      return value;
    }
    if (date.match(/^[0-9]+.?\.[0-9]+.?\.____$/)) {
      value = date.split(".");
      value = `${now.getFullYear()}-${value[1]}-${value[0]}`;
      return value;
    }
    if (date.match(/^[0-9]+\.[0-9]+\.$/)) {
      value = date.split(".");
      value = `${now.getFullYear()}-${value[1]}-${value[0]}`;
      return value;
    }
  }

  // функция для установки даты в формат для сервера
  // {label:DD.MM.YYYY, id:number}
  function getDateBack(value) {
    const date = getDateInFormat(value);

    if (date) {
      // // вычисление id даты (количество дней с 30.12.1899)
      const oneDay = 1000 * 60 * 60 * 24;
      const startDate = new Date("1899-12-30");
      const currentDate = new Date(date);
      let id = Math.round(
        (currentDate.getTime() - startDate.getTime()) / oneDay
      );

      if (!id) id = "-1";
      return { label: value === ".." ? "" : value, id: id };
    } else return { label: "", id: "" };
  }

  // функция для отрисовки кнопок
  function getInnerButtons() {
    // количество кнопок в начале
    let startCounter = 0;
    // количество кнопок в конце
    let endCounter = 0;

    if (EditStyle_PickList) {
      endCounter++;
    }
    if (EditStyle_Calendar) {
      // особенность календаря, при стиле календарь и список - список заменяется на календарь
      if (EditStyle_PickList === 0) {
        endCounter++;
      }
    }
    if (EditStyle_Check) {
      startCounter++;
    }
    if (EditStyle_UpDown) {
      endCounter++;
    }
    if (EditStyle_Ellipsis) {
      if (EditStyle_Calendar === 0) {
        endCounter++;
      }
    }
    if (EditStyle_CheckList) {
      if (EditStyle_PickList === 0) endCounter++;
    }
    if (EditStyle_EditList) {
    }
    if (EditStyle_ColorBox) {
    }
    if (EditStyle_AutoList) {
    }
    if (EditStyle_Password) {
    }
    // обновление количества контента перед и после input
    setWidth({
      input: `calc(100% - ${startCounter * 27}px - ${endCounter * 20}px)`,
      start: `${startCounter * 27}px`,
      end: `${endCounter * 20}px`,
    });
  }

  // события на затемнение при наведении
  function onMouseEnter() {
    if (Boolean(props.disabled || props.HardCodeReadOnlyForEditor)) return;
    input.current.style.backgroundColor = "#fbfbfb";
  }
  function onMouseLeave() {
    if (Boolean(props.disabled || props.HardCodeReadOnlyForEditor)) return;
    input.current.style.backgroundColor = "";
    if (!focus.current) {
      if (props.placeholderVisible !== "1") input.current.placeholder = "";
    }
  }

  // функция на фокус input
  function onFocus() {
    focus.current = true;
    input.current.placeholder = placeholder.current;
    // для таблиц отдельный слушатель событий
    if (props.grid) {
      document.addEventListener("click", clickAway);
      input.current.deleteClickAway = () =>
        document.removeEventListener("click", clickAway);
    }
  }
  // функция на потерю фокуса input
  function onBlur(ev) {
    if (ev.target.tagName === "INPUT" || ev.target.tagName === "TEXTAREA") {
      focus.current = false;
      if (EditStyle_PickList) setOpenList(false);
      if (props.placeholderVisible !== "1") input.current.placeholder = "";
      // для таблиц отдельная логика редактирования
      if (!props.grid) {
        // вызов функции редактирования
        onEditValue(undefined, ev.target.value ? ev.target.value : inputValue);
      }
      // значение на потерю фокуса для функции onEditValue
      blurValue.current = ev.target.value;
    }
  }

  // Слушатель событий для таблиц на потерю фокуса
  function clickAway(ev) {
    if (
      ev.target === autoCompleteValue.current.closest("TD") ||
      ev.target.closest("[componenteditor]") ||
      (ev.target.closest("SMART-WINDOW") &&
        ev.target.closest("SMART-WINDOW") !==
          autoCompleteValue.current.closest("SMART-WINDOW")) ||
      ev.target.closest("[checklist]") ||
      ev.target.closest("[calendar]") ||
      ev.target.closest("[details]") ||
      ev.target.closest("[loadmask]") ||
      ev.target.classList.contains("MuiBackdrop-root")
    ) {
      return;
    }

    // если клик произошел на любое место кроме выше перечисленых выполнится код ниже
    if (props.clickAway) {
      // внешняя функция редактирования
      props.clickAway(ev, onEditValue, blurValue.current);
    }

    document.removeEventListener("click", clickAway);
  }

  // Функция для запроса на редактирование
  function onEditValue(ev, newValue, textchanged) {
    if (!props.onEdit || props.details) return;
    if (ev) {
      if (ev && ev.key === "Enter" && !props.grid) {
        if (typeof newValue === "object") {
          props.onEdit({
            path: props.path,
            type: props.type,
            datatype: props.datatype,
            name: props.name,
            value: newValue.label,
            tag: newValue.id,
            index: undefined,
            proof: needProof(),
            input: input.current,
            record: props.record,
            groupid: props.groupid,
            fieldname: props.fieldname,
            current: props.current,
            textchanged: textchanged,
            setValue: setValue,
            setInputValue: setInputValue,
            addInfo: props.addInfo,
            onEdit: props.onEdit,
          });
        } else {
          const val =
            EditStyle_Calendar === 2
              ? getDateBack(newValue)
              : { label: newValue };
          props.onEdit({
            path: props.path,
            type: props.type,
            datatype: props.datatype,
            name: props.name,
            value: val.label,
            tag: val.id,
            index: undefined,
            proof: needProof(),
            input: input.current,
            record: props.record,
            groupid: props.groupid,
            current: props.current,
            fieldname: props.fieldname,
            textchanged: textchanged,
            setValue: setValue,
            setInputValue: setInputValue,
            addInfo: props.addInfo,
            onEdit: props.onEdit,
          });
        }
        valueChanged.current = false;
        return;
      }

      if (ev && ev.key === "Enter" && textchanged !== undefined) {
        if (typeof newValue === "object") {
          props.onEdit({
            path: props.path,
            type: props.type,
            datatype: props.datatype,
            name: props.name,
            value: newValue.label,
            tag: newValue.id,
            index: undefined,
            proof: false,
            input: input.current,
            record: props.record,
            groupid: props.groupid,
            fieldname: props.fieldname,
            current: props.current,
            textchanged: textchanged,
            setValue: setValue,
            setInputValue: setInputValue,
            addInfo: props.addInfo,
            onEdit: props.onEdit,
          });
        }
      }

      if (ev.target && ev.target.tagName === "LI") {
        if (props.onEditList) {
          props.onEditList({
            current: props.current,
            tag: newValue.id,
            value: newValue.label,
            fieldname: props.fieldname,
            setValue: setValue,
            setInputValue: setInputValue,
          });
          valueChanged.current = false;
          return;
        }
        props.onEdit({
          path: props.path,
          proof: false,
          type: props.type,
          datatype: props.datatype,
          name: props.name,
          value: newValue.label,
          tag: newValue.id,
          index: undefined,
          input: input.current,
          record: props.record,
          groupid: props.groupid,
          current: props.current,
          fieldname: props.fieldname,
          textchanged: "0",
          setValue: setValue,
          setInputValue: setInputValue,
          addInfo: props.addInfo,
          onEdit: props.onEdit,
        });
        valueChanged.current = false;
        return;
      }
    }
    if (newValue !== undefined) {
      if (
        !ev &&
        valueChanged.current &&
        needProof() &&
        newValue !== undefined
      ) {
        const val =
          EditStyle_Calendar === 2
            ? getDateBack(newValue)
            : { label: newValue };
        props.onEdit({
          path: props.path,
          type: props.type,
          datatype: props.datatype,
          name: props.name,
          value: val.label,
          tag: val.id,
          index: undefined,
          proof: needProof(),
          input: input.current,
          record: props.record,
          groupid: props.groupid,
          current: props.current,
          fieldname: props.fieldname,
          textchanged: textchanged,
          setValue: setValue,
          setInputValue: setInputValue,
          addInfo: props.addInfo,
          onEdit: props.onEdit,
        });
        valueChanged.current = false;
        return;
      }

      if (!ev && valueChanged.current && newValue !== undefined) {
        const val =
          EditStyle_Calendar === 2
            ? getDateBack(newValue)
            : { label: newValue };
        props.onEdit({
          path: props.path,
          type: props.type,
          datatype: props.datatype,
          name: props.name,
          value: val.label,
          tag: val.id,
          index: undefined,
          input: input.current,
          record: props.record,
          groupid: props.groupid,
          current: props.current,
          fieldname: props.fieldname,
          textchanged: textchanged,
          setValue: setValue,
          setInputValue: setInputValue,
          addInfo: props.addInfo,
          onEdit: props.onEdit,
        });
        valueChanged.current = false;
        return;
      }
    }

    // if (
    //   !ev &&
    //   valueChanged.current &&
    //   autoCompleteValue.current.dataset.needproof === "true"
    // ) {
    //   const val =
    //     EditStyle_Calendar === 2
    //       ? getDateBack(inputValue)
    //       : { label: inputValue };
    //   props.onEdit({
    //     path: props.path,
    //     type: props.type,
    //     datatype: props.datatype,
    //     name: props.name,
    //     value: val.label,
    //     tag: val.id,
    //     index: undefined,
    //     proof: true,
    //     input: input.current,
    //     record: props.record,
    //     setValue: setValue,
    //     setInputValue: setInputValue,
    //     addInfo: props.addInfo,
    //   });
    //   valueChanged.current = false;
    //   return;
    // }

    // if (!ev && valueChanged.current) {
    //   const val =
    //     EditStyle_Calendar === 2
    //       ? getDateBack(inputValue)
    //       : { label: inputValue };
    //   props.onEdit({
    //     path: props.path,
    //     type: props.type,
    //     datatype: props.datatype,
    //     name: props.name,
    //     value: val.label,
    //     tag: val.id,
    //     index: undefined,
    //     input: input.current,
    //     record: props.record,
    //     setValue: setValue,
    //     setInputValue: setInputValue,
    //     addInfo: props.addInfo,
    //   });
    //   valueChanged.current = false;
    //   return;
    // }
  }

  // функция выбора маски по стилю и типу
  function getMask(dataType, editStyle) {
    if (props.editMask) {
      return props.editMask.replaceAll("#", "0");
    }
    if (dataType === "2003") {
      return /^\d+[-]?[0-5]?[0-9]?$/;
    }
    if (dataType === "byte") {
      return /^[0]*[0-9]$|^[0]*[0-9][0-9]$|^[0]*1[0-9][0-9]$|^[0]*2[0-4][0-9]$|^[0]*25[0-5]$/;
    }
    if (
      dataType === "int" ||
      dataType === "5" ||
      dataType === "2" ||
      dataType === "3"
    ) {
      return /^\d+$/;
    }
    if (dataType === "0" || dataType === "17") {
      return Number;
    }
    if (editStyle & 2) {
      return Date;
    }

    return /[.\n]*/;
  }

  // функция установки количества нулей после запятой
  function getScale(dataType) {
    if (dataType === "2003") return 2;
    if (dataType === "0") return 2;
    if (dataType === "17") return 3;
    return 0;
  }

  // функция установки дробного разделителя
  function getRadix(dataType, editStyle) {
    if (dataType === "2003") {
      return "-";
    }
    if (dataType === "0" || dataType === "17") {
      return ",";
    }
    if (editStyle & 2) {
      return ".";
    }
  }

  function getHint() {
    if (props.showHint) {
      if (props.hintText !== undefined && props.hintText !== "") {
        return props.hintText;
      } else {
        return input.current ? input.current.value : "";
      }
    }
    return "";
  }

  function needProof() {
    return EditStyle_PickList ||
      (EditStyle_Ellipsis && !(props.datatype <= 12 && props.datatype >= 7))
      ? true
      : false;
  }

  function closeAllPopovers() {
    if (openList) {
      setOpenList(false);
    }
    if (anchorElForCheckList) {
      handleCloseForCheckList();
    }
    if (anchorElDate) {
      handleCloseDate();
    }
  }

  function onArrowDown(ev) {
    if (openList || anchorElForCheckList || anchorElDate) {
      ev.stopPropagation();
      if (ev.altKey) closeAllPopovers();
      return;
    }
    if (
      ev.altKey &&
      (EditStyle_PickList || EditStyle_CheckList || EditStyle_Calendar)
    ) {
      ev.stopPropagation();
      if (EditStyle_Calendar) {
        buttonCalendar.current.click();
        return;
      }
      if (EditStyle_PickList || EditStyle_CheckList) {
        buttonPickList.current.click();
        return;
      }
    }
  }

  function onF4keyDown(ev) {
    if (EditStyle_PickList || EditStyle_CheckList || EditStyle_Calendar) {
      ev.stopPropagation();
      if (EditStyle_Calendar) {
        buttonCalendar.current.click();
        return;
      }
      if (EditStyle_PickList || EditStyle_CheckList) {
        buttonPickList.current.click();
        return;
      }
    }
  }

  function onKeyDown(ev) {
    switch (ev.code) {
      case "NumpadEnter":
      case "Enter":
        if (openList || anchorElForCheckList || anchorElDate) {
          ev.stopPropagation();
          return;
        }
        valueChanged.current = true;
        onEditValue(ev, inputValue);
        break;
      case "Escape":
        valueChanged.current = false;
        closeAllPopovers();
        break;
      case "KeyS":
        if (ev.ctrlKey) {
          onEditValue(undefined, inputValue);
        }
        break;
      case "ArrowUp":
      case "ArrowDown":
        onArrowDown(ev);
        break;
      case "F4":
        if (openList || anchorElForCheckList || anchorElDate) {
          closeAllPopovers();
        } else onF4keyDown(ev);
        break;
    }
  }

  return (
    <div
      componenteditor="true"
      style={{
        height: "100%",
        width: "100%",
        ...props.styleComponent,
      }}
    >
      {/* компонент со списком */}
      <Autocomplete
        disabled={Boolean(props.disabled || props.HardCodeReadOnlyForEditor)}
        data-needproof={`${needProof()}`}
        // свободное редактирование
        freeSolo
        // внутреннее значение
        value={value && value.label ? value : ""}
        onChange={(ev, newValue) => {
          if (!newValue) return;
          if (ev.code === "Enter" || ev.code === "NumpadEnter") {
            if (openList || anchorElForCheckList || anchorElDate) {
              onEditValue(ev, newValue, "0");
              closeAllPopovers();
            }
            return;
          }
          if (ev.code === "Escape") {
            return;
          }
          onEditValue(ev, newValue);
          // setValue(newValue);
        }}
        // внешнее значение
        inputValue={inputValue ? inputValue : ""}
        // onInputChange={(ev, newValue) => {
        //   if (ev && ev.key !== "Enter") setInputValue(newValue);
        //   valueChanged.current = true;
        // }}
        // вызов функции редактирования при нажатии на enter
        onKeyDown={onKeyDown}
        ref={autoCompleteValue}
        sx={{
          height: "100%",
        }}
        // данные для выпадающего списка
        options={
          paramValuesItems.length
            ? paramValuesItems
            : [{ label: "Ничего не найдено...", disabled: true }]
        }
        renderOption={(props, option) => {
          // нужно для фикса ошибки с ключами
          return (
            <li
              {...props}
              title={option.label}
              style={{
                height: "30px",
                fontSize: "13px",
                borderBottom: "1px solid #eeeeee",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              key={`${option.label}-${option.id}-${props.id}`}
            >
              {option.label}
            </li>
          );
        }}
        // выбор текста из опций
        getOptionLabel={(option) => {
          return option.label ? option.label : "";
        }}
        // правило равенства опции и заданного внутреннего значения
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id || option.label === value.label;
        }}
        // правило получения выключенных опций
        getOptionDisabled={(item) => {
          return item.disabled === true;
        }}
        noOptionsText="Ничего не найдено..."
        // состояние открытия выпадающего списка
        open={openList}
        // передача в выпадающий список функции на click по значению
        slotProps={{
          popper: {
            placement: "bottom-start",
            style: {
              minWidth: autoCompleteValue.current
                ? `${autoCompleteValue.current.clientWidth}px`
                : "200px",
              maxWidth: `${document.body.clientWidth / 2}px`,
              width: `fit-content`,
              overflow: "auto",
              border: "1px solid #cccccc",
              borderRadius: "6px",
              boxShadow: "5px 5px 5px #dddddd",
            },
            sx: {
              [`& .${autocompleteClasses.listbox}`]: {
                listStyle: "none",
                margin: "0",
                padding: "0",
                maxHeight: "40vh",
                overflow: "auto",
                position: "relative",
              },
            },
            onClick: (ev) => {
              if (ev.target.tagName === "LI") setOpenList(false);
            },
          },
        }}
        // отрисовка маскированного input с необходимыми параметрами
        renderInput={(params) => {
          // use ref to get access to internal "masked = ref.current.maskRef"
          delete params.InputProps.endAdornment;
          delete params.InputProps.startAdornment;

          return (
            // главный контейнер
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              onFocus={(ev) => {
                onFocus(ev);
              }}
              onBlur={onBlur}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              {...params.InputProps}
              style={{
                height: "100%",
                width: "100%",
                paddingRight: "0px",
                paddingLeft: "3px",
                backgroundColor: "#ffffff",
              }}
            >
              {/* контейнер с кнопками перед input */}
              <Grid
                item
                ref={startContentRef}
                style={{
                  maxHeight: "100%",
                  width: width.start,
                  display: "flex",
                }}
              >
                {/* чекбокс */}
                <CheckBoxMultiCheck
                  onEdit={(data) => {
                    props.onEdit({
                      path: props.path,
                      type: props.type,
                      datatype: props.datatype,
                      name: props.name,
                      checked: data.CheckState,
                      addInfo: props.addInfo,
                    });
                  }}
                  parentProps={props}
                  MultiCheckSet={undefined}
                  CheckState={props.checked}
                  // видимость
                  display={EditStyle_Check ? "" : "none"}
                  disabled={Boolean(
                    props.disabled || props.HardCodeReadOnlyForEditor
                  )}
                />
              </Grid>
              {/* маскированный input */}
              <Tooltip
                open={openTooltip}
                arrow
                placement="bottom-start"
                title={getHint()}
              >
                <Grid
                  item
                  style={{
                    width: width.input,
                    height: "100%",
                    ...props.styleComponent,
                  }}
                >
                  {props.datatype <= 12 && props.datatype >= 7 ? (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        // paddingTop: "4px",
                        // paddingBottom: "2px",
                      }}
                    >
                      <textarea
                        {...params.inputProps}
                        inputeditor="true"
                        placeholder={
                          props.placeholderVisible === "1"
                            ? placeholder.current
                            : ""
                        }
                        onMouseEnter={onOpenToolTip}
                        onMouseLeave={onCloseToolTip}
                        className={"textfieldinput"}
                        style={{
                          ...styleInput,
                          resize: "none",
                          wrap: "soft",
                          height: "100%",
                          paddingTop: "3px",
                        }}
                        // type={"text"}
                        onDoubleClick={() => {
                          if (
                            modalButton.current &&
                            modalButton.current.style.display !== "none" &&
                            props.doubleClickOnInput
                          ) {
                            modalButton.current.click();
                          }
                        }}
                        readOnly={EditStyle_ReadOnly === 64 || props.readonly}
                        value={inputValue ? inputValue : ""}
                        title={inputValue}
                        ref={(el) => {
                          input.current = el;
                          params.inputProps.ref.current = el;
                        }}
                        onKeyDown={(ev) => {
                          if (ev.key === "Enter") return;
                        }}
                        // onChange для инпута
                        onChange={(ev) => {
                          if (inputValue !== ev.target.value) {
                            valueChanged.current = true;
                          }
                          props.onChange &&
                            props.onChange(ev.target.value, {
                              valueChanged: valueChanged,
                              datatype: props.datatype,
                              addInfo: props.addInfo,
                              type: props.type,
                              ObjRef: props.objref,
                            });
                          setInputValue(ev.target.value);
                        }}
                      />
                    </div>
                  ) : (
                    <IMaskInput
                      {...params.inputProps}
                      inputeditor="true"
                      placeholder={
                        props.placeholderVisible === "1" && !props.editMask
                          ? placeholder.current
                          : undefined
                      }
                      onMouseEnter={onOpenToolTip}
                      onMouseLeave={onCloseToolTip}
                      className={"textfieldinput"}
                      style={styleInput}
                      type={"text"}
                      onDoubleClick={() => {
                        if (
                          modalButton.current &&
                          modalButton.current.style.display !== "none" &&
                          props.doubleClickOnInput
                        ) {
                          modalButton.current.click();
                        }
                      }}
                      readOnly={EditStyle_ReadOnly === 64 || props.readonly}
                      // маска
                      mask={getMask(props.datatype, props.editStyle)}
                      // разделитель между тысячами
                      thousandsSeparator=" "
                      padFractionalZeros={true}
                      scale={getScale(props.datatype, props.editStyle)}
                      radix={getRadix(props.datatype, props.editStyle)}
                      // отображение маски в placeholder
                      lazy={false}
                      value={inputValue}
                      title={inputValue}
                      unmask={true}
                      ref={maskRef}
                      overwrite={EditStyle_Calendar ? true : false}
                      // проверка даты
                      validate={(val) => {
                        if (EditStyle_Calendar !== 2) return;
                        if (val.match(/[0-9]{2}.[0-9]{2}.[0-9]{4}/)) {
                          if (val.match(dateRegexp)) {
                            input.current.style.outline = "";
                            input.current.title = "";
                          } else {
                            input.current.style.outline = "1px solid red";
                            input.current.title = "Укажите корректную дату";
                          }
                        } else {
                          input.current.style.outline = "";
                          input.current.title = "";
                        }
                      }}
                      inputRef={(el) => {
                        input.current = el;
                        params.inputProps.ref.current = el;
                      }}
                      // onChange для инпута
                      onAccept={(value, mask, ev) => {
                        if (
                          (inputValue === undefined || inputValue === "") &&
                          (value === "" || value === "..")
                        ) {
                          return;
                        }
                        if (inputValue !== value) valueChanged.current = true;
                        props.onChange &&
                          props.onChange(value, {
                            valueChanged: valueChanged,
                            datatype: props.datatype,
                            addInfo: props.addInfo,
                            type: props.type,
                            ObjRef: props.objref,
                          });
                        if (ev && ev.data === null) {
                          input.current.style.outline = "";
                          input.current.title = "";
                        }
                        setInputValue(value);
                      }}
                    />
                  )}
                </Grid>
              </Tooltip>

              {/* контейнер с кнопками после input */}
              <Grid
                item
                ref={endContentRef}
                style={{
                  maxHeight: "100%",
                  width: width.end,
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  {/* календарь */}
                  <CalendarButton
                    buttonRef={buttonCalendar}
                    id={idDate}
                    handleClick={handleClickDate}
                    itemID={props.id}
                    EditStyle_Calendar={EditStyle_Calendar}
                    disabled={Boolean(
                      props.disabled || props.HardCodeReadOnlyForEditor
                    )}
                  />
                  {/* кнопки +- */}
                  <UpDownButtons
                    EditStyle_UpDown={EditStyle_UpDown}
                    incValue={incValue}
                    decValue={decValue}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    valueChanged={valueChanged}
                    disabled={Boolean(
                      props.disabled || props.HardCodeReadOnlyForEditor
                    )}
                  />
                  {/* кнопка модального окна */}
                  <ModalButton
                    props={{
                      ...props,
                      refButton: modalButton,
                      EditStyle_Ellipsis: EditStyle_Ellipsis,
                      EditStyle_Calendar: EditStyle_Calendar,
                      inputValue: inputValue,
                      value: value,
                      input: input,
                      selection: selection,
                      setValue: setValue,
                      setInputValue: setInputValue,
                      setSelection: setSelection,
                      GetDialogParam: GetDialogParam,
                      openModalWindow: openModalWindow,
                      closeList: () => {
                        if (EditStyle_PickList) setOpenList(false);
                      },
                      disabled: Boolean(
                        props.disabled || props.HardCodeReadOnlyForEditor
                      ),
                    }}
                  />
                  {/* выпадающий список */}
                  <PickListEditor
                    buttonRef={buttonPickList}
                    id={idForCheckList}
                    itemId={props.id}
                    EditStyle_PickList={
                      (EditStyle_PickList || EditStyle_CheckList) &&
                      !EditStyle_Calendar &&
                      !props.details
                    }
                    onPickListClick={onPickListClick}
                    disabled={Boolean(
                      props.disabled || props.HardCodeReadOnlyForEditor
                    )}
                  />
                  {props.details ? (
                    <DetailsButton
                      autoCompleteValue={autoCompleteValue}
                      details={props.details}
                      inputValue={inputValue}
                      module={props.type}
                      setInputValue={setInputValue}
                      setValue={setValue}
                      valueChanged={valueChanged}
                    />
                  ) : null}
                  {EditStyle_CheckList ? (
                    <CheckboxList
                      // objref={checkListObjRef.current}
                      id={idForCheckList}
                      open={openForCheckList}
                      anchorEl={anchorElForCheckList}
                      handleClose={handleCloseForCheckList}
                      items={paramValuesItems}
                      checked={checkedList}
                      handleToggle={handleToggle}
                    />
                  ) : null}
                </Grid>
              </Grid>
              {/* popover для выбора даты */}
              {EditStyle_Calendar ? (
                <DateCalendarEditor
                  id={idDate}
                  open={openDate}
                  anchorEl={anchorElDate}
                  handleClose={handleCloseDate}
                  getDateInFormat={getDateInFormat}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  valueChanged={valueChanged}
                  onEditValue={onEditValue}
                />
              ) : null}
            </Grid>
          );
        }}
      />
    </div>
  );
}
