import React from "react";

import { AxiosRequest, ImgURLSRC } from "../../../../Url";
import {
  formEdit,
  getDefaultMenu,
  getElementsById,
  getIcons,
} from "../../../Tools/Tools";

import { Button, Grid } from "@mui/material";
import ContextMenu from "../../../NotWorkArea(Side&Head)/ContextMenu";
import LoadingMask from "../../../NotWorkArea(Side&Head)/LoadingMask";
import "./treeClasses.css";
import Scrollbars from "react-custom-scrollbars-2";

// картинка для распахивания дерева
const expandIcon = `
          <svg
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="ExpandMoreIcon"
            style="height: 15px; width: 15px;"
          >
            <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
          </svg>
        `;

//картинки элементов
const IconFolder =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAmElEQVRIiWNgGAUDDRhhjP/zGbwYGBlmMjAwyGCo+s/QyJjI0ECOBUxIVmE3HCJX/38+eRYgfLCW5z+DpAQDAwsLOeYgwJ8/fxm+fGpkdHvVjGrBMZX/FBsOA79//2W0vsvCwIAcRNQynIGBgYGVlRnGZMKnjhpg1IJRC0YtQLXgCfWM/f8Y0wLGf2nIEhQanka5OaOAWgAAdiEh3MqSfL4AAAAASUVORK5CYII=";
const IconList =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAABmJLR0QA/wD/AP+gvaeTAAACfElEQVRIib2VTUhUURTHf/fNjOPofGTWfOVMhhFUEgRhUabkYtZCm8gEjWzRokULlwUZSLswIlxE4iKiWgUVmYsgCJqNMKEwZUKMjOP4AY36Xlq+12IEpxnfvKcz9d8c7nmH87v3nvPOhf8ske/IZDK1QLMQosJ0EiHiTqcztm3g0tKSV1XVz0IIr1nYhjRFUfp8Pt8to0Br3rpVCOG91nOf1bVf5kiqRlvkuLjU2XZzZmZGDgQCd00DVVW1CyFQNY07jww3C0B6WWWgfxjx5AMdF8/2JxIJJRQKDejFS6ayFpGQJILnO3kXTfDs+UfhdrvvxePxK/8MCCAsFuouXObV+y+8fjMm/H7/YCwW694qNr+GBRqfh6kfhX6PHVrqNteSzUaoo4enQw9xOCqk1pbDgyMjIwuRSOTltoDzP2F6udC/stFTlVZwWEHVgKpKDnZdZfjxA0LBXbZwONwHjAKyaaCexMYP5aqArqO5X6oZTTYx+S1F08kDLmAv8N00sDkIpwKFfkvByNDfW+7CEGiRwGI6t7EMgROLEKyG2BykFf24Ez6od5cBeGR31uZ2ZCkyBKblbGMklyGzph9X74EaexmAFpGdDlYJ7EWKaXaCGAJrHVm730R9ygKcWICgE8bSkFzRjzsTLFfT1GbtuZBxMjMyHm0KuGyQkjfH2Vba58zO15KBqgYq8FuD1fUiccYsc0BvVdY2eExmNFBZ3sPtaMsTKvIqb1+M7jjp5PgUe04fMgX8CnD9RjuyXGSsGKgheIzGxjDpuWSKvPL+BfR4PJ+i0ehtn9/ZDo6Srjs1O73Y29s7BMzm+vVetRrAVQoQWAfmgJ1fVTn0B+QbqU31/zFJAAAAAElFTkSuQmCC";

// ref= React.useRef() - переменная хука useRef() в которую будет записана ссылка на компонент дерева
// selectID= function(id){setID(id)} *React.useState()* - функция setState() для получения выбранного id элемента/массива выбранных id элементов
// Current= int - Изначально выбранный элемент при первой отрисовке
// Selection= "1,2,3" - Изначаьные id элементов выбранных множественным выбором через запятую
// checkModeOnly= false/true - Только множественный выбор
// defaultMultiSel= false/true - Множественный выбор по умолчанию
// MultiSel= false/true - Режим возможности переключаться между множественным и одиночным выбором
const TestTree = ({ props }) => {
  //хук для хранения id группы, можно получить передав по пропсам setState под ключом selectID
  const [GroupID, setGroupID] = React.useState();
  //хук для хранения данных последнего запроса параметров дерева
  const [load, setLoad] = React.useState(true);
  // переменная для первой загрузки
  const firstLoad = React.useRef(true);
  // главный контейнер
  const treeContainer = React.useRef();

  // переменная с данными по отрисованому дереву
  const values = React.useRef();
  // переменные для множественного выбора
  const selection = React.useRef([]);
  const selectionMode = React.useRef(false);
  //элемент дерево
  const tree = React.useRef();

  // при первой отрисовке заполняем дерево элементами
  React.useEffect(() => {
    tree.current.addGroupID = addGroupID;
    tree.current.selectGroupID = selectGroupID;

    // установка значений множественного выбора по умолчанию
    if (props.Selection) {
      selection.current = props.Selection.split(",");
    }

    // первая отрисовка
    if (tree.current) {
      tree.current.innerHTML = "";
      fetchData().then((res) => {
        if (!res) return;

        if (props.Current === undefined) {
          fetchData(undefined, res.Current).then(() => {
            if (
              (props.MultiSel && props.Selection) ||
              (props.MultiSel && props.defaultMultiSel)
            )
              selectionMode.current = true;
            addNewItems(res["Tree"]["items"], "tree", {
              Current: res.Current,
            });
            // разворачивание первого выбранного элемента
            expandItem();
            setLoad(false);
          });
        } else {
          if (
            (props.MultiSel && props.Selection) ||
            (props.MultiSel && props.defaultMultiSel)
          )
            selectionMode.current = true;
          addNewItems(res["Tree"]["items"], "tree", { Current: res.Current });
          // разворачивание первого выбранного элемента
          expandItem();
          setLoad(false);
        }
      });

      values.current = {};
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // при изменении id выбранной группы вызывается setState переданный по пропсам для передачи данных в родительский компонент
  React.useEffect(() => {
    if (props.selectID && GroupID !== undefined) {
      if (selectionMode.current && typeof GroupID === "object") {
        props.selectID(GroupID.join(","));
      } else {
        props.selectID(GroupID);
      }
      tree.current.getCurrentInfo();
      tree.current.getSelectData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GroupID]);

  React.useEffect(() => {
    if (props.setSelectionMode) {
      props.setSelectionMode(selectionMode.current);
    }
  }, [selectionMode.current]);

  // внешние функции
  React.useEffect(() => {
    if (tree.current) {
      // информация по текущему элементу
      tree.current.getCurrentInfo = getCurrentInfo;
      // информация по элементу с переданным id
      tree.current.getSelectData = getSelectData;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tree.current]);

  function addGroupID(id) {
    if (selectionMode.current) {
      let item = tree.current.querySelector(`#text-tree-item_${id}`);

      if (item) item = item.closest(".tree-item");
      if (!item) return;

      let checkbox = item.querySelector(`input[type="checkbox"]`);
      console.log(checkbox);

      if (checkbox && !checkbox.checked) {
        checkbox.click();
      }
    }
  }

  function selectGroupID(id) {
    if (!selectionMode.current) {
      let item = tree.current.querySelector(`#text-tree-item_${id}`);

      if (item) item = item.closest(".tree-item");
      if (!item) return;

      item.click();
    }
  }

  function getSelectData() {
    let item = tree.current.querySelector(".tree-item-selected");
    let id = 0;
    let itemText = "";
    if (item) {
      id = item.id;
      itemText = tree.current.querySelector(`#text-tree-item_${id}`).innerText;
    }

    if (itemText && id) return { label: itemText, id: id };
    return {};
  }

  function getCurrentInfo(GroupID) {
    if (GroupID !== undefined) {
      let item = tree.current.querySelector(`#text-tree-item_${GroupID}`);
      if (item) item = item.closest(".tree-item");

      if (!item) return;

      return {
        GroupID: GroupID,
        item: item,
        title: item.querySelector(`#text-tree-item_${GroupID}`).innerText,
      };
    }
  }

  // запрос дерева
  // для запроса необходимо передать только необходимые параметры
  async function fetchData(idItem, Current) {
    const params = new Map();
    params.set("prefix", props.Module).set("comand", "GetGroupTree");
    if (props.SectionID) params.set("SectionID", props.SectionID);
    if (props.Info) params.set("Info", props.Info);
    if (props.UsedDate) params.set("UsedDate", props.UsedDate);
    if (props.ObjType) params.set("ObjType", props.ObjType);
    if (Current || props.Current)
      params.set("Current", Current ? Current : props.Current);
    if (props.ObjRef) params.set("ObjRef", props.ObjRef);
    if (idItem) {
      params.set("GroupID", idItem).set("Current", idItem);
    }
    if (!props.CLSID && props.Path) params.set("Path", props.Path);
    // возвращаем промис
    return await AxiosRequest(true, params).then((res) => {
      if (!res.Tree) return;
      // возвращаем ответ для обработки промиса через .then
      return res;
    });
  }

  // создание тела для законченной ветви
  function createSingleItem(item, id, res) {
    const newItem = document.createElement("LI");
    newItem.style.borderLeft = "1px solid #eeeeee";
    newItem.style.marginLeft = "11px";

    newItem.id = `tree-item_${id}`;
    newItem.key = `tree-item_${id}`;

    newItem.selected = id === props.ObjRef || id === res.Current;

    newItem.dataset.noDel = item.noDel;
    newItem.dataset.noEdit = item.noEdit;
    return newItem;
  }

  // создание тела для незаконченной ветви
  function createGroupItem(item, id, res) {
    const newItemsGroup = document.createElement("LI");
    newItemsGroup.style.borderLeft = "1px solid #eeeeee";
    newItemsGroup.style.marginLeft = "11px";

    newItemsGroup.id = `tree-group_${id}`;
    newItemsGroup.key = `tree-group_${id}`;

    newItemsGroup.selected = id === props.ObjRef || id === res.Current;

    newItemsGroup.dataset.noDel = item.noDel;
    newItemsGroup.dataset.noEdit = item.noEdit;
    return newItemsGroup;
  }

  // создание котента для конечной ветви
  function createSingleItemContent(id) {
    const content = document.createElement("div");
    content.id = id;
    content.classList.add("tree-item");
    content.style.paddingLeft = "23px";
    content.tabIndex = 0;
    content.addEventListener("mouseenter", onMouseEnter);
    content.addEventListener("mouseleave", onMouseLeave);
    return content;
  }

  // создание котента для незаконченной ветви
  function createGroupItemContent(id) {
    const content = document.createElement("div");
    content.id = id;
    content.classList.add("tree-item");
    content.tabIndex = 0;
    content.addEventListener("mouseenter", onMouseEnter);
    content.addEventListener("mouseleave", onMouseLeave);
    return content;
  }

  // создание общего контейнера для конечной ветви
  function createSingleItemContainer(items, index) {
    const ul = document.createElement("UL");
    if (items.length - 1 > index) ul.style.borderBottom = "1px solid #eeeeee";
    ul.style.listStyleType = "none";
    ul.style.paddingLeft = "12px";
    return ul;
  }

  // создание общего контейнера для незаконченной ветви
  function createGroupItemContainer(item, items, index) {
    const ul = document.createElement("UL");
    if (items.length - 1 > index) ul.style.borderBottom = "1px solid #eeeeee";
    ul.style.listStyleType = "none";
    ul.style.paddingLeft = "12px";
    ul.group = "true";
    ul.classList.add("tree-group");
    ul.expanded = item.items ? "true" : "false";
    return ul;
  }

  // создание чекбокса
  function createCheckbox(id) {
    const checbox = document.createElement("input");
    checbox.type = "checkbox";
    checbox.style.display =
      selectionMode.current || props.checkModeOnly ? "" : "none";
    checbox.classList.add("tree-item-checkbox");
    checbox.checked = selection.current.indexOf(id) !== -1 ? true : false;
    return checbox;
  }

  // создание кнопки распахивания
  function createExpandButton(item) {
    const expandButton = document.createElement("button");
    expandButton.classList.add("expandButton");
    expandButton.expanded = item.items ? "true" : "false";
    expandButton.style.transform = item.items ? "" : "rotate(-90deg)";
    expandButton.innerHTML = expandIcon;
    return expandButton;
  }

  // добавление элементов в дерево
  function addNewItems(input, current, res) {
    if (!input || !tree.current) return;

    // перебор элементов массива с данными
    const items = input;

    if (!res.Current && items.length) {
      res.Current = items[0].id;
    }

    items.forEach((item, index) => {
      const id = item.id ? item.id : item.ID;

      // установка первого элемента как выбранный если он не указан в ответе
      if (firstLoad.current && (res.Current === id || !res.Current)) {
        if (props.MultiSel && selection.current.length) {
          setGroupID([...selection.current]);
        } else setGroupID(id);
        firstLoad.current = false;
      }

      // текст и картинка из запроса
      const text = item.text ? item.text : item.Text;
      const image = item.image ? item.image : item.Image;
      // если это законченная ветвь то создаем элемент tree-item внутри элемента с id current
      if (item.leaf) {
        // тело элемента
        const newItem = createSingleItem(item, id, res);

        // рабочая часть элемента
        const div = createSingleItemContent(id);

        // визуальный выбор элемента
        if (newItem.selected) {
          div.classList.add("tree-item-selected");
        }

        // получение иконки если она есть
        let icon;
        if (image) {
          icon = getIcon(image);
        }
        // визуальный контент элемента
        const innerContent = getInnerContent(text, id, undefined, icon);
        // текст
        div.insertAdjacentElement("afterbegin", innerContent[0]);
        // иконка
        div.insertAdjacentElement("afterbegin", innerContent[1]);
        // чекбокс
        const checbox = createCheckbox(id);
        div.insertAdjacentElement("afterbegin", checbox);

        // общий контейнер
        const ul = createSingleItemContainer(items, index);

        // сборка всех элементов в один список
        // рабочая область(текст и кнопки)
        ul.appendChild(div);
        // тело
        ul.appendChild(newItem);

        // Добавление элемента в ДОМ
        if (current === "tree") {
          tree.current.appendChild(ul);
        } else {
          current.appendChild(ul);
        }

        // обновление выбранной группы если id совпадает с ObjRef или  Current
        if (id === props.ObjRef || id === res.Current) {
          setGroupID(id);
        }
      } else {
        // тело элемента в которое будут добавляться ветви
        const newItemsGroup = createGroupItem(item, id, res);

        // рабочая часть элемента
        const div = createGroupItemContent(id);

        // визуальный выбор элемента
        if (newItemsGroup.selected) {
          div.classList.add("tree-item-selected");
        }

        // получение иконки если она есть
        let icon;
        if (image) {
          icon = getIcon(image);
        }
        // визуальный контент элемента
        const innerContent = getInnerContent(text, id, true, icon);
        // текст
        div.insertAdjacentElement("afterbegin", innerContent[0]);
        // иконка
        div.insertAdjacentElement("afterbegin", innerContent[1]);
        // чекбокс
        const checbox = createCheckbox(id);
        div.insertAdjacentElement("afterbegin", checbox);
        // кнопка распахивания элемента
        const expandButton = createExpandButton(item);
        div.insertAdjacentElement("afterbegin", expandButton);

        // общий контейнер
        const ul = createGroupItemContainer(item, items, index);

        // сборка всех элементов в один список
        // рабочая область(текст и кнопки)
        ul.appendChild(div);
        // тело
        ul.appendChild(newItemsGroup);

        // Добавление элемента в ДОМ
        if (current === "tree") {
          tree.current.appendChild(ul);
        } else {
          current.appendChild(ul);
        }

        // обновление выбранной группы если id совпадает с ObjRef или  Current
        if (id === props.ObjRef || id === res.Current) {
          setGroupID(id);
        }

        // вызов рекурсии если группа содержит внутри items
        // как current передается текущий элемент дерева к которому будут добавляться новые ветви (LI)
        if (item.items) addNewItems(item.items, newItemsGroup, res);
      }
    });
  }

  function getIcon(id) {
    const json = getIcons(id);
    if (json) {
      return ImgURLSRC(json);
    }
  }

  // Создание текста и иконки для элемента в дереве
  function getInnerContent(text, id, group, image) {
    let src;
    if (image) {
      src = image;
    }
    values.current[id] = text;

    // текст
    const innerText = document.createElement("div");
    innerText.innerText = text;
    innerText.title = text;
    innerText.id = `text-tree-item_${id}`;
    // стили
    innerText.style.display = "grid";
    innerText.style.alignItems = "center";

    // иконка
    const img = document.createElement("img");
    if (src) {
      img.src = src;
      img.style.width = "21px";
    } else {
      img.src = group ? IconFolder : IconList;
    }
    img.style.marginRight = "5px";

    return [innerText, img];
  }

  // функция для разворачивания дерева по id группы
  async function getNewLeaf(current, id) {
    await fetchData(id.split("_")[1]).then((res) => {
      addNewItems(res["Tree"]["items"], current, { Current: res.Current });
    });
  }
  // }

  async function onSelect() {
    if (props.onEdit && props.setValue && props.setInputValue) {
      const selectedItem = tree.current.querySelector(".tree-item-selected");
      if (!selectedItem) return;
      let value = values.current[selectedItem.id];

      props.onEdit({
        value: value,
        tag: selectedItem.id,
        name: props.Name,
        record: props.record,
        textchanged: "0",
        setValue: props.setValue,
        setInputValue: props.setInputValue,
        type: props.Module,
        requestId: props.RequestID,
        addInfo: props.addInfo,
        multiselect: selectionMode.current ? "1" : "0",
        selection: selectionMode.current
          ? selection.current.join(",")
          : undefined,
      });
      return;
    }

    if (props.RequestID) {
      if (props.RequestID) {
        await formEdit(
          "1",
          props.func,
          {
            Name: "ObjRef",
            Value: selectedItem.id,
          },
          props.RequestID,
          props.from
        );
      }
      return;
    }
  }

  // В РАЗРАБОТКЕ
  // function getTreeView(id) {
  //   const params = new Map();
  //   params.set("prefix", props.Module);
  //   params.set("comand", "GetTreeViewMenu");
  //   params.set("ID", id);
  //   params.set("ObjType", props.ObjType ? props.ObjType : "0");
  //   params.set("Info", props.Info ? props.Info : "0");
  //   params.set("Internal", props.Internal ? props.Internal : "1");
  //   const json = XMLrequest(params);
  //   if (json && json.Items && json.Items.items) {
  //     return { arr: json.Items.items };
  //   }
  // }

  // function ChangeTreeNodes(data, request) {
  //   const params = new Map();
  //   params.set("prefix", props.Module);
  //   params.set("comand", "ChangeTreeNodes");
  //   params.set("ObjType", props.ObjType ? props.ObjType : "0");
  //   params.set("Info", "0");
  //   if (data.id) params.set("ID", data.id);
  //   if (data.ParentID) params.set("ParentID", data.ParentID);
  //   params.set("Text", data.text);
  //   params.set("Command", data.comand);
  //   const json = AxiosRequest(true, params, request);
  //   return json;
  // }

  // function createItem(text, id) {
  //   const newItem = document.createElement("smart-tree-item");
  //   newItem.id = `tree-item_${id}`;
  //   newItem.key = `tree-item_${id}`;

  //   const innerContent = getInnerContent(text, id);
  //   // текст
  //   newItem.insertAdjacentElement("afterbegin", innerContent[0]);
  //   // иконка
  //   newItem.insertAdjacentElement("afterbegin", innerContent[1]);
  //   return newItem;
  // }

  // function createItemsGroup(text, id, data) {
  //   const newItemsGroup = document.createElement("smart-tree-items-group");
  //   newItemsGroup.id = `tree-group_${id}`;
  //   newItemsGroup.key = `tree-group_${id}`;

  //   newItemsGroup.dataset.noDel = data.item.dataset.noDel;
  //   newItemsGroup.dataset.noEdit = data.item.dataset.noEdit;

  //   const innerContent = getInnerContent(text, id, true);
  //   newItemsGroup.insertAdjacentElement("afterbegin", innerContent[0]);
  //   newItemsGroup.insertAdjacentElement("afterbegin", innerContent[1]);
  //   return newItemsGroup;
  // }

  // async function ContextMenuHandler(data) {
  //   const comand = data.value;
  //   const parent = data.item.closest("smart-tree-items-group");
  //   let json, newItem, newItemsGroup;
  //   switch (comand) {
  //     case "AddSection":
  //       // POST /organizations~ChangeTreeNodes?LicGUID=003E967942C5ED97F5E1A5A51C3AB859&ID=0&ObjType=2310&Text=Новый%20раздел%20#1&Info=0&Command=Insert
  //       // <Result ObjType="2310" Text="Новый раздел #1" Command="Insert"/> <Result ID="-101"/>
  //       // GET /organizations~GetTableLayout?LicGUID=003E967942C5ED97F5E1A5A51C3AB859&GroupID=-101&ObjType=2310&SectionID=100
  //       json = await ChangeTreeNodes(
  //         {
  //           text: "Новый раздел",
  //           id: "0",
  //           comand: "Insert",
  //           parentID:
  //             parent === null || data.item === parent
  //               ? "0"
  //               : parent.id.split("_")[1],
  //         },
  //         {
  //           ObjType: props.ObjType ? props.ObjType : "0",
  //           Text: "Новый раздел",
  //           Command: "Insert",
  //           ParentID:
  //             parent === null || data.item === parent
  //               ? "0"
  //               : parent.id.split("_")[1],
  //         }
  //       );

  //       newItem = createItem("Новый раздел", json.ID);

  //       // Добавление элемента в ДОМ
  //       if (parent === null || data.item === parent) {
  //         // условие для заполнения пустого дерева
  //         tree.current.nativeElement.addTo(newItem);
  //       } else {
  //         tree.current.nativeElement.addTo(newItem, parent);
  //       }
  //       break;

  //     case "AddSubSection":
  //       json = await ChangeTreeNodes(
  //         {
  //           text: "Новый раздел",
  //           id: "0",
  //           comand: "Insert",
  //           parentID: data.id,
  //         },
  //         {
  //           ObjType: props.ObjType ? props.ObjType : "0",
  //           Text: "Новый раздел",
  //           Command: "Insert",
  //           ParentID: data.id,
  //         }
  //       );

  //       if (data.item.tagName === "SMART-TREE-ITEM") {
  //         tree.current.nativeElement.removeItem(data.item);
  //         newItemsGroup = createItemsGroup(data.text, data.id, data);

  //         if (parent) {
  //           tree.current.nativeElement.addTo(newItemsGroup, parent);
  //         } else {
  //           tree.current.nativeElement.addTo(newItemsGroup);
  //         }
  //       } else {
  //         newItemsGroup = data.item;
  //       }
  //       newItemsGroup.dataset.noDel = "1";

  //       newItem = createItem("Новый раздел", json.ID);
  //       // Добавление элемента в ДОМ
  //       tree.current.nativeElement.addTo(newItem, newItemsGroup);

  //       break;

  //     case "Delete":
  //       // POST /organizations~ChangeTreeNodes?LicGUID=003E967942C5ED97F5E1A5A51C3AB859&ID=-102&ObjType=2310&Text=Новый%20раздел%20#1&Info=0&ParentID=1&Command=Delete
  //       // <Result ID="-102" ObjType="2310" Text="Новый раздел #1" ParentID="1" Command="Delete"/>
  //       const parentID = parent ? parent.id.split("_")[1] : "0";
  //       await ChangeTreeNodes(
  //         {
  //           text: data.text,
  //           id: data.id,
  //           comand: "Delete",
  //           parentID: parentID,
  //         },
  //         {
  //           ID: data.id,
  //           ObjType: props.ObjType ? props.ObjType : "0",
  //           Text: data.text,
  //           ParentID: parentID,
  //           Command: "Delete",
  //         }
  //       );
  //       tree.current.nativeElement.removeItem(data.item);
  //       if (parent && parent.lastChild.firstChild.children.length === 0) {
  //         parent.dataset.noDel = undefined;
  //       }
  //       break;
  //     default:
  //       break;
  //   }
  // }

  // ВРЕМЕННЫЙ КОСТЫЛЬ
  function addItemsForChecking(input, current, res, arr) {
    if (!input || !tree.current) return;

    const items = input;

    items.forEach((item, index) => {
      const id = item.id ? item.id : item.ID;

      const text = item.text ? item.text : item.Text;
      const image = item.image ? item.image : item.Image;
      if (item.leaf) {
        const newItem = document.createElement("LI");
        newItem.style.listStyleType = "none";
        newItem.style.borderLeft = "1px solid #eeeeee";
        newItem.style.marginLeft = "11px";
        newItem.style.display = "none";
        newItem.id = `tree-item_${id}`;
        newItem.key = `tree-item_${id}`;
        newItem.selected = false;
        let icon;
        if (image) {
          icon = getIcon(image);
        }
        newItem.dataset.noDel = item.noDel;
        newItem.dataset.noEdit = item.noEdit;
        const innerContent = getInnerContent(text, id, undefined, icon);
        const div = document.createElement("div");
        div.id = id;
        div.classList.add("tree-item");
        div.style.paddingLeft = "23px";
        div.tabIndex = 0;
        div.addEventListener("mouseenter", onMouseEnter);
        div.addEventListener("mouseleave", onMouseLeave);
        const checbox = document.createElement("input");
        checbox.type = "checkbox";
        checbox.style.display = selectionMode.current ? "" : "none";
        checbox.classList.add("tree-item-checkbox");
        div.insertAdjacentElement("afterbegin", innerContent[0]);
        div.insertAdjacentElement("afterbegin", innerContent[1]);
        div.insertAdjacentElement("afterbegin", checbox);
        const ul = document.createElement("UL");
        if (items.length - 1 > index)
          ul.style.borderBottom = "1px solid #eeeeee";
        ul.style.listStyleType = "none";
        ul.style.paddingLeft = "12px";
        ul.appendChild(div);
        ul.appendChild(newItem);
        arr.push(ul);
        if (current === "tree") {
          tree.current.appendChild(ul);
        } else {
          current.appendChild(ul);
        }
      } else {
        const newItemsGroup = document.createElement("LI");
        newItemsGroup.style.borderLeft = "1px solid #eeeeee";
        newItemsGroup.style.marginLeft = "11px";
        newItemsGroup.style.display = "none";
        newItemsGroup.id = `tree-group_${id}`;
        newItemsGroup.key = `tree-group_${id}`;
        newItemsGroup.selected = false;
        newItemsGroup.dataset.noDel = item.noDel;
        newItemsGroup.dataset.noEdit = item.noEdit;
        let icon;
        if (image) {
          icon = getIcon(image);
        }
        const innerContent = getInnerContent(text, id, true, icon);
        const div = document.createElement("div");
        div.id = id;
        div.classList.add("tree-item");
        div.tabIndex = 0;
        div.addEventListener("mouseenter", onMouseEnter);
        div.addEventListener("mouseleave", onMouseLeave);
        const expandButton = document.createElement("button");
        expandButton.classList.add("expandButton");
        expandButton.expanded = "false";
        expandButton.style.transform = item.items ? "" : "rotate(-90deg)";
        expandButton.innerHTML = expandIcon;
        const checbox = document.createElement("input");
        checbox.type = "checkbox";
        checbox.style.display = selectionMode.current ? "" : "none";
        checbox.classList.add("tree-item-checkbox");
        div.insertAdjacentElement("afterbegin", innerContent[0]);
        div.insertAdjacentElement("afterbegin", innerContent[1]);
        div.insertAdjacentElement("afterbegin", checbox);
        div.insertAdjacentElement("afterbegin", expandButton);
        const ul = document.createElement("UL");
        if (items.length - 1 > index)
          ul.style.borderBottom = "1px solid #eeeeee";
        ul.style.listStyleType = "none";
        ul.style.paddingLeft = "12px";
        ul.group = "true";
        ul.classList.add("tree-group");
        ul.expanded = "false";
        ul.appendChild(div);
        ul.appendChild(newItemsGroup);
        arr.push(ul);
        if (current === "tree") {
          tree.current.appendChild(ul);
        } else {
          current.appendChild(ul);
        }
        if (item.items) addItemsForChecking(item.items, newItemsGroup, res);
      }
    });
  }

  // ВРЕМЕННЫЙ КОСТЫЛЬ=
  async function loadAllInnerGroups(parentGroup) {
    const li = parentGroup.querySelector("li");
    const children = li.children;
    if (children.length === 0) {
      const item = parentGroup.querySelector(".tree-item");
      const id = item.id;
      const res = await fetchData(id, GroupID);
      li.style.display = "none";
      const children = [];
      addItemsForChecking(res["Tree"]["items"], li, res, children);
      for (let child of children) {
        if (child.group) {
          await loadAllInnerGroups(child);
        }
      }
    } else {
      for (let child of children) {
        if (child.group) {
          await loadAllInnerGroups(child);
        }
      }
    }
    return "";
  }

  async function onCheckGroup(data) {
    // ВРЕМЕННЫЙ КОСТЫЛЬ
    await loadAllInnerGroups(data.parentElement);
    // выбор всей группы
    const items = data.parentElement.querySelectorAll(`ul`);
    checking(items, data);
    // выбор для внешних элементов
  }

  async function checking(items, data) {
    items.forEach((item) => {
      const div = item.querySelector(".tree-item");
      const id = div.id;
      const checbox = div.querySelector(`input[type="checkbox"]`);

      checbox.checked = data.check;
      updateSelection(checbox.checked, id);
    });
    setGroupID([...selection.current]);
    setLoad(false);
  }

  // функция для распахивания по элементу
  function expandItem(item) {
    if (!tree.current) return;
    const selected = item
      ? itme
      : tree.current.querySelector(".tree-item-selected");
    if (selected) {
      const bttn = selected.querySelector(".expandButton");
      if (bttn) bttn.click();
    }
  }
  // функция смены выбранного элемента визуально
  function changeSelected(item) {
    const oldSelectedItem = tree.current.querySelector(".tree-item-selected");

    if (oldSelectedItem) {
      if (oldSelectedItem === item) return;
      oldSelectedItem.classList.remove("tree-item-selected");
    }

    item.classList.add("tree-item-selected");
  }

  // функция обновление массива выбранных элементов
  function updateSelection(checked, id) {
    if (checked) {
      selection.current.push(id);
    } else {
      selection.current = selection.current.filter((item) => item !== id);
    }
  }

  // hover
  const onMouseEnter = (ev) => {
    const el = ev.target.closest(".tree-item");
    el.classList.add("tree-item-hover");
  };

  const onMouseLeave = (ev) => {
    const el = ev.target.closest(".tree-item");
    el.classList.remove("tree-item-hover");
  };

  // поворот кнопки в зависимости от состояния распахивания
  function updateStateEpxandButton(bttn, state) {
    bttn.expanded = state ? "true" : "false";
    bttn.style.transform = state ? "" : "rotate(-90deg)";
  }

  // функция клика на кнопку распахивания
  const onClickExpandButtn = (ev) => {
    const bttn = ev.target.closest(".expandButton");

    updateStateEpxandButton(bttn, bttn.expanded === "false");

    const el = ev.target.closest("ul");

    if (el.group === "true") {
      if (bttn.expanded === "true") {
        if (el.children[1].children.length === 0) {
          getNewLeaf(el.children[1], el.children[1].id);
        } else {
          el.children[1].style.display = "";
        }
        el.expanded = "true";
      } else {
        el.children[1].style.display = "none";
        el.expanded = "false";
      }
    }
  };
  // функция клика по чекбоксу
  function onClickCheckBox(ev) {
    const checbox =
      ev.target.tagName === "INPUT" && ev.target.type === "checkbox"
        ? ev.target
        : null;
    if (checbox) {
      const treeItem = checbox.closest(".tree-item");
      const id = treeItem.id;
      const ulGroup = checbox.closest("UL");
      updateSelection(checbox.checked, id);

      setGroupID([...selection.current]);
      if (ulGroup && ulGroup.group) {
        onCheckGroup({ check: checbox.checked, parentElement: ulGroup });
      }
    }
  }

  // функция на клик по дереву
  function onClickItem(ev) {
    // клик по кнопке распахивания
    if (ev.target.closest(".expandButton")) {
      onClickExpandButtn(ev);
      return;
    }
    // клик по чекбоксу
    if (ev.target.closest(".tree-item-checkbox")) {
      onClickCheckBox(ev);
      return;
    }

    const item = ev.target.closest(".tree-item");
    if (!item) return;
    // визуальный выбор нового элемента
    changeSelected(item);

    // выбор для внешних элементов
    if (!selectionMode.current) {
      setGroupID(item.id);
    }
  }

  //функция на двойной клик по дереву
  function onDoubleClick(ev) {
    if (ev.target.closest(".expandButton")) return;
    if (ev.target.closest(".tree-item-checkbox")) return;

    const el = ev.target.closest("ul");
    const expandButton = el.querySelector(".expandButton");
    expandButton.click();
  }

  function calcHeight() {
    let correction = 0;
    if (props.showButtons) correction += 33.5;
    if (props.MultiSel && !props.checkModeOnly) correction += 20;
    return `calc(100% - ${correction}px)`;
  }
  // функция на клик по чек боксу множественного выбора
  function onChangeSelectionMode(ev) {
    const checkboxes = tree.current.querySelectorAll(".tree-item-checkbox");
    if (!checkboxes) return;

    if (ev.target.checked) {
      selectionMode.current = true;
      checkboxes.forEach((item) => {
        item.style.display = "";
      });
      setGroupID([...selection.current]);
    } else {
      selectionMode.current = false;
      checkboxes.forEach((item) => {
        item.style.display = "none";
      });
      const itemSelected = tree.current.querySelector(".tree-item-selected");
      setGroupID(itemSelected.id);
    }
  }

  return (
    <>
      <div ref={treeContainer} style={{ height: "100%", width: "100%" }}>
        {load ? <LoadingMask /> : null}
        {/* <ContextMenu
          for={"tree"}
          onRightClick={getTreeView}
          Menu={getDefaultMenu("tree")}
          onMenuItemClick={ContextMenuHandler}
        > */}
        <Grid
          style={{
            height: calcHeight(),
            overflow: "auto",
            border: "1px solid #cccccc",
          }}
        >
          <Scrollbars style={{ height: "100%" }} autoHide>
            <ul
              // tabIndex={0}
              onClick={onClickItem}
              onDoubleClick={onDoubleClick}
              style={{
                height: "100%",
                width: "100%",
                minWidth: "fit-content",
                listStyleType: "none",
                paddingLeft: "0px",
                paddingTop: "5px",
                margin: "0px",
                outline: "0",
                transition: "0.2s",
              }}
              ref={(ref) => {
                tree.current = ref;
                if (props.ref) props.ref.current = ref;
              }}
            ></ul>
          </Scrollbars>
        </Grid>
        <Grid
          style={{
            height: "20px",
            display: props.MultiSel && !props.checkModeOnly ? "flex" : "none",
            alignItems: "center",
          }}
        >
          <input
            defaultChecked={
              (props.MultiSel && props.Selection) ||
              (props.MultiSel && props.defaultMultiSel) ||
              props.checkModeOnly
                ? true
                : false
            }
            style={{ display: "flex" }}
            type="checkbox"
            onChange={onChangeSelectionMode}
          />
          <div
            style={{
              width: "fit-content",
              paddingTop: "2px",
            }}
          >
            Множественный выбор
          </div>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{
            width: "100%",
            display: props.showButtons ? "" : "none",
            height: "30.5px",
            paddingTop: "3px",
          }}
        >
          {/* {Кнопка Выбрать} */}
          <Button
            onClick={(ev) => {
              if (props.onSelect) {
                props.onSelect(
                  tree.current,
                  props,
                  selection.current.join(",")
                );
                return;
              }
              onSelect();
            }}
            size="small"
            variant="outlined"
            style={{
              textTransform: "none",
              marginRight: "10px",
            }}
            value="1"
            className="button_Modal_Select"
          >
            Выбрать
          </Button>
          {/* {кнопка Отмена} */}
          <Button
            size="small"
            variant="outlined"
            style={{ textTransform: "none" }}
            value="2"
            className="button_Modal_Close"
          >
            Отмена
          </Button>
        </Grid>
        {/* </ContextMenu> */}
      </div>
    </>
  );
};

export default TestTree;
