// import TestEditor from "../../Editor/testEditor";
import { DialogBook } from "../../MainPage/Module/Dialogs/DialogBook";
import DialogDoc from "../../MainPage/Module/Dialogs/DialogDoc";
import DialogOrganizations from "../../MainPage/Module/Dialogs/DialogOrganisations";
import DialogPerson from "../../MainPage/Module/Dialogs/DialogPerson";
import TestTree from "../../MainPage/Windows/ViewData/Tree/testTree";
// import MyTree from "../../MainPage/Windows/ViewData/Tree/tree";
import DialogAddress from "../../MainPage/Module/Dialogs/DialogAddress";
import DialogPeriod from "../../MainPage/Module/Dialogs/DialogPeriod";
import DialogPeriodTime from "../../MainPage/Module/Dialogs/DialogPeriodTime";
import DialogPeriodCurrentYear from "../../MainPage/Module/Dialogs/DialogPeriodCurrentYear";
import DialogChildren from "../../MainPage/Module/Dialogs/DialogChildren";
import { getParent } from "../../MainPage/Tools/Requests";
import DialogStartAndEndDate from "../../MainPage/Module/Dialogs/DialogStartAndEndDate";
import DialogOficialStructure from "../../MainPage/Module/Dialogs/DialogOficialStructure";
import DialogAcctools from "../../MainPage/Module/Dialogs/DialogAcctools";
import DialogNFA from "../../MainPage/Module/Dialogs/DialogNFA";
import { getModalSectionID } from "../../MainPage/Tools/Tools";
import DialogDepartments from "../../MainPage/Module/Dialogs/DialogDepartments";
import DialogMultiSection from "../../MainPage/Module/Dialogs/DialogMultiSection";
import DialogTabsForReports from "../../MainPage/Module/Dialogs/DialogTabsForReports";
import DialogCalendar from "../../MainPage/Module/Dialogs/DialogCalendar";
import { DialogHtml } from "../../Forms/DialogHtml";
import DialogDocTabs from "../../MainPage/Module/Dialogs/DialogDocTabs";
import DialogClsBook from "../../MainPage/Module/Dialogs/DialogClsBook";
// import DialogSetObjText from "../../MainPage/Module/Dialogs/DialogSetObjText";
// import TestParams from "../../MainPage/Sections/ElementsSections/testParams";

export default function HandleTable({ json, subData }) {
  let content, props;

  switch (json.CLSID) {
    case "{295EA015-4573-4AD9-922A-A14CE0FD9C78}":
      props = {
        RequestID: json.Params ? json.Params.RequestID : undefined,
        ...subData.props,
        ObjType: json.Params ? json.Params.ObjType : subData.props.ObjType,
        KeyField: json.KeyField
          ? json.KeyField
          : json.FieldName
          ? json.FieldName
          : "ID",
        CLSID: json.CLSID,
        EditorMode: true,
        style: { height: 300, width: 400 },
        ObjRef: subData.props.data
          ? subData.props.data.ObjRef
          : subData.props.ObjRef,
        Module: json.Module ? json.Module : subData.props.Module,
        ID: subData.props.ObjRef,
        SectionID: subData.props.SectionID,
      };
      content = <DialogBook props={props} />;
      break;
    case "{37912C20-59B2-415F-8F59-DE7F85AABB00}":
      props = {
        RequestID: json.Params ? json.Params.RequestID : undefined,
        ...subData.props,
        KeyField: json.KeyField ? json.KeyField : json.FieldName,
        CLSID: json.CLSID,
        EditorMode: true,
        style: { height: 300, width: 400 },
        ID: json.ID,
        SectionID: subData.props.SectionID,
      };
      content = <DialogClsBook props={props} />;
      break;
    case "{7FAC2B20-109D-4406-A757-61674277B71F}":
      props = {
        RequestID: json.Params ? json.Params.RequestID : undefined,
        ...subData.props,
        CLSID: json.CLSID,
        DlgType: json.DlgType,
        NodeID: json.NodeID,
        SectionID: subData.props.SectionID,
      };
      content = <DialogPerson props={props} />;
      break;
    case "{2AC0E428-23CD-49F6-AF96-3DB9D6B7AD0A}":
      props = {
        RequestID: json.Params ? json.Params.RequestID : undefined,
        ...subData.props,
        CLSID: json.CLSID,
        NodeID: json.NodeID,
        Title: json.title,
        SectionID: subData.props.SectionID,
        ObjRef: json.ParentID ? json.ParentID : subData.props.ObjRef,
        Selector: json.Selector,
      };
      content = <DialogOrganizations props={props} />;
      break;
    case "{5D1A1010-A394-43EC-A84B-AB401C1C2640}":
      props = {
        RequestID: json.Params ? json.Params.RequestID : undefined,
        ...subData.props,
        CLSID: json.CLSID,
        SectionID: subData.props.SectionID,
        showButtons: true,
      };
      content = (
        <TestTree props={props} />
        // <MyTree
        //   CLSID={json.CLSID}
        //   ObjRef={subData.props.ObjRef}
        //   ObjType={subData.props.ObjType}
        //   Module={subData.props.Module}
        //   multiCheck={false}
        // />
      );
      break;
    case "{C79B4449-B9CE-41C2-A28F-FB40EBA7E9DA}":
      props = {
        RequestID: json.Params ? json.Params.RequestID : undefined,
        ...subData.props,
        CLSID: json.CLSID,
        SectionID: subData.props.SectionID,
        ID: json.ID,
      };
      content = <DialogDepartments props={props} />;
      break;
    case "{3748C5C5-C0E9-4D45-8E05-17A046FD5C56}":
      content = (
        <DialogPeriod
          props={{
            RequestID: json.Params ? json.Params.RequestID : undefined,
            ...subData.props,
            CLSID: json.CLSID,
            Info: json.Info,
            SectionID: subData.props.SectionID,
          }}
        />
      );
      break;
    case "{C525B0F8-232B-4136-A5F2-AB22C77F55F0}":
      content = (
        <DialogPeriodTime
          props={{
            RequestID: json.Params ? json.Params.RequestID : undefined,
            ...subData.props,
            CLSID: json.CLSID,
          }}
        />
      );
      break;
    case "{5FEE058E-8083-453F-B31E-FB8DD7ED5174}":
      content = (
        <DialogPeriodCurrentYear
          props={{
            RequestID: json.Params ? json.Params.RequestID : undefined,
            ...subData.props,
            CLSID: json.CLSID,
          }}
        />
      );
      break;
    case "{D828B658-CBC4-46E4-A512-1E27F7C9BCBA}":
      const parentData = getParent(json.PersonID);
      const parent = parentData ? parentData.Text : "";

      content = (
        <DialogChildren
          props={{
            RequestID: json.Params ? json.Params.RequestID : undefined,
            ...subData.props,
            CLSID: json.CLSID,
            PersonID: json.PersonID,
            parent: parent,
          }}
        />
      );
      break;
    case "{3D16C8B0-9C78-474C-9D3D-10112A3F96A6}":
      content = (
        <DialogStartAndEndDate
          props={{
            RequestID: json.Params ? json.Params.RequestID : undefined,
            ...subData.props,
            CLSID: json.CLSID,
          }}
        />
      );
      break;
    case "{7D4264B1-7DA7-4009-A7BF-830D17590A4F}":
      content = (
        <DialogOficialStructure
          props={{
            RequestID: json.Params ? json.Params.RequestID : undefined,
            ...subData.props,
            CLSID: json.CLSID,
            NoFormServer: json.NoFormServer,
            ControlHeight: json.ControlHeight,
            NodeID: json.NodeID,
            ObjRef: json.ID,
          }}
        />
      );
      break;

    case "{010C6A4E-4954-4380-9E9F-40712B45D0DE}":
      content = (
        <DialogAcctools
          props={{
            RequestID: json.Params ? json.Params.RequestID : undefined,
            ...subData.props,
            CLSID: json.CLSID,
            SectionID: subData.props.SectionID,
          }}
        />
      );
      break;
    case "{AE5A67E0-EBEB-11D5-900D-000021E6A31F}":
      content = (
        <>
          <DialogAddress
            props={{
              ...subData.props,
              RequestID: json.Params ? json.Params.RequestID : undefined,
            }}
          />
        </>
      );
      break;
    case "{48C155C0-1336-11D6-9CF2-DB1D5BC3046D}":
      content = (
        <DialogDoc
          props={{
            RequestID: json.Params ? json.Params.RequestID : undefined,
            ...subData.props,
            CLSID: json.CLSID,
            Title: json.title,
            SectionID: subData.props.SectionID,
          }}
        />
      );
      break;
    case "{E39CC90D-5F17-4FDF-883E-E75DEA69BE60}":
      content = (
        <DialogNFA
          props={{
            RequestID: json.Params ? json.Params.RequestID : undefined,
            ...subData.props,
            CLSID: json.CLSID,
            NodeID: json.NodeID,
            Title: json.title,
            Module: "storobj",
            SectionID: subData.props.SectionID,
          }}
        />
      );
      break;

    case "{03338F11-FC1E-11D4-A605-0000E8D9CBDB}":
      if (json.SelectDocument) {
        content = (
          <DialogDocTabs
            props={{
              RequestID: json.Params ? json.Params.RequestID : undefined,
              ...subData.props,
              CLSID: json.CLSID,
              Title: json.title,
              SectionID: subData.props.SectionID,
              SelectDocument: json.SelectDocument,
            }}
          />
        );
        break;
      }
      content = (
        <DialogDoc
          props={{
            RequestID: json.Params ? json.Params.RequestID : undefined,
            ...subData.props,
            CLSID: json.CLSID,
            Title: json.title,
            SectionID: subData.props.SectionID,
          }}
        />
      );
      break;

    case "{353FD9D7-651E-4840-9319-A8578806C496}":
      content = (
        <DialogMultiSection
          props={{
            RequestID: json.Params ? json.Params.RequestID : undefined,
            ...subData.props,
            json: json,
            SectionID: subData.props.SectionID,
          }}
        />
      );
      break;
    case "{55D200F8-A5EE-4BB8-B9AD-762B6FB815D1}":
    case "{408E20A3-4BE3-4DCD-98BD-2613A8968783}":
      content = (
        <DialogTabsForReports
          props={{
            RequestID: json.Params ? json.Params.RequestID : undefined,
            ...subData.props,
            json: json,
          }}
        />
      );
      break;

    case "{B52AB815-36CC-4921-A1C0-6C3649D9D0E9}":
      content = (
        <DialogCalendar
          props={{
            RequestID: json.Params ? json.Params.RequestID : undefined,
            ...subData.props,
            json: json,
          }}
        />
      );
      break;

    case "{AC183F1C-2254-4B7C-AEBE-250EF345B74D}":
      content = (
        <DialogHtml
          htmlInput={subData.props.content ? subData.props.content : ""}
          RequestID={subData.props.RequestID}
          Buttons={subData.props.Buttons}
          Module={json.Module}
        />
      );
      break;

    default:
      if (json.CLSID) {
        content = (
          <div style={{ width: "100%", height: "100%" }}>
            Функционал в разработке
          </div>
        );
        break;
      }
      if (json.DlgType) {
        switch (json.DlgType) {
          case "tree":
            props = {
              RequestID: json.Params ? json.Params.RequestID : undefined,
              ...subData.props,
              CLSID: json.CLSID,
              SectionID: subData.props.SectionID,
              showButtons: true,
              multiCheck: false,
            };
            return <TestTree props={props} />;
          case "complex":
            props = {
              RequestID: json.Params ? json.Params.RequestID : undefined,
              ...subData.props,
              CLSID: json.CLSID,
              DlgType: json.DlgType,
              NodeID: json.NodeID,
              SectionID: subData.props.SectionID,
            };
            return <DialogPerson props={props} />;
          default:
            content = (
              <div style={{ width: "100%", height: "100%" }}>
                Функционал в разработке
              </div>
            );
            break;
        }
      }
      content = (
        <div style={{ width: "100%", height: "100%" }}>
          Функционал в разработке
        </div>
      );
  }

  return content;
}
