import React, { useLayoutEffect, useRef } from "react";
import { GridLoader } from "../../rcsgrid/createGridTools";
import { Box, Button, Grid, Paper } from "@mui/material";
import ContextMenu from "../../NotWorkArea(Side&Head)/ContextMenu";
import { PostHandleTable, getObjText } from "../../Tools/Requests";
import { tokenProcessingTest } from "../../../TokenProcessing/TokenProcessing";
import { AxiosRequest } from "../../../Url";
import ContentOrganization from "./Components/ContentOrganization";
import { TabItem } from "smart-webcomponents-react/tabs";
import LoadingMask from "../../NotWorkArea(Side&Head)/LoadingMask";
import SectionDocuments from "../../Sections/ElementsSections/SectionDocuments/SectionDocuments";
import { formEdit } from "../../Tools/Tools";
import {
  getDetailContent,
  updateTabContentFunc,
} from "./Components/DialogDetailsTools";

export default function DialogClsBook({ props }) {
  const gridpanelRef = useRef(null);
  const [mainGrid, setMainGrid] = React.useState();

  const [collapsedButtons, setCollapsedButtons] = React.useState({});

  const [countRecords, setCountRecords] = React.useState("0");
  const [tabs, setTabs] = React.useState(null);
  const [needUpdate, setNeedUpdate] = React.useState(false);
  const [deleteFilter, setDeleteFilter] = React.useState();

  const [timer, setTimer] = React.useState(null);

  const [selectedRecordID, setSelectedRecordID] = React.useState(props.ID);

  const tabsPanel = React.useRef();
  const Pages = React.useRef([]);
  const buttonsRef = React.useRef();
  useLayoutEffect(() => {
    PostHandleTable(
      props.Module,
      props.ObjType,
      undefined,
      props.SectionID
    ).then((res) => {
      if (res.Pages) {
        Pages.current = res.Pages.split("\r\n").map((page) => {
          return { Caption: page };
        });
      }
      if (res.Filter) setDeleteFilter("1");
      GridLoader(gridpanelRef.current, props, {
        json: res,
        setSelectedRecord: setSelectedRecordID,
        setCountRecords: setCountRecords,
      }).then((res) => {
        setMainGrid(res);
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (mainGrid) {
      setTabs(getTabs());
      setNeedUpdate(true);
    }
  }, [mainGrid]);

  React.useLayoutEffect(() => {
    if (selectedRecordID) {
      setNeedUpdate(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRecordID]);

  React.useLayoutEffect(() => {
    if (!collapsedButtons.detail) {
      setTimeout(() => {
        setNeedUpdate(true);
      }, 251);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsedButtons.detail]);

  React.useLayoutEffect(() => {
    if (tabsPanel.current && needUpdate) {
      updateTab(tabsPanel.current.selectedIndex, tabs);
      setNeedUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needUpdate]);

  function getTabs() {
    let details = [];
    if (Pages.current && Pages.current.length) {
      details = Pages.current.map((page) => {
        return getLoadTab(page.Caption);
      });
    }
    return details;
  }

  function getLoadTab(Caption) {
    return (
      <TabItem
        id={`LoadTab_${Caption}`}
        key={`TabItem_${Caption}`}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
        label={Caption}
      >
        <div
          id={`item_${Caption}`}
          load="true"
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          <LoadingMask />
        </div>
      </TabItem>
    );
  }

  function getEmptyTab(Caption) {
    return (
      <TabItem
        id={`LoadTab_${Caption}`}
        key={`TabItem_${Caption}`}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
        label={Caption}
      >
        <div
          id={`item_${Caption}`}
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Функционал в разработке
        </div>
      </TabItem>
    );
  }

  function getFullTab(detailItem, content) {
    return (
      <TabItem
        id={`Tab_${detailItem.Caption}`}
        key={`TabItem_${detailItem.Caption}`}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
        label={detailItem.Caption}
      >
        <div
          id={`item_${detailItem.Caption}`}
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          {content}
        </div>
      </TabItem>
    );
  }

  function getDocTab(page) {
    return (
      <TabItem
        id={`DocTab_${page.Caption}`}
        key={`TabItem_${page.Caption}`}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
        label={page.Caption}
      >
        <div
          id={`item_${page.Caption}`}
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SectionDocuments
            id={props.SectionID}
            DocCfgID={page.DocCfgID}
            ParentID={page.ParentID}
            HideParams={true}
            SectionToolsJS={false}
            PlaneView={"0"}
            setRecorcCount={() => {}}
            HideControlButtons={true}
            footerID={`footerData_${props.SectionID}`}
            noAutoFocus={true}
            // buttonsContainerRef={buttonsRef}
          />
        </div>
      </TabItem>
    );
  }

  function GetClsParams(data) {
    const params = new Map();
    params
      .set("prefix", data.Module)
      .set("comand", "GetClsParams")
      .set("ID", data.ID)
      .set("SectionID", data.SectionID)
      .set("Name", data.Name)
      .set("ObjType", data.ObjType);
    if (data.Info) {
      params.set("Info", data.Info);
    }
    if (data.Path) {
      params.set("Path", data.Path);
    }
    if (data.ChangeID) {
      params.set("ChangeID", data.ChangeID);
    }
    if (data.ProgID) params.set("ProgID", data.ProgID);
    return AxiosRequest(true, params);
  }

  async function getContentForTab(pages, index) {
    let json;
    let page = pages.current[index];

    if (page.Info !== undefined) {
      json = await GetClsParams({
        Module: props.Module,
        ID: selectedRecordID,
        ObjType: props.ObjType,
        SectionID: props.SectionID,
        Info: page.Info,
        ProgID: page.ProgID,
        Path: page.Path,
        ChangeID: page.ChangeID,
      });
      if (json.Props) {
        page.Items = page.Items.map((item) => {
          if (json.Props[item.ShortName]) {
            item = {
              ...item,
              Value: json.Props[item.ShortName].Value,
              EditVal: json.Props[item.ShortName].EditVal,
              ID: json.Props[item.ShortName].ID,
              TextColor: json.Props[item.ShortName].TextColor,
              CheckState: json.Props[item.ShortName].CheckState,
            };
            return item;
          }
        });
      }
      page.ObjRef = json.ObjRef;
      page.ParentID = json.ParentID;
      page.DocCfgID = json.ObjType;
      page = pages.current[index] = { ...page, ...json };
    } else {
      json = await GetClsParams({
        Module: props.Module,
        ID: selectedRecordID,
        SectionID: props.SectionID,
        Name: page.Caption,
        ObjType: props.ObjType,
      });
      json.DocCfgID = json.ObjType;
      json.ObjType = props.ObjType;
      page = pages.current[index] = {
        ...page,
        ...json,
      };
    }

    switch (page.CLSID) {
      case "{37912C20-59B2-415F-8F59-DE7F85AABB00}":
        return "clsbook";
      default:
        return "documents";
    }
    // "{D8402CE6-6582-4F0D-A82D-C2D9CA73F79E}"
    // {48C155C0-1336-11D6-9CF2-DB1D5BC3046D}
    // {C3A20435-640D-4653-A821-C6C6E76A1B63}
  }

  function updateTabsContent(data, firstLoad) {
    if (firstLoad) {
      data.firstLoadFunc();
    } else {
      updateTabContentFunc(data);
    }
  }

  function getReqForContent(selector) {
    switch (selector) {
      default:
        break;
    }
  }

  async function updateTab(index, tabs) {
    if (index !== undefined && tabs && Pages.current && Pages.current.length) {
      if (collapsedButtons.detail) return;
      clearTimeout(timer);
      const newTimer = setTimeout(async () => {
        const selector = await getContentForTab(Pages, index);
        const detailItem = Pages.current[index];

        const blockTab = tabsPanel.current.nativeElement.getTabContent(index);

        const firstLoad = blockTab.querySelector("div[load='true']");
        updateTabsContent(
          {
            Module: props.Module,
            detailItem: detailItem,
            selectedRecordID: selectedRecordID,
            index: index,
            element: blockTab.ownerElement,
            selector: selector,
            SectionID: props.SectionID,
            onRequest: getReqForContent(selector),
            firstLoadFunc: () => {
              const tabContent = getDetailContent(selector, props, {
                selectedRecordID: selectedRecordID,
                onRequest: getReqForContent(selector),
                detailItem: detailItem,
              });
              const tab = getFullTab(Pages.current[index], tabContent);

              tabs[index] = tab;
              setTabs([...tabs]);
            },
          },
          firstLoad
        );
      }, 100);
      setTimer(newTimer);
    }
  }

  function checkRequest(request) {
    if (!request) {
      const reqValue =
        props.KeyField === "Code"
          ? gridpanelRef.current.grid.source.getFieldValueSync("CODE")
          : gridpanelRef.current.grid.source.getFieldTextSync(
              props.KeyField.toUpperCase()
            );
      return { Name: "ObjRef", Value: reqValue };
    }
    return request;
  }

  async function onSelect(func, RequestId, from, req) {
    const request = checkRequest(req);
    let selection = "";
    if (props.MultiSel) {
      selection = [];
      for (let value of Object.values(mainGrid.source.ClsSelection)) {
        selection.push(value);
      }
      selection = selection.join(",");
    }
    if (props.onEdit && props.setValue && props.setInputValue) {
      const objRef =
        props.KeyField === "Code"
          ? gridpanelRef.current.grid.source.getFieldValueSync("CODE")
          : gridpanelRef.current.grid.source.getFieldTextSync(
              props.KeyField.toUpperCase()
            );
      if (props.record) {
        props.onEdit({
          tag: objRef,
          name: props.Name,
          fieldname: props.FieldName,
          current: props.Current,
          record: props.record,
          textchanged: "0",
          selection: selection,
          multiselect: props.MultiSel,
          setValue: props.setValue,
          setInputValue: props.setInputValue,
          type: props.Module,
          requestId: props.RequestID,
          addInfo: props.addInfo,
        });
        return;
      } else if (props.setValue && props.setInputValue) {
        props.onEdit({
          value: getObjText(props.Module, props.ObjType, objRef, "0").Text,
          tag: objRef,
          name: props.Name,
          setValue: props.setValue,
          setInputValue: props.setInputValue,
          type: props.Module,
          requestId: props.RequestID,
          addInfo: props.addInfo,
        });
        return;
      }
    }

    await formEdit("1", func, request, RequestId, from);
  }

  function ShowOnlyActual(Module, ObjType, Value) {
    const params = new Map();
    params
      .set("prefix", Module)
      .set("comand", "ShowOnlyActual")
      .set("ObjType", ObjType)
      .set("Value", Value);
    return AxiosRequest(true, params);
  }
  function ChangeStatus(Module, ObjType, ID, Hidden) {
    const params = new Map();
    params
      .set("prefix", Module)
      .set("comand", "ChangeStatus")
      .set("ObjType", ObjType)
      .set("ID", ID)
      .set("Hidden", Hidden);
    return AxiosRequest(true, params);
  }

  async function ContextMenuHandler(data) {
    const comand = data.value;
    let json;
    const objRef =
      props.KeyField === "Code"
        ? gridpanelRef.current.grid.source.getFieldValueSync("CODE")
        : gridpanelRef.current.grid.source.getFieldTextSync(
            props.KeyField.toUpperCase()
          );
    switch (comand) {
      case "ShowActualCls":
        ShowOnlyActual(props.Module, props.ObjType, data.state ? "0" : "1");
        mainGrid.grid.refreshSource();
        break;
      case "ShowCls":
        ChangeStatus(props.Module, props.ObjType, objRef, data.state);
        mainGrid.grid.refreshSource();
        break;
      case "HideCls":
        ChangeStatus(props.Module, props.ObjType, objRef, data.state);
        mainGrid.grid.refreshSource();
        break;

      default:
        break;
    }
  }

  function getBookContextMenu() {}

  function ResetFilter(Module, ObjType, SectionID) {
    const params = new Map();
    params
      .set("prefix", Module)
      .set("comand", "ResetFilter")
      .set("ObjType", ObjType)
      .set("SectionID", SectionID);
    return AxiosRequest(true, params);
  }

  function onDeleteFilter() {
    setDeleteFilter(undefined);
    ResetFilter(props.Module, props.ObjType, props.SectionID);
    mainGrid.grid.refreshSource();
  }

  return (
    <ContentOrganization
      props={props}
      data={{
        getCollapsed: setCollapsedButtons,
        gridPanel: gridpanelRef,
        mainGrid: mainGrid,
        countRecords: countRecords,
        tabs: tabs,
        tabsPanel: tabsPanel,
        onDeleteFilter: onDeleteFilter,
        updateTab: updateTab,
        mainMenuSelector: "clsbook",
        mainMenuRequest: getBookContextMenu,
        ContextMenuHandler: ContextMenuHandler,
        onSelect: () => {
          return onSelect(
            props.func,
            props.RequestID,
            props.from,
            props.request
          );
        },
        onCancel: () => {
          return formEdit(
            "0",
            props.func,
            props.RequestID,
            props.from,
            props.request
          );
        },
        docFooter: true,
        buttonsRef: buttonsRef,
        // selectedRecordID: selectedRecordID ? selectedRecordID : "0",
      }}
      buttons={{
        detail: true,
        deleteFilter: deleteFilter,
      }}
    />
  );
}
