import SplitterLocal from "../../../../Tools/Splitter";

export default function AccountPlanMainBlock() {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        border: "1px solid rgb(204, 204, 204)",
        borderTop: "0",
      }}
    >
      <div
        style={{
          height: "32px",
          width: "100%",
          border: "1px solid rgb(200, 181, 143)",
        }}
      ></div>
      <div
        style={{
          height: "calc(100% - 32px)",
          width: "100%",
        }}
      >
        <SplitterLocal
          onResizeEnd={() => {}}
          onCollapse={() => {}}
          onExpand={() => {}}
          orientation="vertical"
          style={{
            width: "100%",
            height: "100%",
          }}
          primaryIndex={0}
          // collapsed={colapsedUsers}
          transitionDuration={250}
          sizeSecondary={"20%"}
          hideButtons={true}
        >
          <div style={{ height: "100%", width: "100%" }}></div>
          <div style={{ height: "100%", width: "100%" }}></div>
        </SplitterLocal>
      </div>
    </div>
  );
}
