import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

export async function treeMenu(
  event,
  props,
  clickMenu,
  items,
  menuForGrid,
  CreateMenuItem,
  AssignMenuItems
) {
  const tree = event.target.closest("smart-tree");
  if (!tree) return;

  let item = tree.querySelector("smart-tree-items-group[aria-selected = true]");
  //получение выбранного item в дереве если нет выбранной группы
  if (!item) {
    item = tree.querySelector("smart-tree-item[aria-selected = true]");
  }
  //получение текста из выбранного item
  let id = "0";
  let itemText = "";
  if (item) {
    id = item.id.split("_")[1];
    itemText = tree.querySelector(`#text-tree-item_${id}`).innerText;
  }

  if (typeof props.onRightClick === "function") {
    // TO DO ПЕРЕНЕСТИ ВНУТРЬ ТЕЙБЛ
    //тут мы посылаем запрос из пропсов, и если надо добавляем кнопки к стандартному списку
    clickMenu = props.onRightClick(id);
    clickMenu = clickMenu ? clickMenu : {};
    if (
      clickMenu.arr &&
      clickMenu.arr.length > 0 &&
      clickMenu.arr[clickMenu.arr.length - 1].Caption !== "Divider"
    ) {
      clickMenu.arr.push({ Caption: "Divider" });
    }
  }

  items.push(
    await CreateMenuItem({
      MenuData: AssignMenuItems("Field", clickMenu.arr ? clickMenu.arr : [], [
        ...menuForGrid.Field,
        {
          Caption: "Добавить раздел",
          value: "AddSection",
          item: item ? item : tree,
          text: itemText,
          id: id,
        },
        {
          Caption: "Добавить подраздел",
          value: "AddSubSection",
          item: item ? item : tree,
          text: itemText,
          id: id,
        },
        item
          ? {
              Caption: `Удалить раздел ${itemText}`,
              value: "Delete",
              item: item,
              text: itemText,
              id: id,
              Disabled: item.dataset.noDel === "1",
            }
          : undefined,
      ]),
    })
  );
}

export async function paramsMenu(
  event,
  props,
  items,
  menuForGrid,
  CreateMenuItem,
  AssignMenuItems
) {
  if (event.target.tagName === "INPUT") return;
  event.preventDefault();

  let savedItems, currentSample;
  const endOptions = [];
  if (typeof props.onRightClick === "function") {
    const data = await props.onRightClick();
    savedItems = Object.values(data.json).map((item) => {
      return {
        Caption: `Шаблон ${typeof item === "object" ? item.text : item}`,
        value: "SelectSample",
        name: typeof item === "object" ? item.text : item,
      };
    });

    currentSample = data.Sample;
    endOptions.push({
      Caption: "Фильтр параметров",
      value: "FilterParams",
      Image: data.filter ? <CheckIcon fontSize="small" /> : null,
    });
  }
  const options = [];

  if (savedItems.length) {
    options.push({
      Caption: "Сохраненные шаблоны",
      items: { ...savedItems },
    });
    if (currentSample) {
      options.push({
        Caption: `Удалить шаблон: ${currentSample}`,
        value: "DeleteSample",
        name: currentSample,
      });
    }
  }
  items.push(
    await CreateMenuItem({
      MenuData: AssignMenuItems(
        "Field",
        [],
        [...options, ...menuForGrid.Field, ...endOptions]
      ),
    })
  );
}

export async function addressMenu(
  event,
  items,
  CreateMenuItem,
  AssignMenuItems,
  setContextMenu
) {
  const rec = event.target.closest("TR");

  if (!rec) {
    setContextMenu(null);
    return;
  }

  items.push(
    await CreateMenuItem({
      MenuData: AssignMenuItems(
        "Field",
        [],
        [
          {
            Caption: `Очистить значение для: ${rec.dataset.name}`,
            value: "ClearValues",
            rec: rec,
          },
        ]
      ),
    })
  );
}

export async function gridMenu(
  target,
  props,
  clickMenu,
  items,
  buttonsArr,
  menuForGrid,
  CreateMenuItem,
  AssignMenuItems,
  setContextMenu,
  gridRef,
  GetSortButtons,
  ClassListForGridMain,
  ClassListForGridHead,
  ClassListForGridGroup
) {
  gridRef.current = target.closest(".grid-panel");
  if (
    //ищем есть ли класс у элемента, если есть то это клик в грид
    ClassListForGridMain.includes(target.classList[0])
  ) {
    if (typeof props.onRightClick === "function") {
      // TO DO ПЕРЕНЕСТИ ВНУТРЬ ТЕЙБЛ
      //тут мы посылаем запрос из пропсов, и если надо добавляем кнопки к стандартному списку
      clickMenu = await props.onRightClick();
      clickMenu = clickMenu ? clickMenu : {};
      if (
        clickMenu.arr &&
        clickMenu.arr.length > 0 &&
        clickMenu.arr[clickMenu.arr.length - 1].Caption !== "Divider"
      ) {
        if (!clickMenu.arr[0].ID) {
          clickMenu.arr[0].ID = "0";
        }
        clickMenu.arr.push({ Caption: "Divider" });
      }
    }
    buttonsArr = AssignMenuItems(
      "Table",
      clickMenu.arr ? clickMenu.arr : [],
      menuForGrid.Table
    );
    items.push(
      await CreateMenuItem({
        MenuData: [clickMenu.status, ...buttonsArr],
        State: clickMenu.state,
      })
    ); //сохраняем в массив, кнопки
  } else if (
    ClassListForGridHead.includes(target.classList[0]) ||
    target.closest("grid-column")
  ) {
    //клик в хэдэр
    let fieldInfoElem,
      el = target;
    while (el) {
      fieldInfoElem = el.field;
      if (fieldInfoElem) break;
      el = el.parentElement;
    }
    buttonsArr = AssignMenuItems("Header", [], menuForGrid.Header);
    buttonsArr.unshift({
      Caption: `Скрыть столбец ${el.field.title}`,
      value: "HideColumn",
    });
    GetSortButtons(el.field.sortDirect, buttonsArr);
    items.push(
      await CreateMenuItem({
        MenuData: buttonsArr,
        addictionalInfo: {
          fieldName: el.field.fieldName,
          textcomponent: el,
        },
      })
    );
  } else if (
    //клик в область группировки
    ClassListForGridGroup.includes(target.classList[0])
  ) {
    buttonsArr = AssignMenuItems("Grouping", [], menuForGrid.Grouping);
    items.push(await CreateMenuItem({ MenuData: buttonsArr }));
  } else {
    setContextMenu(null);
  }
}

export async function clsbooksGrid(
  target,
  props,
  clickMenu,
  items,
  buttonsArr,
  menuForGrid,
  CreateMenuItem,
  AssignMenuItems,
  setContextMenu,
  gridRef,
  GetSortButtons,
  ClassListForGridMain,
  ClassListForGridHead,
  ClassListForGridGroup
) {
  gridRef.current = target.closest(".grid-panel");
  const grid = gridRef.current.grid;
  if (
    //ищем есть ли класс у элемента, если есть то это клик в грид
    ClassListForGridMain.includes(target.classList[0])
  ) {
    if (typeof props.onRightClick === "function") {
      clickMenu = await props.onRightClick();
      clickMenu = clickMenu ? clickMenu : {};
      if (
        clickMenu.arr &&
        clickMenu.arr.length > 0 &&
        clickMenu.arr[clickMenu.arr.length - 1].Caption !== "Divider"
      ) {
        if (!clickMenu.arr[0].ID) {
          clickMenu.arr[0].ID = "0";
        }
        clickMenu.arr.push({ Caption: "Divider" });
      }
    }

    let clsMenu = [];
    await setClsMenu(clsMenu, grid);
    buttonsArr = AssignMenuItems("Table", clickMenu.arr ? clickMenu.arr : [], [
      ...menuForGrid.Table,
      ...clsMenu,
    ]);
    items.push(
      await CreateMenuItem({
        MenuData: [clickMenu.status, ...buttonsArr],
        State: clickMenu.state,
      })
    );
  } else if (
    ClassListForGridHead.includes(target.classList[0]) ||
    target.closest("grid-column")
  ) {
    //клик в хэдэр
    let fieldInfoElem,
      el = target;
    while (el) {
      fieldInfoElem = el.field;
      if (fieldInfoElem) break;
      el = el.parentElement;
    }
    buttonsArr = AssignMenuItems("Header", [], menuForGrid.Header);
    buttonsArr.unshift({
      Caption: `Скрыть столбец ${el.field.title}`,
      value: "HideColumn",
    });
    GetSortButtons(el.field.sortDirect, buttonsArr);
    items.push(
      await CreateMenuItem({
        MenuData: buttonsArr,
        addictionalInfo: {
          fieldName: el.field.fieldName,
          textcomponent: el,
        },
      })
    );
  } else if (
    //клик в область группировки
    ClassListForGridGroup.includes(target.classList[0])
  ) {
    buttonsArr = AssignMenuItems("Grouping", [], menuForGrid.Grouping);
    items.push(await CreateMenuItem({ MenuData: buttonsArr }));
  } else {
    setContextMenu(null);
  }
}

async function setClsMenu(clsMenu, grid) {
  clsMenu.push({ Caption: "Divider" });

  const recordData = await grid.source.getRecordData();

  if (recordData.info.hidden) {
    clsMenu.push({
      Caption: "Открыть классификатор",
      value: "ShowCls",
      state: "0",
      Image: <VisibilityOutlinedIcon />,
    });
  } else {
    clsMenu.push({
      Caption: "Скрыть классификатор",
      value: "HideCls",
      state: "1",
      Image: <VisibilityOffOutlinedIcon />,
    });
  }

  clsMenu.push({
    Caption: "Показывать только используемые",
    Image: grid.source.actual ? <CheckIcon /> : undefined,
    value: "ShowActualCls",
    state: grid.source.actual,
  });

  clsMenu.push({ Caption: "Divider" });

  let deleteDisabled;
  if (
    grid.source.getFieldTextSync("ID") < 0 ||
    grid.source.getFieldTextSync("$CfgID")
  ) {
    deleteDisabled = true;
  }

  clsMenu.push({
    Caption: "Удалить запись",
    value: "Delete",
    Image: <DeleteIcon fontSize="small" />,
    Disabled: deleteDisabled,
  });
  clsMenu.push({ Caption: "Divider" });
}

export async function reportMenu(props, clickMenu, items, CreateMenuItem) {
  clickMenu = await props.onRightClick();
  if (clickMenu.Items) {
    items.push(await CreateMenuItem({ MenuData: clickMenu.Items.items }));
  }
}

export async function popupMenu(
  target,
  props,
  loadPopupMenu,
  items,
  CreateMenuItem
) {
  let namePopupMenu,
    el = target;
  while (el) {
    namePopupMenu = el.getAttribute("namePopupMenu");
    if (namePopupMenu) break;
    el = el.parentElement;
  }
  const popupMenu = props.onRightClick();
  if (popupMenu && namePopupMenu) {
    let fixedItems = [];
    loadPopupMenu(fixedItems, popupMenu.get(namePopupMenu));
    items.push(await CreateMenuItem({ MenuData: fixedItems }));
  }
}
