import React from "react";

//Компоненты
// import SignIn from "./Singin/SingIn";

//Стили и пропсы
// import WrapperRightSide from "./MainPage/WrapperMainFile";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "../provider/ThemeProvider";

import fallbackRender from "./ErrorFallBack";
import { ErrorBoundary } from "react-error-boundary";
import { lazy, Suspense } from "react";
import LoadingMask from "./MainPage/NotWorkArea(Side&Head)/LoadingMask";
import CheckMobile from "./lk/CheckMobile";
import { Container } from "@mui/material";
import { webkcrypt_invoke } from "./TokenProcessing/TokenProcessing";
import webkcrypt_globals from "./dualplug/webkcrypt_globals";
import cadesplugin_api from "./dualplug/cadesplugin_api";
import webkcrypt_create from "./dualplug/webkcrypt";

const SignIn = lazy(() => import("./Singin/SingIn"));
const WrapperRightSide = lazy(() => import("./MainPage/WrapperMainFile"));
const SignInLK = lazy(() => import("./lk/SignIn"));
const SignUpUmi = lazy(() => import("./lk/SignUp/SignUpUMI"));
const SignUp = lazy(() => import("./lk/SignUp/SignUp"));
const RestorePassword = lazy(() => import("./lk/RestorePassword"));

export const Theme = React.createContext("");

const Wrapper = () => {
  const pjson = require("./../../package.json")
  React.useEffect(() => {
    webkcrypt_globals();
    cadesplugin_api();
    webkcrypt_create();
    window.StimateVersion = "81." + pjson.version + "/1";
  }, []);

  String.prototype.replaceAt = function(index, replacement) {
    return this.substring(0, index) + replacement + this.substring(index + replacement.length);
}

  function detectMob() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }
  if (detectMob() && window.innerWidth < 650) {
    return <CheckMobile />;
  }

  window.onload = function () {
    webkcrypt_invoke("webkcrypt-check-updates", null, (event) => {
      // nothing
    });
  };

  return (
    <main>
      <HashRouter>
        <Routes>
          {window.hideEnter !== undefined && window.hideEnter === true ? (
            <Route
              path="/"
              element={
                <Suspense fallback={<LoadingMask />}>
                  <SignInLK />
                </Suspense>
              }
            />
          ) : (
            <Route
              path="/lk"
              element={
                <Suspense fallback={<LoadingMask />}>
                  <SignInLK />
                </Suspense>
              }
            />
          )}
          {window.hideEnter !== undefined &&
          window.hideEnter === true ? null : (
            <Route
              path="/"
              element={
                <Suspense fallback={<LoadingMask />}>
                  <SignIn />
                </Suspense>
              }
            />
          )}

          {window.BASE_UMI === "1" ? (
            <Route
              path="/restorepassword"
              element={
                <Suspense fallback={<LoadingMask />}>
                  <RestorePassword />
                </Suspense>
              }
            />
          ) : null}
          {window.BASE_UMI === "1" ? (
            <Route
              path="/signup"
              element={
                <Suspense fallback={<LoadingMask />}>
                  <SignUpUmi />
                </Suspense>
              }
            />
          ) : (
            <Route
              path="/signup"
              element={
                <Suspense fallback={<LoadingMask />}>
                  <SignUp />
                </Suspense>
              }
            />
          )}

          <Route
            path="/main"
            element={
              <ThemeProvider>
                <ErrorBoundary fallbackRender={fallbackRender}>
                  <Suspense fallback={<LoadingMask />}>
                    <WrapperRightSide />
                  </Suspense>
                </ErrorBoundary>
              </ThemeProvider>
            }
          />
        </Routes>
      </HashRouter>
    </main>
  );
};

export default Wrapper;
