import {
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import ChecklistIcon from "@mui/icons-material/Checklist";
import Check from "@mui/icons-material/Check";

export default function ColumnButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const MenuItems = (json) => {
    const items = json.filter(filterByOptions);
    return items.map((item, index) => {
      let Disabled = false;
      let field = item.field;
      let hide = field.hide === undefined ? false : field.hide;
      if (field.fieldInfo && field.fieldInfo.Options & 64) {
        Disabled = true;
      }
      if (item.expanded && item.items) {
        return MenuItems(item.items);
      } else {
        return (
          <MenuItem
            onClick={(e) => {
              props.onClick(field);
              const display =
                e.currentTarget.firstChild.firstChild.style.display;
              e.currentTarget.firstChild.firstChild.style.display =
                display === "none" ? "" : "none";
            }}
            disabled={Disabled}
            style={{ height: "25px" }}
            key={"ButtonsMenu" + index}
          >
            <ListItemIcon>
              <Check style={{ display: hide ? "none" : "" }} />
            </ListItemIcon>
            <ListItemText>{field.title}</ListItemText>
          </MenuItem>
        );
      }
    });
  };

  function filterByOptions(value) {
    if(props.viewInfo.horizontal === true){
      return value.field.hideInCard !== true;
    }else{
      return value.field.hideInGrid !== true;
    }
  }

  return (
    <div>
      <IconButton
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <ChecklistIcon fontSize={"small"} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid
            item
            style={{
              backgroundColor: "#e6e6e6",
              position: "absolute",
              height: "100%",
              width: "46px",
            }}
          />
          <Grid item>{MenuItems(props.data)}</Grid>
        </Grid>
      </Menu>
    </div>
  );
}
