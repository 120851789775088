// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tree-item-selected {
    background-color: rgba(99, 159, 230, 0.2);
}

.tree-item-hover{
    background-color: rgba(117, 140, 140, 0.1);
    -webkit-user-select: none;
            user-select: none;
}

.tree-item{
    display : flex;
    align-items : center;
    white-space : nowrap;
    width: max-content;
    border-radius: 3px;
    padding-right: 5px;
    min-width: 100%;
}

.expandButton{
    display : flex;
    justify-content : center;
    align-items :center;
    background : none;
    border : 0;
    border-radius :50%;
    height : 23px;
    width :23px;
    transition:0.2s;
}
.tree-item-checkbox{
    height : 15px;
    width :15px;
    opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/components/MainPage/Windows/ViewData/Tree/treeClasses.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;AAC7C;;AAEA;IACI,0CAA0C;IAC1C,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,oBAAoB;IACpB,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,wBAAwB;IACxB,mBAAmB;IACnB,iBAAiB;IACjB,UAAU;IACV,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,eAAe;AACnB;AACA;IACI,aAAa;IACb,WAAW;IACX,YAAY;AAChB","sourcesContent":[".tree-item-selected {\r\n    background-color: rgba(99, 159, 230, 0.2);\r\n}\r\n\r\n.tree-item-hover{\r\n    background-color: rgba(117, 140, 140, 0.1);\r\n    user-select: none;\r\n}\r\n\r\n.tree-item{\r\n    display : flex;\r\n    align-items : center;\r\n    white-space : nowrap;\r\n    width: max-content;\r\n    border-radius: 3px;\r\n    padding-right: 5px;\r\n    min-width: 100%;\r\n}\r\n\r\n.expandButton{\r\n    display : flex;\r\n    justify-content : center;\r\n    align-items :center;\r\n    background : none;\r\n    border : 0;\r\n    border-radius :50%;\r\n    height : 23px;\r\n    width :23px;\r\n    transition:0.2s;\r\n}\r\n.tree-item-checkbox{\r\n    height : 15px;\r\n    width :15px;\r\n    opacity: 0.5;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
