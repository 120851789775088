import { handleTableAccounts, handleTableDoc } from "../Tools/Requests";
import { booksGrid } from "./GridFunctions/booksGrid";
import { clsBookGrid } from "./GridFunctions/clsbookGrid";
import { groupingOrg } from "./GridFunctions/organizationsGrid/groupingOrgGrid";
import { accountsGrid } from "./GridFunctions/organizationsGrid/accountsGrid";
import { organizationsGrid } from "./GridFunctions/organizationsGrid/organizationsGrid";
import { createGrid, createRecordSource } from "./rcsgrid";
import { dbviewGrid } from "./GridFunctions/dbviewGrid";
import { kladrGrid } from "./GridFunctions/kladrGrid";
import { personsGrid } from "./GridFunctions/personsGrid";
import { documentsGrid } from "./GridFunctions/organizationsGrid/documentsOrgGrid";
import { defaultGrid } from "./GridFunctions/defaultGrid";
import { accToolsGrid } from "./GridFunctions/acctools";
import { certGrid } from "./GridFunctions/certGrid";
import { localGrid } from "./GridFunctions/localGrid";
import { programsGrid } from "./GridFunctions/programsGrid";
import { departmentsGrid } from "./GridFunctions/departmentsGrid";
import { accountsTableGrid } from "./GridFunctions/accountsTableGrid";

export const checkAvailabilityFromCanModify = (flag, grid) => {
  let available = true;
  if (grid) {
    available = !grid.canModify(flag, undefined, grid);
  }
  return available;
};

export async function CheckMultiSelect(grid, field, separator) {
  let multiSelect = grid.getMultiSelect(),
    returnStr = "";

  if (multiSelect.length > 0) {
    for (const index of multiSelect) {
      {
        returnStr += await grid
          .getActiveSource()
          .getFieldText(field ? field : "ID", Number(index));
        if (index !== multiSelect[multiSelect.length - 1])
          returnStr += separator ? separator : ",";
      }
    }
  } else {
    returnStr += grid.getActiveSource().getFieldTextSync(field ? field : "ID");
  }
  return returnStr;
}

//Создание Grid.
// Аргументы:
//  1.gridPanel = jsx-Элемент где будет создан Grid (пример с использованием хука const gridPanelRef = useRef(): GridLoader(gridPanel = gridPanelRef.current, props, {}, func)
//  2.props = Свойства компонента (props.ID, props.ObjType, props.Module)
//  3.data = Объект с данными для post запроса HandleTable(если никакие данные не нужны передается пустой объект {})
// если не передать параметр data , то ему будет присвоено значение по умолчанию
export async function GridLoader(gridPanel, props, data = {}) {
  if (!gridPanel.getAttribute("rcsgrid_panel"))
    gridPanel.setAttribute("rcsgrid_panel", "");

  if (data.refresh) {
    if (data.localGrid) {
      return await localGrid(gridPanel.grid, data.source, props, data);
    }
    return await refreshGrid(gridPanel.grid, props, data);
  }

  let grid;
  let source;
  if (gridPanel !== null) {
    gridPanel.innerHTML = "";
    gridPanel.grid = new createGrid(gridPanel);
    grid = gridPanel.grid;
    source = new createRecordSource();
    // grid.setOptions(0);
    if (data.indicator === false) {
      grid.ShowIndicatorF(false);
    }
    if (data.canModify !== undefined && data.canModify !== null) {
      grid.canModify = data.canModify;
    }
    if (data.localGrid) {
      return localGrid(grid, source, props, data);
    }

    //здесь можно добавлять отрисовку других модулей
    switch (props.Module) {
      case "books":
        await booksGrid(grid, source, props, data);
        break;
      case "dbview":
        await dbviewGrid(grid, source, props, data);
        break;
      case "clsbook":
        await clsBookGrid(grid, source, props, data);
        break;
      case "address":
        await kladrGrid(grid, source, props, data);
        break;
      case "organizations":
        if (data.main === true && data.GroupID !== undefined) {
          await organizationsGrid(grid, source, props, data);
          break;
        }
        if (data.sub === true && data.GroupID !== undefined) {
          await accountsGrid(grid, source, props, data);
          break;
        }
        break;
      case "organizations_group":
        await groupingOrg(grid, source, props, data);
        break;
      case "persons":
        await personsGrid(grid, source, props, data);
        break;
      case "staff":
        await personsGrid(grid, source, props, data);
        break;
      case "documents":
        await documentsGrid(grid, source, props, data);
        break;
      case "acctools":
        await accToolsGrid(grid, source, props, data);
        break;
      case "storobj":
        await organizationsGrid(grid, source, props, data);
        break;
      case "cert":
        await certGrid(grid, source, props, data);
        break;
      case "programs":
        await programsGrid(grid, source, props, data);
        break;
      case "departments":
        await departmentsGrid(grid, source, props, data);
        break;
      case "accounts":
        await accountsTableGrid(grid, source, props, data);
        break;
      default:
        await defaultGrid(grid, source, props, data);
    }
  }

  if (!grid || !source) return;
  return { grid, source };
}

// Функция для обновления таблицы с другим токеном
async function refreshGrid(grid, props, data) {
  switch (data.token) {
    case "HandleAccounts":
      data.source.onHandleRequest = handleTableAccounts(
        props.Module,
        data.ObjType,
        data.GroupID,
        props.SectionID
      );
      break;
    case "HandleObjParams":
      data.source.onHandleRequest = handleTableDoc(
        props.Module,
        data.DocCfgID,
        data.GroupID,
        props.SectionID
      );
      break;
    case "HandleParams":
      data.source.onHandleRequest = handleTableDoc(
        props.Module,
        data.DocCfgID,
        data.GroupID,
        props.SectionID
      );
      break;
    default:
      data.source.onHandleRequest = handleTableDoc(
        props.Module,
        data.DocCfgID,
        data.GroupID,
        props.SectionID
      );
      break;
  }
  // data.source.close();
  // data.source.open();
  await grid.setSource(data.source);
  await data.source.first();
  await grid.refreshSource();
  grid.updateGridSize();
  return { grid, source: data.source };
}
