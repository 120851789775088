import React from "react";
import Tabs, { TabItem } from "smart-webcomponents-react/tabs";
import SectionAccountPlan from "../AccountPlan/SectionAccountPlan";
import SectionTransaction from "../Transactions/SectionTransactions";
import DocsAndReportsContent from "./AccountingComponents/DocsAndReportsContent";

export default function SectionAccounting(props) {
  const tabPanel = React.useRef(null);
  const [multipage, setMultipage] = React.useState({
    Pages: [
      {
        Name: "Журнал проводок",
        content: (
          <SectionTransaction
            sectionData={props.sectionData}
            SectionID={props.SectionID}
          />
        ),
      },
      { Name: "Документы и отчеты", content: <></> },
    ],
  });
  const [content, setContent] = React.useState();

  React.useEffect(() => {
    firstLoadContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onBreadCrumbClick() {
    tabPanel.current.select(1);
  }

  function getContent(index) {
    switch (index) {
      case 0:
        return (
          <SectionTransaction
            sectionData={props.sectionData}
            SectionID={props.SectionID}
          />
        );
      case 1:
        return (
          <DocsAndReportsContent
            CLSID={props.CLSID}
            id={props.id}
            ElemJson={props.ElemJson}
            onBreadCrumbClick={onBreadCrumbClick}
          />
        );
      case 2:
        return (
          <SectionAccountPlan
            sectionData={props.sectionData}
            SectionID={props.SectionID}
          />
        );
    }
  }

  async function firstLoadContent() {
    if (multipage && multipage.Pages !== undefined) {
      const elements = [];
      let index = 0;
      for (let Page of multipage.Pages) {
        const innerContent = Page.content;

        elements.push(
          <TabItem
            key={`multipage-${index}`}
            style={{ height: "100%", width: "100%" }}
            label={Page.Name}
          >
            {
              <div style={{ height: "100%", width: "100%" }}>
                {innerContent}
              </div>
            }
          </TabItem>
        );
        index++;
      }
      setContent([...elements]);
      return;
    }
    setContent(<></>);
  }

  async function updatePage(ev) {
    if (ev.target !== null) return;
    if (content) {
      const id = tabPanel.current.selectedIndex
        ? tabPanel.current.selectedIndex
        : 0;
      const Page = multipage.Pages[id];
      const innerContent = getContent(id);

      content[id] = (
        <TabItem
          key={`multipage-${id}`}
          style={{ height: "100%", width: "100%" }}
          label={Page.Name}
        >
          {<div style={{ height: "100%", width: "100%" }}>{innerContent}</div>}
        </TabItem>
      );
      setContent([...content]);
    }
  }

  return (
    <>
      <Tabs
        ref={tabPanel}
        onChange={async (ev) => await updatePage(ev)}
        animation="none"
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "s",
        }}
      >
        {content}
      </Tabs>
    </>
  );
}
