import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { AxiosRequest } from "../../../Url";
import ContextMenu from "../../NotWorkArea(Side&Head)/ContextMenu";
import { GridLoader } from "../../rcsgrid/createGridTools";
import { closeModal, openModal } from "../../Tools/modalManager";
import { getObjText, setParamObjectText } from "../../Tools/Requests";
import { getDateOfInt, getDateRusFormatWithId, getDefaultMenu, getStyleWindow } from "../../Tools/Tools";
import DialogCreateObj from "./DialogCreateObj";
import DialogSetObjText from "./DialogSetObjText";

export default function DialogHistory(props) {
  const gridPanelRef = React.useRef();
  const [mainGrid, setMainGrid] = React.useState();
  const [records, setRecords] = React.useState([]);
  const [currentDay, setCurrentDay] = React.useState("");

  React.useEffect(() => {
    // firstMainLoad();
    GetParamHistory().then((res) => {
      const result = [];
      if (res && res.Items && res.Items.items && res.Items.items.length) {
        res.Items.items.forEach((item, index) => {
          result.push(item);
          result[index].UsedDate
            ? result[index].UsedDate = getDateOfInt(result[index].UsedDate)
            : result[index].UsedDate = "";
        });
      }
      setCurrentDay(res.UsedDate);
      setRecords(result);
    });
  }, []);

  React.useEffect(() => {
    if (mainGrid === undefined) {
      mainGridLoader(false);
    } else {
      mainGridLoader(true);
    }
  }, [records]);

  async function mainGridLoader(refresh) {
    const res = await GridLoader(gridPanelRef.current, props, {
      localGrid: true,
      refresh: refresh, // обновление данных таблицы без изменения grid
      source: refresh ? mainGrid.source : undefined, // текущий сурс для обновления
      Columns: getColumns(),
      defaultFields: getDefaultFields(),
      keyField: "ID",
      records: records,
      recordKeys: ["ID", "UsedDate", "ObjRef", "Text", "EditVal"],
      titleKey: "Text",
      getFieldText: getFieldText,
      checkFieldText: checkFieldText,
      onPostRecord: onPostRecord,
      Rights: "-1",
    })
    setMainGrid(res);
  }

  function checkFieldText(request) {
    const val = getObjText(props.Module, props.DataType, request.ObjRef, "-1");
    return { Text: val.Text, ObjRef: request.ObjRef, EditVal: val.Text };
  }

  function onPostRecord(request, requestData, data) {
    const modifiedFields = request.RecordSet.Attributes[0];
    const updateRecords = [...records];
    if (modifiedFields === "UsedDate") {
      let newUsedDate = "";
      if (requestData[0].id) newUsedDate = getDateOfInt(requestData[0].id);
      updateRecords[request.RecordIndex].UsedDate = newUsedDate;
    } else {
      let newText = "",
        newObjRef = "",
        newEditVal = "",
        json;
      if (requestData[0]) {
        if (!requestData[0].id) {
          json = setParamObjectText(props.Module, props.DataType, updateRecords[request.RecordIndex].ObjRef, requestData[0].text);
          if (json.CreateNew) {
            openModal(
              <DialogCreateObj
                onEdit={(values) => {
                  requestData[0].text = undefined;
                  requestData[0].id = values.tag;
                  onPostRecord(request, requestData, data);
                }}
                inputNameObj={json.CreateNew.Text}
                spanText={json.CreateNew.Label}
                CLSID={json.CreateNew.CLSID}
                ObjType={props.DataType}
                Module={props.Module}
                groups={json.CreateNew.Groups}
              />,
              {
                Title: "Создать новый объект",
                style: getStyleWindow(
                  `${json.CreateNew.CLSID}${json.CreateNew.Groups ? "Group" : ""}`
                ),
                hiddenButton: true,
                blockMaximize: true,
                blockSaveLocation: true,
              }
            );
            return;
          }
          if (json.Selection && json.Selection.length) {
            if (json.Selection.length === 1) {
              requestData[0] = json.Selection[0];
              onPostRecord(request, requestData, data);
              return;
            }
            openModal(
              <DialogSetObjText
                list={json.Selection}
                NewModalWindow={true}
                onEdit={(values) => {
                  requestData[0].text = values.value;
                  requestData[0].id = values.tag;
                  onPostRecord(request, requestData, data);
                }}
              />,
              {
                Title: "Выберите элемент из списка",
                hiddenButton: true,
                style: getStyleWindow(),
              }
            );
            return;
          }
          newText = json.Text;
          newObjRef = json.ObjRef;
          newEditVal = json.EditVal || newText;
        } else {
          newObjRef = requestData[0].id;
          newText = getObjText(props.Module, props.DataType, newObjRef, "-1").Text;
          newEditVal = newText;
        }
      }
      updateRecords[request.RecordIndex].ObjRef = newObjRef;
      updateRecords[request.RecordIndex].Text = newText;
      updateRecords[request.RecordIndex].EditVal = newEditVal;
    }
    setRecords(updateRecords);
  }

  function getColumns() {
    const valueList = [];
    if (props.Values) Object.values(props.Values).forEach((item) => {
      if (typeof item === "object") {
        valueList.push(item.text);
      } else {
        valueList.push(item);
      }
    })
    return {
      title: [
        {
          fieldName: "UsedDate",
          title: "Дата",
          width: 100,
          fieldInfo: {
            DataType: "6",
            EditStyle: "2",
            Options: "",
            TitleAjust: "",
            TextAjust: "",
            Module: "",
            Values: [],
          },
        },
        {
          fieldName: "Text",
          title: "Значение",
          width: 200,
          fieldInfo: {
            DataType: props.DataType,
            EditStyle: props.EditStyle,
            Options: props.Options,
            TitleAjust: "",
            TextAjust: "",
            Module: props.Module,
            Values: valueList,
          },
        },
      ],
    };
  }

  function getDefaultFields() {
    return [
      {
        Name: "ID",
        Title: "ID",
        Width: "7",
      },
      {
        Name: "UsedDate",
        Title: "Дата",
        Width: "10",
      },
      {
        Name: "ObjRef",
        Title: "ObjRef",
        Width: "7",
      },
      {
        Name: "Text",
        Title: "Значение",
        Width: "20",
      },
      {
        Name: "EditVal",
        Title: "Наименование для редактора",
        Width: "30",
      },
    ];
  }

  function getFieldText(request, data) {
    if (data.records.length) {
      let val = request.FieldName === "Text" ? "EditVal" : request.FieldName;
      const recVal = data.records[request.RecordIndex];
      return { Text: recVal[val] };
    }
    return { Text: "" };
  }

  function GetParamHistory() {
    const params = props.paramsRequest;
    params.set("comand", "GetParamHistory");
    return AxiosRequest(true, params);
  }

  async function SetParamHistory() {
    const params = props.paramsRequest;
    params.set("comand", "SetParamHistory");
    const data = [];
    for (let item = 0; item < records.length; item++) {
      const record = records[item];
      const dataItem = {};
      if (record.UsedDate) dataItem.UsedDate = getDateRusFormatWithId(record.UsedDate).id;
      if (record.ObjRef) dataItem.ObjRef = record.ObjRef;
      data.push(dataItem);
    }
    const json = await AxiosRequest(true, params, { Items: data });
    props.onSelect?.(json);
  }

  return (
    <>
      <Box style={{ width: "100%", height: "calc(100% - 40px)" }}>
        <Grid
          style={{
            width: "100%",
            height: "100%",
            border: "1px solid #cccccc",
            overflow: "auto",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <ContextMenu
            for="grid"
            Menu={getDefaultMenu("history")}
            refs={{ current: mainGrid }}
          >
            <div
              id={`rcgrid_panel`}
              ref={gridPanelRef}
              style={{ width: "100%", height: "100%" }}
            ></div>
          </ContextMenu>
        </Grid>
      </Box>
      <Box style={{ height: "35px", width: "100%", marginTop: "5px" }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item>
            <Button
              size="small"
              variant="outlined"
              style={{ textTransform: "none", marginRight: "10px" }}
              value="1"
              className="button_Modal_Select"
              onClick={(ev) => {
                SetParamHistory();
                closeModal();
              }}
            >
              Выбрать
            </Button>
            <Button
              size="small"
              variant="outlined"
              style={{ textTransform: "none" }}
              value="2"
              className="button_Modal_Close"
              onClick={(ev) => {
                closeModal();
              }}
            >
              Отмена
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
