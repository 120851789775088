import React from "react";
import { GridLoader } from "../../../rcsgrid/createGridTools";
import { Grid } from "@mui/material";
import ButtonsForGridComponent from "../../../rcsgrid/GridTools/ButtonsForGridComponent";

export default function AccountsTable({ props }) {
  const [mainGrid, setMainGrid] = React.useState();
  const [countRecords, setCountRecords] = React.useState();
  const [selectedRecordID, setSelectedRecordID] = React.useState();

  const gridPanel = React.useRef();

  React.useEffect(() => {
    GridLoader(gridPanel.current, props, {
      setSelectedRecord: setSelectedRecordID,
    }).then((res) => {
      setMainGrid(res);
    });
  }, []);

  React.useEffect(() => {
    if (mainGrid) {
      setTimeout(() => {
        mainGrid.grid.updateGridSize();
      }, 251);
      setCountRecords(mainGrid.source.recordCount);
    }
  }, [mainGrid]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      style={{ width: "100%", height: "100%" }}
    >
      <Grid item style={{ width: "100%", height: "30px" }}>
        <ButtonsForGridComponent
          grid={mainGrid}
          selectedRecord={selectedRecordID}
          buttons={{
            navigate: true,
            delete: true,
            save: true,
            card: true,
            create: true,
            editButtons: true,
          }}
        />
      </Grid>
      <Grid style={{ width: "100%", height: "calc(100% - 60px)" }}>
        <div
          ref={gridPanel}
          style={{ width: "100%", height: "100%", position: "relative" }}
        ></div>
      </Grid>
      <Grid
        item
        style={{
          width: "100%",
          height: "30px",
          display: "flex",
          alignItems: "center",
          paddingLeft: "10px",
          border: "1px solid rgb(200, 181, 143)",
        }}
      >
        Количество проводок: {countRecords}
      </Grid>
    </Grid>
  );
}
