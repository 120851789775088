import React from "react";
import { AxiosRequest, hasURLparam } from "../../../../Url";
import Card from "@mui/material/Card";
import { Tabs, TabItem } from "smart-webcomponents-react/tabs";
import Typography from "@mui/material/Typography";
import { CardActionArea, Grid, Link } from "@mui/material";
import Scrollbars from "react-custom-scrollbars-2";
import cn from "classnames";
import {
  addBreadCrumb,
  clearNoneBreadCrumbs,
  isEmptyObject,
} from "../../../Tools/Tools";
import SectionDocuments from "./SectionDocuments";
import { useSelector } from "react-redux";
import { HideAllFooterData } from "../../../NotWorkArea(Side&Head)/Footer";
import { createUrlParams } from "../../../Tools/urlParams";
import { tokenProcessingTest } from "../../../../TokenProcessing/TokenProcessing";

export default function SectionDocumentsChose(props) {
  const [SectionDocs, setSectionDocs] = React.useState([]); //Все документы секции
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [recordCount, setRecorcCount] = React.useState(0);
  const [DocData, setDocData] = React.useState({
    DocCfgID: undefined,
    Name: undefined,
    Index: undefined,
  }); //Текущий выбранный документ
  const sideBarSelected = useSelector((state) => state.sideBar.selected);
  const [seacrhSelected, setSearchSelected] = React.useState({});
  const theme = useSelector((state) => state.theme.theme);

  React.useEffect(async () => {
    let params = new Map();
    params.set("prefix", "documents");
    params.set("comand", "GetSectionDocs");
    params.set("SectionID", props.id);
    params.set("Simple", "1");
    const sectionDocs = await AxiosRequest(true, params);
    clearNoneBreadCrumbs();
    setSectionDocs(sectionDocs);
    if (sectionDocs.length === 1 && !props.onClick) {
      // addBreadCrumb(sectionDocs.Name);
      // setTimeout(() => {
      // console.log("sss", sideBarSelected.Selected === undefined || sideBarSelected.Selected.DocCfgID === undefined);
      setSelectedIndex(1);
      if (
        sideBarSelected.Selected === undefined ||
        sideBarSelected.Selected.DocCfgID === undefined
      ) {
        setDocData({
          DocCfgID: sectionDocs[0].DocCfgID,
          Name: sectionDocs[0].Name,
          Index: 0,
        });
      }
      // }, 100);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useLayoutEffect(() => {
    if (props.id === sideBarSelected.ID && props.seacrhSelected === undefined) {
      if (sideBarSelected.Who) {
        setSearchSelected(sideBarSelected);
      } else {
        //
      }
    }
  }, [sideBarSelected]);

  React.useEffect(() => {
    HideAllFooterData();
  }, [selectedIndex]);

  React.useEffect(() => {
    if (recordCount !== undefined) {
      let secDocs = SectionDocs;
      secDocs.splice(DocData.Index, 1, {
        DocCfgID: DocData.DocCfgID,
        Name: DocData.Name,
        RecordCount: recordCount,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordCount]);

  React.useEffect(() => {
    // эффект на хлебные крошки
    if (
      sideBarSelected.ID === props.id &&
      (selectedIndex !== 0 || props.selectedIndex === 1)
    ) {
      setTimeout(() => {
        const lastli = addBreadCrumb(
          props.selectedIndex === 1 ? "Отчёты" : DocData.Name
        );
        lastli.onclick = function () {
          props.selectedIndex === 1
            ? props.onBreadCrumbClick()
            : setSelectedIndex(0);
          // document.getElementById("buttons_for_section" + props.id).innerHTML = ""
        };
      }, 50);
    }
  }, [sideBarSelected.ID]);

  React.useEffect(() => {
    if (
      sideBarSelected &&
      sideBarSelected.Selected &&
      SectionDocs.length > 0 &&
      sideBarSelected.Selected !== undefined &&
      !isEmptyObject(sideBarSelected.Selected)
    ) {
      const databoutdoc = FilterDocs(sideBarSelected.Selected.DocCfgID);
      if (!isEmptyObject(databoutdoc)) {
        setDocData({
          ...FilterDocs(sideBarSelected.Selected.DocCfgID),
          docid: sideBarSelected.Selected.docid,
          card: sideBarSelected.Selected.card,
        });
        if (SectionDocs.length > 1) CreateFakeBreadCrumb(databoutdoc.Name);
        if (sideBarSelected.Selected.modal === true) {
          tokenProcessingTest({
            Module: "documents",
            Token: "ExposeDocument",
            Break: "1",
            Params: {
              DocCfgID: sideBarSelected.Selected.DocCfgID,
              DocID: sideBarSelected.Selected.docid,
              Options: "0",
              RequestID: "0",
            },
          });
        }
        setSelectedIndex(1);
        props.setSelectedView && props.setSelectedView("doc");
      }
    }
  }, [sideBarSelected, SectionDocs]);

  React.useEffect(() => {
    if (selectedIndex === 0) {
      setDocData({
        DocCfgID: undefined,
        Name: undefined,
        Index: undefined,
      });
    }
  }, [selectedIndex]);

  function FilterDocs(dcfg) {
    let dataAboutDoc = {};
    SectionDocs.filter((value, index) => {
      if (value.DocCfgID === dcfg) {
        dataAboutDoc = {
          DocCfgID: value.DocCfgID,
          Name: value.Name,
          Index: index,
        };
      }
    });
    return dataAboutDoc;
  }

  function ClickDoc(event) {
    const Name = event.currentTarget.getAttribute("Name");
    CreateFakeBreadCrumb(Name);
    setDocData({
      DocCfgID: event.currentTarget.getAttribute("doccfgid"),
      Name: Name,
      Index: event.currentTarget.getAttribute("index"),
    });
    // createUrlParams({doc: event.currentTarget.getAttribute("doccfgid")})
    setTimeout(() => {
      setSelectedIndex(1);
      props.setSelectedView && props.setSelectedView("doc");
    }, 20);
  }

  function CreateFakeBreadCrumb(Name) {
    const lastli = addBreadCrumb(Name);
    lastli.firstChild.onclick = function () {
      setSelectedIndex(0);
      props.onBreadCrumbClick();
      // document.getElementById("buttons_for_section" + props.id).innerHTML = ""
      props.setSelectedView && props.setSelectedView("");
    };

    // createUrlParams({doc: event.currentTarget.getAttribute("doccfgid")})
  }

  return (
    <Tabs
      key={"Section" + props.id}
      class="Tabs"
      selectedIndex={selectedIndex}
      style={{ height: "100%", width: "100%" }}
      tabPosition="hidden"
      animation="none"
      id={"DocumentsAndReports"}
    >
      <TabItem
        style={{
          textTransform: "none",
          paddingBottom: "0px",
          width: "100%",
          display: SectionDocs.length > 0 ? "" : props.onClick ? "" : "none",
        }}
        label="Документы"
      >
        <Scrollbars
          autoHide
          className={cn("backgroundColorStimate", {
            light: theme === "light",
          })}
        >
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            key={"DocumentsSelect" + props.id}
          >
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              sx={{ mt: 0, ml: 0 }}
              style={{ width: "initial", height: "inherit" }}
            >
              {SectionDocs.length > 0 ? (
                SectionDocs.map((item, index) => {
                  return (
                    <Grid item key={`${item.DocCfgID}_${index}`}>
                      <Card style={{}} sx={{ width: 310, height: 100 }}>
                        <CardActionArea
                          doccfgid={item.DocCfgID}
                          name={item.Name}
                          index={index}
                          sx={{ height: "100px" }}
                          onClick={ClickDoc}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Grid
                              key={`div-1-${item.DocCfgID}_${index}`}
                              className={cn("headerStimate", {
                                light: theme === "light",
                              })}
                              item
                              sx={{
                                width: "100px",
                                height: "100px",
                              }}
                            >
                              {/*  #827c64*/}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="48"
                                height="48"
                                viewBox="0 0 24 24"
                                className={cn("iconColorStimatesvg", {
                                  light: theme === "light",
                                })}
                                style={{
                                  marginTop: "25%",
                                  marginLeft: "25%",
                                }}
                              >
                                <path d="M19.903 8.586a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.952.952 0 0 0-.051-.259c-.01-.032-.019-.063-.033-.093zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                                <path d="M8 12h8v2H8zm0 4h8v2H8zm0-8h2v2H8z"></path>
                              </svg>
                            </Grid>
                            <Grid
                              key={`div-2-${item.DocCfgID}_${index}`}
                              item
                              sx={{ ml: 2, width: "calc(100% - 158px)" }}
                            >
                              <Typography
                                variant="subtitle1"
                                component="div"
                                doccfgid={item.DocCfgID}
                                title={item.Name}
                                sx={{
                                  textAlign: "center",
                                  fontFamily: "Oswald",
                                  fontSize: "1.25rem",
                                  letterSpacing: "0.02em",
                                  lineHeight: "1",
                                }}
                                style={{
                                  wordWrap: "anywhere",
                                  textAlign: "left",
                                }}
                              >
                                {item.Name}
                              </Typography>
                            </Grid>
                            <Grid
                              key={`div-3-${item.DocCfgID}_${index}`}
                              item
                              sx={{ pt: 1, width: "42px", height: "100px" }}
                            >
                              <Typography
                                variant="caption"
                                component="div"
                                doccfgid={item.DocCfgID}
                                title={"Количество записей"}
                                sx={{
                                  fontFamily: "Oswald",
                                  textAlign: "center",
                                  fontSize: "0.75rem",
                                  lineHeight: "1",
                                }}
                                style={{
                                  wordWrap: "anywhere",
                                  textAlign: "left",
                                }}
                              >
                                {item.RecordCount === undefined
                                  ? ""
                                  : item.RecordCount}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  );
                })
              ) : (
                <div key={"empty-doc-div"}></div>
              )}
            </Grid>
            <Grid item sx={{ ml: 2 }}>
              {props.onClick ? (
                <Link
                  id={"Reports"}
                  name={"Отчёты"}
                  underline="hover"
                  className={cn("fontColorStimate", {
                    light: theme === "light",
                  })}
                  style={{
                    cursor: "pointer",
                    fontSize: "22px",
                    fontFamily: "Oswald",
                  }}
                  onClick={(e) => {
                    setSelectedIndex(0);
                    props.onClick(e);
                  }}
                >
                  {"Отчёты"}
                </Link>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Scrollbars>
      </TabItem>
      <TabItem
        style={{ textTransform: "none", paddingBottom: "0px", width: "100%" }}
        label="Документ"
      >
        <SectionDocuments
          id={props.id}
          DocCfgID={DocData.DocCfgID}
          ElemJson={selectedIndex === 2 ? props.ElemJson : undefined}
          SectionToolsJS={props.onClick ? false : true}
          setRecorcCount={setRecorcCount}
          ObjRef={DocData.docid}
        />
      </TabItem>
    </Tabs>
  );
}
