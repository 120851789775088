// import { Button, Checkbox, Grid, Paper, Radio , Typography,CircularProgress, Dialog ,DialogActions, RadioGroup, DialogContent, FormControlLabel, FormControl, FormLabel} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { XMLrequest, AxiosRequest } from "../../../../Url";
import { Tabs, TabItem } from "smart-webcomponents-react/tabs";
import Link from "@mui/material/Link";
import {
  CreateBackgroundForModal,
  tokenProcessingTest,
} from "../../../../TokenProcessing/TokenProcessing";
import SectionToolsJS from "../../../Tools/SectionToolsJS";
import Slide from "@mui/material/Slide";
import Draggable from "react-draggable";
import Editor from "../../../Tools/Editor/Editor";
import EditStyleJson from "../../../../Forms/EditStyleB.json";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import ManWhoSoldTheWorld from "../../../rcsgrid/GridTools.jsx";
import AccorionDownIcon from "../../../../../static/images/down.png";
import { Scrollbars } from "react-custom-scrollbars-2";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import EditorAdress from "../../../Tools/Editor/EditorAdress";
import LoadingMask from "../../../NotWorkArea(Side&Head)/LoadingMask";
import { store } from "../../../../../store";
import { Zoom } from "@mui/material";
import { InsertIdReport, getStyleWindow } from "../../../Tools/Tools";
import TestEditor from "../../../../Editor/testEditor";
import ModalWindow from "../../../Module/ModalWindow";
import DialogSetObjText from "../../../Module/Dialogs/DialogSetObjText";
import IMemo from "./Components/IMemo";
import TRadioButton from "./Components/TRadioButton";
import TSectionCheckBox from "./Components/TSectionCheckBox";
import ContextMenu from "../../../NotWorkArea(Side&Head)/ContextMenu";
import TabsForReports from "../SectionReport/TabsForReports";
// import TRadioButton from "./Components/TRadioButton";

function PointerEventsInSideBar() {
  const SideBar = document.getElementById("SideBar_FullRightSide");
  SideBar.style.pointerEvents =
    SideBar.style.pointerEvents === "all" ? "none" : "all";
}

function SlideLocal(props) {
  if (props.animation) {
    return (
      <>
        <Slide direction="left" timeout={200} in={true}>
          {props.children}
        </Slide>
      </>
    );
  } else return <>{props.children}</>;
}

function ZoomLocal(props) {
  if (props.animation) {
    return (
      <>
        <Zoom timeout={500} in={true}>
          {props.children}
        </Zoom>
      </>
    );
  } else return <>{props.children}</>;
}

export function PaperComponent(props) {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummaryLeft = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  // flexDirection: 'row-reverse',
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor: "#ffffff",
}));

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
/////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////

export default function FormsMainFile(props) {
  const [dataForms, setDataForms] = useState();
  const [tab, setTab] = useState(0);
  const [Path, setPath] = useState(undefined); // only for modals
  const [expanded, setExpanded] = React.useState("panel1");
  const [expandedMap, setExpandedMap] = React.useState(new Map());
  const [load, setLoad] = React.useState(true);
  const [radioValues, setRadioValues] = useState({});
  const [height, setHeight] = useState("100%");
  const popupDataRef = useRef(new Map());
  const ContextMenuFunc = useRef(null);
  var TabsName;

  useEffect(() => {
    if (props.jsonFormData !== undefined) {
      if (props.setForm && typeof props.setForm === "function")
        props.setForm(setDataForms);
      if (props.jsonFormData.Form) {
        setPath(props.jsonFormData.Form.Path);
        setDataForms(props.jsonFormData);
      }
    } else {
      GetSectionForms(props.CommitFormOnSection);
    }
  }, [props.jsonFormData]);

  async function GetSectionForms(CommitFormOnSection) {
    //GET /forms~GetSectionForm?LicGUID=8C5F5163443EBAC78D42B78939951952&SectionID=482 HTTP/1.0
    let params = new Map(),
      res;
    PointerEventsInSideBar();
    params.set("prefix", "forms");
    const comand =
      CommitFormOnSection === undefined || CommitFormOnSection === ""
        ? "GetSectionForm"
        : "GetUpdateSectionForm";
    //
    params.set("comand", comand);
    params.set("SectionID", props.id);
    // params.set("WSM", "1")
    if (CommitFormOnSection !== undefined) {
      res = XMLrequest(params);
    } else {
      res = await AxiosRequest(true, params);
    }
    // AxiosRequest(true, params).then((res) => {
    PointerEventsInSideBar();
    if (res.Break && !res.Form) {
      tokenProcessingTest(res, { func: setLoadDataForms, from: "Forms" });
    } else {
      setLoadDataForms(res);
    }
    // });
  }

  const setLoadDataForms = (res) => {
    setDataForms(res);
    setLoad(false);
  };

  function GetParams(json, param) {
    return json[param] === undefined ? json[param.toLowerCase()] : json[param];
  }

  function isEmptyObject(obj) {
    for (var i in obj) {
      if (obj.hasOwnProperty(i)) {
        return false;
      }
    }
    return true;
  }

  function TextFromServerToBrowser(json, keyName, fontSize, click) {
    let Text,
      FontStyle,
      ReferenceLink,
      FontSize,
      autoSize,
      FontColor,
      Alignment,
      BorderWidth,
      Enabled = json.Enabled,
      FontFamily;
    autoSize = GetParams(json, "AutoSize") === "0" ? "hidden" : "unset";
    FontSize = Number(GetParams(json, "Font-size")) + 2;
    Text = GetParams(json, "Text");
    Enabled = Enabled === "1" ? true : Enabled === undefined ? true : false;
    FontSize = isNaN(FontSize)
      ? fontSize === undefined
        ? 11
        : fontSize + 1
      : FontSize;
    ReferenceLink = GetParams(json, "Reference");
    FontStyle = BackFontweight(GetParams(json, "Font-style"));
    FontColor = Enabled ? BackColor(GetParams(json, "Font-color")) : "grey";
    Alignment = ConvertAlignment(json.Alignment);
    BorderWidth = GetParams(json, "BorderWidth");
    BorderWidth =
      BorderWidth === undefined
        ? undefined
        : `${(Number(BorderWidth) / 100) * 50}px`;
    FontFamily = GetParams(json, "Font-name");
    FontFamily = FontFamily === undefined ? "inherit" : FontFamily;

    // parseInt(FontSize, 10)*0,13}px`,
    if (ReferenceLink === "1" && Enabled && !click) {
      return (
        <Link
          keyName={keyName}
          namePopupMenu={json.NamePopupMenu}
          component="button"
          variant="body2"
          underline="hover"
          onClick={Enabled ? ClickFormElement : null}
          style={{ zIndex: "3" }}
        >
          <Typography
            style={{
              fontSize: `${FontSize}px`,
              fontWeight: FontStyle,
              fontStyle: FontStyle,
              textDecoration: FontStyle,
              overflow: autoSize,
              color: FontColor,
              textAlign: Alignment,
              padding: BorderWidth,
              overflow: "hidden",
              fontFamily: FontFamily,
            }}
          >
            {Text}
          </Typography>
        </Link>
      );
    } else {
      return (
        <Typography //onClick={ClickFormElement}
          keyName={keyName}
          style={{
            fontSize: `${FontSize}px`,
            fontWeight: FontStyle,
            fontStyle: FontStyle,
            textDecoration: FontStyle,
            overflow: autoSize,
            color: FontColor,
            textAlign: Alignment,
            padding: BorderWidth,
            overflow: "hidden",
            fontFamily: `${FontFamily}`,
            whiteSpace: "pre-wrap",
          }}
        >
          {Text}
        </Typography>
      );
    }
  }

  const handleChangeAccordion = (panel) => async (event) => {
    let ariaexpanded,
      params = new Map(),
      json;
    setExpanded(!expanded);
    setExpandedMap(expandedMap.set(panel, !expandedMap.get(panel)));
    const id = event.currentTarget.getAttribute("keyName");
    let content = document.getElementById(id + "content");
    ariaexpanded =
      content.style.display === "inline-block" ? "none" : "inline-block";
    content.style.display = ariaexpanded;
    params.set("prefix", "forms");
    params.set("comand", "ElementEvent");
    params.set("SectionID", props.id); /////
    params.set("Name", id);
    params.set("Collapsed", ariaexpanded === "inline-block" ? "0" : "1");
    params.set("WSM", "1");
    json = await AxiosRequest(true, params);
    CheckAnswerOnElementEvent(json, "TCategoryPanelGroup");
  };

  function BackColor(color, who, ClientColor, parentColor) {
    if (who === "TSectionPanel") return "rgb(255, 255, 255)";
    if (color === undefined && who !== "TSectionPanel") return "";
    let colorArr = color.split(":"),
      CheckColor = color;
    if (parentColor === "1" && ClientColor === "") {
      return "inherit";
    }
    if (ClientColor !== undefined && ClientColor !== "") {
      colorArr =
        ClientColor !== undefined && ClientColor !== "-1"
          ? ClientColor.split(":")
          : colorArr;
      CheckColor = ClientColor !== "-1" ? ClientColor : colorArr;
    }
    if (colorArr[1] === undefined) {
      switch (CheckColor) {
        case "Черный":
          return "black";
        case "Бордовый":
          return "rgb(124,10,2)";
        case "Зеленый":
          return "green";
        case "Коричневый":
          return "brown";
        case "Темно-синий":
          return "darkblue";
        case "Пурпурный":
          return "purple";
        case "Тёмно-зеленый":
          return "darkgreen";
        case "Серый":
          return "gray";
        case "Светло-серый":
          return "lightgray";
        case "Красный":
          return "red";
        case "Светло-зеленый":
          return "lightgreen";
        case "Желтый":
          return "yellow";
        case "Голубой":
          return "rgb(0, 191, 255)";
        case "Фиолетовый":
          return "rgb(138, 43, 226)";
        case "Светло-голубой":
          return "aqua";
        case "Белый":
          return "white";
        case "Подсказка":
          return "rgb(245, 245, 220)";
        case "":
          break;
      }
    } else {
      return `rgb(${colorArr[0]},${colorArr[1]},${colorArr[2]})`;
    }
  }

  function BackFontweight(FS) {
    switch (FS) {
      case "жирный":
        return "bold";
      case "курсив":
        return "italic";
      case "подчеркнутый":
        return "underline";
    }
  }

  function roughScale(x, base) {
    const parsed = parseInt(x, base);
    if (isNaN(parsed)) {
      return 0;
    }
    return parsed;
  }

  function ClickCheckBox(event) {
    const checked =
      event.target.checked === true
        ? "1"
        : event.target.checked === false
        ? "0"
        : undefined;
    // event.currentTarget.checked = false;
    const Name = event.currentTarget.parentElement.getAttribute("keyName");
    ClickFormElement({ checkedValue: checked }, Name);
  }

  async function ClickFormElement(event, IName, Index, Tag, Sender) {
    function GetObjectText(text) {
      if (el.dataset.datatype > 20) {
        let params = new Map();
        params.set("prefix", el.dataset.type);
        params.set("comand", "SetObjectText");
        params.set("Flag", "-1");
        params.set("ObjType", el.dataset.datatype);
        params.set("ObjRef", el.dataset.objref);
        params.set("Text", text === "__.__.____" ? "" : text);
        let json = XMLrequest(params);
        if (json)
          if (json.ObjRef) {
            el.dataset.objref = json.ObjRef;
          }
        params = new Map();
        params.set("prefix", el.dataset.type);
        params.set("comand", "GetObjectText");
        params.set("Flag", "0");
        params.set("ObjType", el.dataset.datatype);
        params.set("ObjRef", el.dataset.objref);
        json = XMLrequest(params);
        Tag = el.dataset.objref;
        if (json.Text) {
          el.value = json.Text;
          el.dataset.value = json.Text;
          return json.Text;
        } else {
          el.value = "";
          return "";
        }
      } else return text;
    }
    try {
      if (
        event &&
        event.currentTarget &&
        event.currentTarget.getAttribute("namePopupMenu") &&
        ContextMenuFunc.current !== null &&
        ContextMenuFunc.current !== undefined
      ) {
        ContextMenuFunc.current(event);
        return;
      }
    } catch (error) {
      console.error(error);
    }
    let params = new Map(),
      Name = null,
      el = { dataset: { value: undefined } },
      dataValue,
      checked = undefined;
    if (event !== undefined) {
      if (event.currentTarget) {
        el = event.currentTarget;
        Name = event.currentTarget.getAttribute("name");
        Name =
          Name === null ? event.currentTarget.getAttribute("keyName") : Name;
        Name =
          Name === null ? event.currentTarget.getAttribute("data-path") : Name;
      } else if (event.tagName) {
        el = event;
        Name = event.getAttribute("name");
        Name = Name === null ? event.getAttribute("keyName") : Name;
        Name = Name === null ? event.getAttribute("data-path") : Name;
        Name = Name === null ? event.dataName : Name;
        if (event["$input"]) {
          el = { dataset: { value: event["$input"].element.value } };
        }
      }
      if (event.key === "Enter") {
        Name = event.target.dataset.name;
      }
      if (event.checkedValue !== undefined) {
        checked = event.checkedValue;
        Name = IName;
      } else if (event.dataset) {
        checked = event.dataset.checkstate;
      }
    }
    Name = Name === null ? IName : Name;
    if (Name !== null) {
      if (event)
        dataValue =
          el.dataset.value === undefined
            ? event.dataset === undefined
              ? undefined
              : event.dataset.value
            : el.dataset.value;
      dataValue = GetObjectText(dataValue);
      params.set("prefix", "forms");
      params.set("comand", "ElementEvent");
      if (props.id !== undefined && Path === undefined)
        params.set("SectionID", props.id); /////
      params.set("Name", Name);
      if (Sender !== undefined) {
        params.set("Sender", Sender);
      }
      if (dataValue !== undefined) {
        params.set("Text", encodeURIComponent(dataValue));
      }
      if (props.head) params.set("Head", "1");
      params.set("Tag", Tag === undefined ? 0 : Tag);
      if (Index) params.set("Index", Index);
      if (checked !== undefined) params.set("Checked", checked);
      if (Path !== undefined) params.set("Path", Path);
      params.set("WSM", "1");
      // json = XMLrequest(params);
      // CheckAnswerOnElementEvent(json);
      if (event && event.currentTarget !== undefined) {
        try {
          event.stopPropagation();
        } catch {}
      }
      const json = await AxiosRequest(true, params);
      CheckAnswerOnElementEvent(json);
    }
    // await axios.get(URL(params)).then((res)=> setData(res.data));
    document.activeElement.blur();
  }
  function FinForms(el) {
    let form = el;
    while (
      form.tagName !== "SMART-WINDOW" &&
      form !== undefined &&
      form != null
    ) {
      form = form.parentElement;
      if (form == null) break;
    }
    return form;
  }

  function EditButtonClick(inp) {
    function SetValueEl(otv) {
      if (otv.Form) setDataForms(otv);
    }
    let params = new Map();
    if (inp.events.OnEditButtonClick) {
      let form = FinForms(inp);
      params.set("prefix", "forms");
      params.set("comand", "ElementEvent");
      params.set("SectionID", props.id);
      params.set("Name", inp.dataset.name);
      params.set("et", "edit");
      params.set("BtnClicked", 1);
      if (form)
        if (form.path) {
          params.set("Path", form.path);
        }
      params.set("WSM", 1);
    } else {
      params.set("prefix", "clsbook");
      params.set("comand", "GetObjectValues");
      params.set("ObjType", inp.dataset.datatype);
      params.set("ObjRef", inp.dataset.dataobjref);
    }
    let otv = XMLrequest(params);
    if (otv.Token) {
      tokenProcessingTest(otv, {
        func: SetValueEl,
        ObjRef: inp.dataset.objref,
        // ObjType: inp.dataset.datatype
        ObjType: "0",
        input: inp,
        SectionID: props.id,
        Name: inp.dataset.name,
      });
    }
  }
  function ClickOnDropDownList(objType) {
    let res = new Map();
    let params = new Map();
    if (objType.events.OnDropDownList) {
      let form = FinForms(objType);
      params.set("prefix", "forms");
      params.set("comand", "ElementEvent");
      params.set("SectionID", props.id);
      params.set("Name", objType.dataset.name);
      if (form)
        if (form.path) {
          params.set("Path", form.path);
        }
      params.set("et", "list");
      params.set("List", 1);
    } else {
      params.set("prefix", "clsbook");
      params.set("comand", "GetObjectValues");
      params.set("ObjType", objType.dataset.datatype);
      params.set("ObjRef", objType.dataset.dataobjref);
    }
    let otv = XMLrequest(params).Items;
    if (otv.length) {
      for (let i = 0; i <= otv.length - 1; i = i + 1) {
        let item = otv[i];
        if (item.id) {
          res.set(otv[i].id, otv[i].text);
        } else res.set(i, otv[i]);
      }
    } else {
      let i = 0,
        item = otv["item0"];

      while (item) {
        if (typeof item === "object") {
          res.set(i, otv["item" + i]["text"]);
        } else {
          res.set(i, otv["item" + i]);
        }

        i++;
        item = otv["item" + i];
      }
    }
    return res;
  }

  // функции для нового Редактора в формы
  //
  function getLabelAndId(data) {
    if (!data.value) return { label: "", id: "0" };
    if (data.datatype > 20) {
      let params = new Map();
      params.set("prefix", data.type);
      params.set("comand", "SetObjectText");
      params.set("Flag", "-1");
      params.set("ObjType", data.datatype);
      params.set("ObjRef", data.tag ? data.tag : "0");
      params.set("Text", data.value);
      params.set("WSM", "1");
      const json1 = XMLrequest(params);
      if (json1 && json1.Selection && json1.Selection.length) {
        CreateBackgroundForModal(
          <ModalWindow
            Title={"Выберите элемент из списка"}
            style={getStyleWindow()}
            hiddenButton={true}
          >
            <DialogSetObjText
              list={json1.Selection}
              onEdit={onEditEditor}
              record={data.record}
              CheckState={data.CheckState}
              data={data}
            />
          </ModalWindow>
        );
        return;
      }
      params = new Map();
      params.set("prefix", data.type);
      params.set("comand", "GetObjectText");
      params.set("Flag", "0");
      params.set("ObjType", data.datatype);
      if (json1) params.set("ObjRef", json1.ObjRef);
      params.set("WSM", "1");
      const json2 = XMLrequest(params);
      if (json2 && json2.Text) {
        return { label: json2.Text, id: json1.ObjRef };
      } else {
        return { label: "", id: json1 ? json1.ObjRef : "0" };
      }
    } else return { label: data.value, id: data.tag ? data.tag : "0" };
  }

  function ClickOnDropDownListV2(data) {
    let params = new Map();
    if (data.events.OnDropDownList) {
      params.set("prefix", "forms");
      params.set("comand", "ElementEvent");
      params.set("SectionID", props.id);
      params.set("Name", data.name);
      const form = FinForms(data.editor);
      if (form) params.set("Path", form.path);
      params.set("et", "list");
      params.set("List", 1);
    } else {
      params.set("prefix", "clsbook");
      params.set("comand", "GetObjectValues");
      params.set("ObjType", data.datatype);
      params.set("ObjRef", data.objref);
    }
    let otv = XMLrequest(params);
    const result = [];
    if (otv) {
      otv = Object.values(otv.Items);
      otv.forEach((item, index) => {
        if (typeof item === "object") {
          result.push({ label: item.text, id: item.id });
        } else {
          result.push({ label: item, id: index });
        }
      });
    }
    return result;
  }
  function onEditButtonClick(data) {
    function SetValueEl(otv) {
      if (otv.Form) setDataForms(otv);
    }
    let params = new Map();
    if (data.events.OnEditButtonClick) {
      params.set("prefix", "forms");
      params.set("comand", "ElementEvent");
      params.set("SectionID", props.id);
      params.set("Name", data.name);
      params.set("et", "edit");
      params.set("BtnClicked", 1);
      if (Path) params.set("Path", Path);
      params.set("WSM", 1);
      let otv = XMLrequest(params);
      if (otv.Token) {
        tokenProcessingTest(otv, {
          func: SetValueEl,
          ...data,
        });
      }
    } else {
      if (data.json) tokenProcessingTest(data.json, data.subData);
    }
  }
  function onEditEditor(data) {
    let labelAndId = data.proof
      ? getLabelAndId(data)
      : { label: data.value, id: data.tag };
    let params = new Map();
    params.set("prefix", "forms");
    params.set("comand", "ElementEvent");
    if (props.id !== undefined && data.path === undefined) {
      params.set("SectionID", props.id);
    }
    params.set("Name", data.name);
    if (labelAndId && labelAndId.label) params.set("Text", labelAndId.label);
    if (labelAndId && labelAndId.id) params.set("Tag", labelAndId.id);
    if (data.index) params.set("Index", data.index);
    if (data.checked !== undefined) params.set("Checked", data.checked);
    if (data.path !== undefined) {
      params.set("Path", data.path);
    } else if (Path !== undefined) params.set("Path", Path);
    params.set("WSM", "1");
    AxiosRequest(true, params).then((res) => {
      CheckAnswerOnElementEvent(res);
    });
    if (labelAndId) {
      if (data.setValue) data.setValue(labelAndId);
      if (data.setInputValue) data.setInputValue(labelAndId.label);
    }
    return labelAndId;
  }

  function ClickOnClickItemList(input) {
    let params = new Map(),
      json,
      value;

    if (input.events.OnCloseUpList) {
      params.set("prefix", "forms");
      params.set("comand", "ElementEvent");
      if (props.id !== undefined && Path === undefined)
        params.set("SectionID", props.id);
      else params.set("Path", Path);
      params.set("Name", input.dataset.name);
      params.set("et", "close");
      params.set("CloseUpListIndex", input.dataset.index);
      params.set("CloseUpListStr", input.dataset.value);
      params.set("WSM", 1);
      json = XMLrequest(params);
      input.value = input.dataset.value;
      CheckAnswerOnElementEvent(json);
    } else {
      if (input.dataset.datatype > 20) {
        params.set("prefix", "clsbook");
        params.set("comand", "GetObjectText");
        params.set("Flag", "0");
        params.set("ObjType", input.dataset.datatype);
        params.set("ObjRef", input.dataset.objref);
        json = XMLrequest(params);

        if (json.Text) input.value = json.Text;
        value = json["Text"] === undefined ? input.value : json["Text"];

        ClickFormElement(
          { dataset: { value: input.value } },
          input.dataset.name,
          undefined,
          input.dataset.objref
        );
        return json["Text"];
      } else if (input.dataset.datatype == 15) {
        value = input.dataset.value;
        params.set("prefix", "programs");
        params.set("comand", "SetParamObjectText");
        params.set("ObjType", 15);
        params.set("ObjRef", 0);
        params.set("Text", value);
        json = XMLrequest(params);
        ClickFormElement(
          { dataset: { value: value } },
          input.dataset.name,
          undefined,
          input.dataset.objref
        );
        input.value = value;
      } else {
        value = input.dataset.value;
        ClickFormElement(
          { dataset: { value: value } },
          input.dataset.name,
          undefined,
          input.dataset.objref
        );
        input.value = value;
      }
    }
  }

  function CheckAnswerOnElementEvent(json, from) {
    if (!isEmptyObject(json)) {
      if (json.error === undefined) {
        const TokenReturn = tokenProcessingTest(json, {
          func: setDataForms,
          propsId: props.id,
          from: "Forms",
        });
        if (TokenReturn !== undefined) {
          if (json.Token == "SelectObject") {
            // GetDialogParam(json)
          } else {
            // GetParamDialog(TokenReturn);
          }
        } else if (!isEmptyObject(json.Form)) {
          setDataForms(json);
          if (from !== "TCategoryPanelGroup") setExpandedMap(new Map());
        }
      }
    }
  }

  function sortByIndex(arr) {
    arr.sort((a, b) => (a.Index > b.Index ? 1 : -1));
  }

  function ConvertAlignment(item) {
    switch (item) {
      case "центр":
        return "center";
      case "влево":
        return "left";
      case "вправо":
        return "right";
    }
  }

  function LinkrefClick(event) {
    let frame = event.target;
    let AllLinksInFrame =
      frame.contentDocument.getElementsByClassName("linkref");
    for (const [, value] of Object.entries(AllLinksInFrame)) {
      if (value.onclick === null) {
        value.onclick = function (event) {
          ClickFormElement(event);
        };
      }
    }
  }

  function RadioChange(event) {
    let Name, index;
    event.stopPropagation();
    const value = event.target.value.split(",");
    Name = value[0];
    index = value[1];
    ClickFormElement(undefined, Name, index);
    // const newJsonForRadio = Object.assign({[Name]:event.target.value},radioValues)
  }

  function AnchorsUse(Anchors, jsonStyle, left, top, type, Right, Bottom) {
    if (Anchors !== undefined) {
      let leftLocal = left === undefined ? 0 : left;
      let topLocal = top === undefined ? 0 : top;
      let bottomLocal = top === undefined ? 0 : top;
      let w,
        h,
        returnj = jsonStyle,
        AnchorsArr = Anchors.split(","),
        right = Number(leftLocal) > 20 ? 0 : leftLocal,
        bottom = Number(bottomLocal) > 20 ? 10 : Number(bottomLocal) + 0;

      topLocal = type === "TTabPagePanel" ? 0 : topLocal;
      w = Anchors.includes("лево,право");
      if (!w) w = Anchors.includes("лево,верх,право");
      h = Anchors.includes("верх,низ");
      for (const [, value] of Object.entries(AnchorsArr)) {
        switch (value) {
          case "лево":
            returnj = Object.assign(returnj, { left: `${leftLocal}px` });
            break;
          case "право":
            returnj = Object.assign(returnj, {
              right: `${Right === undefined ? right : Right}px`,
            });
            break;
          case "верх":
            returnj = Object.assign(returnj, { top: `${topLocal}px` });
            break;
          case "низ":
            returnj = Object.assign(returnj, {
              bottom:
                type === true
                  ? `${Bottom === undefined ? "0px" : Bottom}px`
                  : `${Bottom === undefined ? bottom : Bottom}px`,
            });
            break;
        }
      }
      if (w) {
        if (type !== "TButton" && returnj.width) {
          if (!returnj.width.includes("%"))
            returnj = Object.assign(returnj, {
              minWidth: returnj.width,
            });
          delete returnj.width;
        }
      }
      if (h) {
        if (type !== "TButton" && returnj.height) {
          if (!returnj.height.includes("%"))
            returnj = Object.assign(returnj, {
              minHeight: returnj.height,
            });
          delete returnj.height;
        }
      }
      return returnj;
    } else {
      let returnj = jsonStyle;
      Object.assign(returnj, { left: `${left}px` });
      Object.assign(returnj, { top: `${top}px` });
      return returnj;
    }
  }

  function ShouldUseFullScreen(Anchors) {
    if (Anchors !== undefined) {
      let w, h;
      w = Anchors.includes("лево,право");
      h = Anchors.includes("верх,низ");
      return { w: w, h: h };
    }
    // return {w:false,h:false}
  }

  function CalculateSize(pw, cw) {
    let oneprecent = (Number(pw) / 100) * 1;
    return `${Number(cw) / oneprecent}%`;
  }

  function CalculateMargin(cols, target, width, left) {
    let TargetSolo = Number(target.split(":")[0]),
      count = 1,
      elemWidthPrecent,
      LeftPx = 0,
      RightPx = 0,
      RightPrecent,
      LeftPrecent,
      elemPX;
    let ColsArrayWithPrecent = cols.split(";");
    const widthprecent = width / 100;
    for (const [, value] of Object.entries(ColsArrayWithPrecent)) {
      if (value.substring(2, 3) !== "%") {
        if (TargetSolo === count) {
          elemWidthPrecent = `${Number(value) / widthprecent}%`;
          elemPX = Number(value) / 2;
        } else {
          if (count < TargetSolo) {
            LeftPx += Number(value) / 2;
          } else {
            RightPx += Number(value) / 2;
          }
        }
        count += 1;
      }
    }
    if (RightPx > 0) {
      elemPX += RightPx;
    } else if (LeftPx > 0) {
      elemPX -= LeftPx;
    }
    LeftPrecent = `calc(${ColsArrayWithPrecent[0]} - ${elemPX}px)`;
    RightPrecent = `calc(${
      ColsArrayWithPrecent[ColsArrayWithPrecent.length - 1]
    } - ${elemPX}px)`;
    return { ml: LeftPrecent, mr: RightPrecent, w: elemWidthPrecent };
  }

  function ChangeTabs() {
    setTimeout(() => {
      let Tabs, tabsHeader, span;
      Tabs = document.getElementById("Tabs" + props.id);
      if (!Tabs.getAttribute("styled")) {
        Tabs.setAttribute("styled", "true");
        for (const [key, value] of Object.entries(
          Tabs["_tabLabelContainers"]
        )) {
          value.style.BorderRadius = "0px";
          value.classList.add("Borders");
          value.classList.add("selectedTabItem[selected]");
          if (Number(key) === 0) {
            value.classList.add("WithoutLeftBorder");
          } else {
            value.classList.add("LeftBorder");
          }
          if (Tabs["_tabLabelContainers"].length - 1 === Number(key)) {
            value.classList.add("RightBorder");
          } else {
            value.classList.add("WithoutRightBorder");
          }
        }
        tabsHeader = Tabs.children[0].children[0];
        tabsHeader.style.backgroundColor = "#ffffff";
        tabsHeader.classList.add("disableBorder");
        Tabs.children[0].children[0].children[0].classList.add("SmartCustom");
        span =
          Tabs.children[0].children[0].children[0].getElementsByTagName(
            "span"
          )[0];
        span.classList.add("selectionBarCustom");
        Tabs.children[0].children[1].style.overflowY = "hidden"; //Это где данные отображаются
        Tabs.children[0].children[1].classList.add("headerSmartCustom");
      }
    }, 300);
  }

  function isThereChild(json) {
    for (const [, value] of Object.entries(json)) {
      if (value.Type !== undefined) {
        return true;
      }
    }
    return false;
  }

  function CheckFrameEdges(FrameEdges, Radius) {
    let FrameEdgesArr,
      json = {};
    if (FrameEdges !== undefined) {
      FrameEdgesArr = FrameEdges.split(",");

      if (FrameEdgesArr.length > 1) {
        for (const value of Object.values(FrameEdgesArr)) {
          json = Object.assign(json, FrameEdge(value, Radius));
        }
        return json;
      } else {
        return FrameEdge(FrameEdges, Radius);
      }
    }
  }

  function FrameEdge(value, Radius) {
    switch (value) {
      case "ВерхЛево":
        return {
          borderTopLeftRadius: `${Radius}px`,
        };
      case "НизЛево":
        return {
          borderBottomLeftRadius: `${Radius}px`,
        };
      case "ВерхПраво":
        return {
          borderTopRightRadius: `${Radius}px`,
        };
      case "НизПраво":
        return {
          borderBottomRightRadius: `${Radius}px`,
        };
    }
  }

  function CheckAlignInChild(jsonOfChilds, whatSearch, type) {
    const align = whatSearch === undefined ? "целиком" : whatSearch;
    const Type = type === undefined ? undefined : type;
    for (const [, value] of Object.entries(jsonOfChilds)) {
      if (value.Type !== Type) {
        if (value.Align === align) return true;
      }
    }
    return false;
  }

  function CheckAndReturnComponent(json, jsonData) {
    let ReturnComponent = [],
      Disabled,
      Height,
      Left,
      Top,
      Name,
      Width,
      RCDATA,
      Text,
      Visability,
      Right,
      w,
      BGColor,
      returnSub = [],
      style,
      Anchors,
      BorderWidth,
      Bottom,
      position,
      ShowHint,
      Hint;
    Left = GetParams(json, "Left");
    Top = GetParams(json, "Top");
    Height = GetParams(json, "Height");
    Width = GetParams(json, "Width");
    Name = GetParams(json, "Name");
    Disabled = json.Enabled;
    Disabled = Disabled === "1" ? false : Disabled === undefined ? false : true;
    Visability = GetParams(json, "Visible");
    Visability =
      Visability === "1"
        ? "visible"
        : Visability === "0"
        ? "hidden"
        : "visible";
    BGColor = BackColor(
      GetParams(json, "Back-color"),
      json.Type,
      json.ClientColor
    );
    ShowHint =
      json.ShowHint === "1" ? true : json.ShowHint === "0" ? false : undefined;
    Hint = json.Hint;
    Anchors = json.Anchors;
    BorderWidth = json.BorderWidth;
    Right =
      Number(jsonData.widthFromParent) - Number(Left) - Number(json.Width);
    Right = isNaN(Right) ? undefined : Right;
    Bottom =
      Number(jsonData.heightFromParent) - Number(Top) - Number(json.Height);
    Bottom = isNaN(Bottom) ? undefined : Bottom;
    // (Right, jsonData.keyName)
    switch (json.Type) {
      case "TImage":
        let ImageContent;
        RCDATA = GetParams(json, "RCDATA");
        style = {
          position: "absolute",
          // left: `${Left}px`,
          // top: `${Top}px`,
          visibility: Visability,
          width: `${Width}px`,
          height: `${Height}px`,
        };
        style = AnchorsUse(Anchors, style, Left, Top, json.Type, Right);
        if (jsonData.SubLabel === "TCategoryPanel") {
          ImageContent = (
            <ZoomLocal animation={store.getState().theme.animations}>
              <Grid
                namePopupMenu={json.NamePopupMenu}
                keyName={jsonData.keyName}
                style={style}
              >
                <img
                  onClick={json.OnClick === "" ? undefined : ClickFormElement}
                  keyName={jsonData.keyName}
                  style={{
                    width: `${Width}px`,
                    height: `${Height}px`,
                    cursor: json.OnClick === "" ? "context-menu" : "pointer",
                    objectFit:
                      json.Stretch === "1"
                        ? "scale-down"
                        : json.stretch === "1"
                        ? "scale-down"
                        : "unset",
                  }}
                  src={`data:image/png;base64,${RCDATA}`}
                />
              </Grid>
            </ZoomLocal>
          );
        } else {
          ImageContent = (
            <ZoomLocal animation={store.getState().theme.animations}>
              <Grid
                namePopupMenu={json.NamePopupMenu}
                keyName={jsonData.keyName}
                style={style}
              >
                <img
                  onClick={json.OnClick === "" ? undefined : ClickFormElement}
                  keyName={jsonData.keyName}
                  style={{
                    width: `${Width}px`,
                    height: `${Height}px`,
                    cursor: json.OnClick === "" ? "context-menu" : "pointer",
                    objectFit:
                      json.Stretch === "1"
                        ? "scale-down"
                        : json.stretch === "1"
                        ? "scale-down"
                        : "unset",
                  }}
                  src={`data:image/png;base64,${RCDATA}`}
                />
              </Grid>
            </ZoomLocal>
          );
        }
        if (RCDATA !== undefined) ReturnComponent.push(<>{ImageContent}</>);
        // ReturnComponent.push(<Zoom timeout={400} in={store.getState().theme.animations}>{ImageContent}</Zoom>);

        break;

      case "TButton":
        Text = GetParams(json, "Text");
        let ButtonContent;
        const rcdataIcon = json.RCDATA;
        let icon = (
          <img
            style={{ height: "inherit", width: "inherit" }}
            src={`data:image/png;base64,${rcdataIcon}`}
          />
        );
        let sxStyle =
          Text === ""
            ? {
                "& .MuiButton-startIcon": {
                  margin: "0px",
                  width: "inherit",
                  height: "inherit",
                },
              }
            : Text === undefined
            ? { "& .MuiButton-startIcon": { margin: "0px" } }
            : {};
        const DrawFrame = json.DrawFrame === "1" ? true : false;
        // Anchors = ShouldUseFullScreen(Anchors);
        // Right = Anchors.w ?`${Left}px`:`${0}px`;
        let TextContent = TextFromServerToBrowser(json);
        if (Number(Width) < 35 && Number(Height) < 35) {
          TextContent = Text;
        }
        Height = `${Height}px`;
        Width = `${Width}px`;
        style = {
          color: Disabled ? BackColor(json["Font-color"]) : "grey",
          backgroundColor: BackColor(json["Back-color"]),
          minWidth: "1px",
          width: Width,
          height: Height,
          position: "absolute",
          textTransform: "none",
          visibility: Visability,
        };
        style = AnchorsUse(Anchors, style, Left, Top, json.Type, Right);
        if (json.Anchors !== undefined && json.Anchors.includes("право,верх")) {
          Right =
            Number(jsonData.widthFromParent) -
            Number(Left) -
            Number(json.Width);
          delete style.left;
          Object.assign(style, { right: `${Right}px` });
        }
        // (Hint)
        if (ShowHint == true && Hint !== undefined && Hint !== "") {
          ButtonContent = (
            <ZoomLocal animation={store.getState().theme.animations}>
              <Tooltip title={Hint} style={{ zIndex: "99999" }}>
                <Button
                  namePopupMenu={json.NamePopupMenu}
                  keyName={jsonData.keyName}
                  disabled={Disabled}
                  name={Name}
                  secid={props.id}
                  id={jsonData.keyName}
                  onClick={ClickFormElement}
                  variant={DrawFrame ? "outlined" : "text"}
                  startIcon={rcdataIcon === undefined ? undefined : icon}
                  sx={sxStyle}
                  style={style}
                  Path={jsonData.Path}
                >
                  {TextContent}
                  {SubDataProcessing(json, { Path: jsonData.Path })}
                </Button>
              </Tooltip>
            </ZoomLocal>
          );
        } else {
          ButtonContent = (
            <ZoomLocal animation={store.getState().theme.animations}>
              <Button
                namePopupMenu={json.NamePopupMenu}
                keyName={jsonData.keyName}
                disabled={Disabled}
                name={Name}
                secid={props.id}
                id={jsonData.keyName}
                onClick={ClickFormElement}
                variant={DrawFrame ? "outlined" : "text"}
                startIcon={rcdataIcon === undefined ? undefined : icon}
                sx={sxStyle}
                style={style}
                Path={jsonData.Path}
              >
                {TextContent}
                {SubDataProcessing(json, { Path: jsonData.Path })}
              </Button>
            </ZoomLocal>
          );
        }
        ReturnComponent.push(<>{ButtonContent}</>);
        // ReturnComponent.push(<Zoom timeout={400} in={true}><>{ButtonContent}</></Zoom>);
        break;

      case "TSectionCheckBox":
        Text = GetParams(json, "Text");
        ReturnComponent.push(
          <TSectionCheckBox
            disabled={Disabled}
            Checked={json.Checked === "0" ? false : true}
            jsonData={jsonData}
            Top={Top}
            Left={Left}
            Width={Width}
            Height={Height}
            Visability={Visability}
            label={TextFromServerToBrowser(json, jsonData.keyName)}
            onClick={ClickCheckBox}
          />
          // <Grid keyName={jsonData.keyName} style={{ whiteSpace: "nowrap" }}>
          //   <FormControlLabel
          //     keyName={jsonData.keyName}
          //     style={{
          //       width: "max-content",
          //       position: "absolute",
          //       left: `${Left}px`,
          //       top: `${Top}px`,
          //       width: `${Width}px`,
          //       height: `${Height}px`,
          //       visibility: Visability,
          //     }}
          //     control={
          //       <Checkbox
          //         disabled={Disabled}
          //         keyName={jsonData.keyName}
          //         style={{ padding: 0 }}
          //         // defaultChecked={json.Checked === "0" ? false : true}
          //         checked={json.Checked === "0" ? false : true}
          //         onChange={ClickCheckBox}
          //         sx={{ "& .MuiSvgIcon-root": { fontSize: 17 } }}
          //       />
          //     }
          //     label={TextFromServerToBrowser(json, jsonData.keyName)}
          //   />
          // </Grid>
        );
        break;

      case "TSectionEditor":
        let EditStyle,
          EditStyleCompleteInt = 0,
          PickListEditor,
          // list = "",
          list = undefined,
          ReadOnly;
        ReadOnly = json.ReadOnly === "1" ? true : false;
        Text = GetParams(json, "Text");
        Text =
          Text === "" || Text == undefined
            ? json.CloseUpListStr === undefined
              ? ""
              : json.CloseUpListStr
            : Text;
        let fontSize = `${Number(GetParams(json, "Font-size")) + 2}px`;
        let fontColor = BackColor(GetParams(json, "Font-color"));
        let fontFamily = `${GetParams(json, "Font-name")}`;
        let styleInput = {
          fontSize: fontSize ? fontSize : "13px",
          color: fontColor ? fontColor : "#ffffff",
          fontFamily: fontFamily ? fontFamily : undefined,
          height: `calc(${Height}px - 2px)`,
        };
        let styleComponent = {
          height: `calc(${Height}px - 2px)`,
        };
        let checkstate = GetParams(json, "Checked");
        let placeholderEditor = GetParams(json, "EmptyText");
        let showHint = GetParams(json, "ShowHint") === "1" ? true : false;
        let hintText = GetParams(json, "Hint");
        let borderStyle =
          GetParams(json, "BorderStyle") === "линия" ? true : false;
        EditStyle = GetParams(json, "EditStyle");
        PickListEditor = GetParams(json, "PickList");
        style = {
          position: "absolute",
          left: `${Left}px`,
          top: `${Top}px`,
          width: `${Width}px`,
          height: `${Height}px`,
          visibility: Visability,
          alignItems: "center",
          display: "grid",
        };
        if (borderStyle) {
          style.border = "1px solid #cccccc";
        }
        w = Anchors.includes("лево,право");
        if (!w) w = Anchors.includes("лево,верх,право");
        if (w) {
          delete style.width;
          const right = Number(Left) > 20 ? 0 : Left;
          Object.assign(style, { right: `${right}px` });
        }
        EditStyle = EditStyle === undefined ? EditStyle : EditStyle.split(",");
        list = PickListEditor;

        if (EditStyle !== undefined) {
          // for (const [, valueEdit] of Object.entries(EditStyle)) {
          //   for (const [keyJson, value] of Object.entries(EditStyleJson)) {
          //     if (valueEdit === keyJson) {
          //       EditStyleCompleteInt = EditStyleCompleteInt + value;
          //     }
          //   }
          // }
          EditStyle.forEach((item) => {
            EditStyleCompleteInt += EditStyleJson[item]
              ? EditStyleJson[item]
              : 0;
          });
        }
        // const sasstyle = AnchorsUse(Anchors,{position:"absolute" ,left:`${Left}px`, top:`${Top}px`, width: `${Width}px`,height:`${Height}px`, visibility:Visability, alignItems:"center", display:"grid"  },Left,Top)
        ReturnComponent.push(
          // <div keyName={jsonData.keyName} style={style}>
          //   <Editor
          //     DataType={json.ObjType}
          //     Type={json.Module}
          //     from="forms"
          //     ObjRef={json.Tag}
          //     ReadOnly={ReadOnly}
          //     Enabled={Disabled === true ? false : true}
          //     list={list}
          //     name={json.Name}
          //     value={Text}
          //     id={jsonData.keyName}
          //     EditStyle={EditStyleCompleteInt}
          //     style={{ width: `100%`, height: `100%` }}
          //     onDropDownList={ClickOnDropDownList}
          //     onEdit={ClickFormElement}
          //     onClickItemList={ClickOnClickItemList}
          //     OnEditButtonClick={EditButtonClick}
          //     checkstate={json.Checked}
          //     placeholder={json.EmptyText === undefined ? "" : json.EmptyText}
          //     onEvents={{
          //       OnEditButtonClick: json.OnEditButtonClick ? true : false,
          //       OnDropDownList: json.OnDropDownList ? true : false,
          //       OnCloseUpList: json.OnCloseUpList ? true : false,
          //       OnChange: json.OnChange ? true : false,
          //     }}
          //   />
          // </div>
          <div style={style}>
            <TestEditor
              datatype={json.ObjType}
              type={json.Module}
              from="forms"
              objref={json.Tag}
              readonly={ReadOnly}
              background={Disabled}
              list={list}
              name={json.Name}
              id={jsonData.keyName}
              disabled={Disabled === true ? true : false}
              value={Text}
              editStyle={EditStyleCompleteInt}
              checked={checkstate ? checkstate : json.Checked}
              onDropDownList={ClickOnDropDownListV2}
              onEdit={onEditEditor}
              onEditButtonClick={onEditButtonClick}
              placeholder={
                placeholderEditor
                  ? placeholderEditor
                  : json.EmptyText === undefined
                  ? ""
                  : json.EmptyText
              }
              placeholderVisible={"1"}
              showHint={showHint}
              hintText={hintText}
              styleInput={styleInput}
              styleComponent={styleComponent}
              onEvents={{
                OnEditButtonClick: json.OnEditButtonClick ? true : false,
                OnDropDownList: json.OnDropDownList ? true : false,
                OnCloseUpList: json.OnCloseUpList ? true : false,
                OnChange: json.OnChange ? true : false,
              }}
            />
          </div>
        );
        break;

      case "TSectionPanel": // WITH SUB
        if (json.align) {
          Width = "100%";
          Height = "100%";
        } else {
          Width = `${Width}px`;
          Height = `${Height}px`;
        }
        style = {
          position: "absolute",
          left: `${Left}px`,
          top: `${Top}px`,
          width: Width,
          height: Height,
          visibility: Visability,
          backgroundColor: BGColor,
        };
        switch (json.CLSID) {
          case "{A5CDFCEB-A95A-4ABC-839E-77D1D1F5CD86}": {
            if (Left === undefined && Top === undefined) {
              delete style.left;
              delete style.top;
              delete style.position;
            }
            style = AnchorsUse(Anchors, style, Left, Top, Right, Bottom);
            ReturnComponent.push(
              <Grid
                id={`innerHTMLpanel` + props.id}
                keyName={jsonData.keyName}
                style={style}
              >
                <TabsForReports reportData={json} ID={props.id} />
              </Grid>
            );
            break;
          }
          case "{408E20A3-4BE3-4DCD-98BD-2613A8968783}":
            if (Left === undefined && Top === undefined) {
              delete style.left;
              delete style.top;
              delete style.position;
            }

            const iframe = (
              <iframe
                className="iframeStimateCustom"
                srcdoc={json.content.replace(
                  "overflow: hidden;",
                  "overflow: auto;"
                )}
                onLoad={LinkrefClick}
              />
            );
            ReturnComponent.push(
              <Grid
                id={`innerHTMLpanel` + props.id}
                keyName={jsonData.keyName}
                style={style}
              >
                <div style={{ height: "inherit" }}>{iframe}</div>
              </Grid>
            );
            break;
          case "{295EA015-4573-4AD9-922A-A14CE0FD9C78}":
            let Path = json.Params.Path;
            if (Path !== undefined) {
              let params = new Map();
              params.set("prefix", "programs");
              params.set("comand", "GetTableLayout");
              params.set("ObjType", "0");
              params.set("Path", Path);
              params.set("SectionID", props.id);
              XMLrequest(params);
              ReturnComponent.push(
                <Grid
                  id={`gridpanel` + props.id}
                  data-path={Path}
                  keyName={jsonData.keyName}
                  style={{
                    position: "absolute",
                    left: `${Left}px`,
                    top: `${Top}px`,
                    width: Width,
                    height: Height,
                    visibility: Visability,
                    backgroundColor: BGColor,
                  }}
                >
                  <Paper elevation={2}></Paper>
                </Grid>
              );
            }
            break;
          case "{D9DE8B26-C68B-4318-96EA-FD75497A5C91}":
            delete style.backgroundColor;
            style = AnchorsUse(Anchors, style, Left, Top, "", Right);
            if (json.BorderStyle === "нет") {
            }
            ReturnComponent.push(
              <div keyName={jsonData.keyName} style={style}>
                <EditorAdress
                  text={json.Text}
                  height={Number(json.Height)}
                  disabled={Disabled}
                  ReadOnly={json.ReadOnly === "1" ? true : false}
                  style={{
                    width: "100%",
                    height: Height,
                    visibility: Visability,
                    borderStyle:
                      json.BorderStyle === "нет" ? "none" : "revert-layer",
                    border: "none",
                    outline: "none",
                    fontFamily: json["Font-name"],
                  }}
                  keyName={jsonData.keyName}
                  placeholder={
                    json.EmptyText === undefined ? "" : json.EmptyText
                  }
                  ClickForm={ClickFormElement}
                  onlySelectValue={true}
                />
              </div>
            );
            break;
        }
        ReturnComponent.push(
          <Grid
            keyName={jsonData.keyName}
            style={{
              position: "absolute",
              left: `${Left}px`,
              top: `${Top}px`,
              width: `${Width}px`,
              height: `${Height}px`,
              visibility: Visability,
              backgroundColor: BGColor,
            }}
          >
            <Paper elevation={2}>
              {SubDataProcessing(json, { Path: jsonData.Path })}
            </Paper>
          </Grid>
        );
        break;

      case "TLabel":
        let FixedWidth = roughScale(Width, 10) + 8;
        if (json.width === undefined && jsonData.SubLabel !== "TMarkingPanel")
          FixedWidth = undefined;
        style = {
          position: "absolute",
          left: `${Left}px`,
          top: `${Top}px`,
          width: `${FixedWidth}px`,
          height: `${Height}px`,
          visibility: Visability,
          // zIndex:1
        };
        Anchors = json.Anchors;

        if (json.AutoSize === "1") delete style.width;
        if (Anchors !== undefined) {
          Anchors = ShouldUseFullScreen(Anchors);
          if (
            json.Anchors !== undefined &&
            json.Anchors.includes("право,верх") &&
            !json.Anchors.includes("лево")
          ) {
            delete style.left;
            Object.assign(style, { right: `${Right}px` });
          }
        } else {
          Height = `${Height}px`;
        }
        Width = `${Width}px`;
        if (json.Align === "целиком") {
          Width = "100%";
          Height = "95%";
        }
        if (json.Alignment === "центр" && !jsonData.ColsFromParent) {
          Object.assign(style, { width: `${100}%` });
        }

        if (jsonData.ColsFromParent) {
          const jm = CalculateMargin(
            jsonData.ColsFromParent,
            json.Target,
            jsonData.widthFromParent,
            Number(Left)
          );
          style = Object.assign(style, { right: jm.mr, left: jm.ml });
          // delete style.width
        }
        if (Anchors !== undefined && Anchors.w && !jsonData.ColsFromParent) {
          delete style.width;
          style = Object.assign(style, { right: Right });
        }

        ReturnComponent.push(
          <Grid
            namePopupMenu={json.NamePopupMenu}
            keyName={jsonData.keyName}
            style={style}
          >
            {TextFromServerToBrowser(json, jsonData.keyName, jsonData.fontSize)}
          </Grid>
        );

        break;

      case "TPopupMenu":
        // popupDataRef.current.push(Map('key':json.Name,json);
        popupDataRef.current.set(json.Name, json);
        break;

      case "TCategoryPanelGroup": //WITH SUB
        RCDATA = GetParams(json, "RCDATA");
        if (json.Align === "целиком") {
          Height = `100%`;
          Width = `100%`;
        } else {
          Height = `${Height}px`;
          Width = `${Width}px`;
        }

        style = {
          position: "absolute",
          left: `${Left}px`,
          top: `${Top}px`,
          width: Width,
          height: Height,
          overflowY: "auto",
          overflowX: "hidden",
          visibility: Visability,
        };
        style = AnchorsUse(Anchors, style, Left, Top);
        if (Anchors.w) {
          delete style.width;
          style = Object.assign(style, { right: Right });
        }
        if (Anchors.h) {
          delete style.height;
          style = Object.assign(style, { bottom: "20px" });
        }
        if (style.minWidth) delete style.minWidth;

        if (style.minHeight) delete style.minHeight;
        ReturnComponent.push(
          <Grid keyName={jsonData.keyName} style={style}>
            <Scrollbars autoHide>
              {SubDataProcessing(json, {
                RCDATA: RCDATA,
                Path: jsonData.Path,
                subElement: jsonData.SubLabel,
                ChevronAlignment: json.ChevronAlignment,
              })}
            </Scrollbars>
          </Grid>
        );
        break;

      case "TCategoryPanel": //WITH SUB
        let Caption,
          name = jsonData.keyName,
          fs,
          Collapsed,
          styleForParent = { marginBottom: "2%" },
          AccordionSummaryContent;
        if (json.Caption) {
          Caption = json.Caption;
        } else {
          Caption = json.caption;
        }
        if (json.click$name) {
          name = json.click$name;
        }
        Collapsed = json.Collapsed;
        Collapsed =
          json.Collapsed === 1
            ? false
            : json.Collapsed === undefined
            ? false
            : true;
        let BoolOpen = expandedMap.get(jsonData.keyName);
        if (Collapsed && BoolOpen === undefined) {
          expandedMap.set(jsonData.keyName, !Collapsed);
          // expandedMapTest.set(Caption, Collapsed)
        } else if (!Collapsed && BoolOpen === undefined) {
          expandedMap.set(jsonData.keyName, Collapsed);
        }
        BoolOpen = expandedMap.get(jsonData.keyName);
        const imgData =
          json["RCDATA"] === undefined
            ? jsonData.RCDATAFormParent
            : json["RCDATA"];
        let HadImg = imgData !== undefined ? true : false;
        Width = Width === undefined ? "100%" : `${Width}px`;
        position = "relative";
        for (const [, value] of Object.entries(json)) {
          if (value.Type === "TGradientPanel") {
            position = "unset";
          }
        }
        position = jsonData.SubLabel === "TTabbedPages" ? "relative" : position;
        style = {
          width: Width,
          height: `${Height}px`,
          display: BoolOpen ? "inline-block" : "none",
          backgroundColor: "#ffffff",
          padding: 0,
          position: position,
        };
        fs = BackFontweight(json["Font-style"]);
        if (json.align === "целиком") {
          Object.assign(styleForParent, { width: "100%", height: "100%" });
        }
        if (
          jsonData.ChevronAlignment === "право" ||
          jsonData.ChevronAlignment === undefined
        ) {
          AccordionSummaryContent = (
            <AccordionSummary
              keyName={jsonData.keyName}
              style={{ backgroundColor: "#edeae2" }}
              expandIcon={<img src={AccorionDownIcon} />}
            >
              <Grid
                namePopupMenu={json.NamePopupMenu}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                keyName={jsonData.keyName}
              >
                <Grid
                  item
                  style={{ position: "relative", left: "-16px" }}
                  keyName={jsonData.keyName}
                >
                  {imgData === undefined ? (
                    <></>
                  ) : (
                    <img src={`data:image/png;base64,${imgData}`} />
                  )}
                </Grid>
                <Grid item keyName={jsonData.keyName}>
                  <Typography
                    keyName={jsonData.keyName}
                    style={{
                      fontWeight: fs,
                      fontStyle: fs,
                      textDecoration: fs,
                    }}
                  >
                    {Caption}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
          );
        } else if (jsonData.ChevronAlignment === "влево") {
          AccordionSummaryContent = (
            <AccordionSummaryLeft
              keyName={jsonData.keyName}
              style={{ backgroundColor: "#edeae2" }}
              expandIcon={<img src={AccorionDownIcon} />}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                keyName={jsonData.keyName}
              >
                <Grid
                  item
                  style={{ position: "relative", left: "-4px" }}
                  keyName={jsonData.keyName}
                >
                  {imgData === undefined ? (
                    <></>
                  ) : (
                    <img src={`data:image/png;base64,${imgData}`} />
                  )}
                </Grid>
                <Grid item keyName={jsonData.keyName}>
                  <Typography
                    keyName={jsonData.keyName}
                    style={{
                      fontWeight: fs,
                      fontStyle: fs,
                      textDecoration: fs,
                    }}
                  >
                    {Caption}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummaryLeft>
          );
        }

        ReturnComponent.push(
          <Accordion
            expanded={BoolOpen}
            onChange={handleChangeAccordion(jsonData.keyName)}
            keyName={jsonData.keyName}
            style={styleForParent}
          >
            {AccordionSummaryContent}
            <AccordionDetails
              keyName={jsonData.keyName + "content"}
              id={jsonData.keyName + "content"}
              bool={"false"}
              style={style}
            >
              <div style={style}>
                {SubDataProcessing(json, {
                  subElement: "TCategoryPanel",
                  RCDATA: HadImg,
                  Path: jsonData.Path,
                })}
              </div>
            </AccordionDetails>
          </Accordion>
        );
        break;

      case "TTabbedPages": //WITH SUB
        let tabIndex;
        let SortedTabs = [];
        for (const value of Object.values(json)) {
          if (typeof value === "object" && value.Visible === "1") {
            SortedTabs.push(value);
          }
        }
        sortByIndex(SortedTabs);
        tabIndex = json.ItemIndex === undefined ? 0 : Number(json.ItemIndex);
        TabsName = jsonData.keyName;
        function ClicTabItem(event) {
          let gridPanel = document.getElementById("gridpanel" + props.id);

          setTab(event.detail.index);
          ClickFormElement(undefined, TabsName, event.detail.index);

          if (gridPanel) {
            let Path = gridPanel.dataset.path;
            ManWhoSoldTheWorld({ id: props.id, path: Path, who: "Forms" });
          }
        }
        style = {
          position: "absolute",
          left: `${Left}px`,
          top: `${Top}px`,
          width: `${Width}px`,
          height: `${Height}px`,
          display: Visability,
          backgroundColor: BGColor,
          padding: "0px",
        };
        style = AnchorsUse(Anchors, style, Left, Top);
        if (json.Align === "целиком") {
          Object.assign(style, { width: "100%", height: "100%" });
        }
        ReturnComponent.push(
          <Tabs
            keyName={jsonData.keyName}
            class="Tabs"
            selectedIndex={tab}
            id={"Tabs" + props.id}
            onChange={ClicTabItem}
            style={style}
          >
            {SubDataProcessing(SortedTabs, {
              Path: jsonData.Path,
              subElement: "TTabbedPages",
            })}
            {ChangeTabs()}
          </Tabs>
        );
        break;

      case "TTabPagePanel": //WITH SUB
        Text = GetParams(json, "Text");
        Text = Text === undefined ? GetParams(json, "Title") : Text;
        style = {
          position: "absolute",
          left: `${Left}px`,
          width: `${Width}px`,
          height: `${Height}px`,
          display: Visability,
          backgroundColor: BGColor,
          overflow: "auto",
        };
        style = AnchorsUse(Anchors, style, Left, Top, "TTabPagePanel");
        if (style.minWidth) delete style.minWidth;

        if (style.minHeight) delete style.minHeight;

        ReturnComponent.push(
          <TabItem keyName={jsonData.keyName} label={Text} style={style}>
            {SubDataProcessing(json, {
              Path: jsonData.Path,
              subElement: "TTabbedPages",
            })}
          </TabItem>
        );

        break;

      case "TGradientPanel": //WITH SUB
        let RelativeObertka = undefined,
          EventHandler =
            json.EventHandler === "" && json.EventHandler !== undefined
              ? true
              : false,
          GradientPanel;
        position = "absolute";
        BGColor = BackColor(
          GetParams(json, "Back-color"),
          json.Type,
          json.ClientColor,
          json.ParentColor
        );
        let BorderStyle = json.BorderStyle,
          childsContent;
        BorderStyle = BorderStyle === "линия" ? true : false;
        // ConvertBorder(BorderRadius, GetParams(json,"BevelWidth"))
        let Radius = json.Radius;
        let FrameEdges = CheckFrameEdges(json.FrameEdges, Radius);
        Text = GetParams(json, "Text");
        let BevelWidth = GetParams(json, "BevelWidth");
        let Scrolling =
          json.Scrolling === "1" ? (isThereChild(json) ? true : false) : false;
        BevelWidth = BevelWidth === undefined ? 0 : Number(BevelWidth);
        BevelWidth = Number(Radius) > 0 ? BevelWidth : 0;
        Height = `${Height}px`;
        Width = `${Width}px`;
        if (Anchors) w = Anchors.includes("лево,право");
        if (json.Align === "целиком") {
          Width = "100%";
          Height = "95%";
        }
        if (json.Align === "верх") {
          Top = 0;
          Left = 0;
          position = "relative";
        }
        if (jsonData.keyName === "ПанельАО") {
        }

        style = {
          position: position,
          left: `${Left}px`,
          top: `${Top}px`,
          height: Height,
          width: Width,
          overflowX: "hidden",
          display: Visability === "hidden" ? "none" : "",
          backgroundColor: BGColor,
          borderRadius: `${Radius}px`,
          borderColor: "#cbcbca",
          borderStyle: "solid",
          borderWidth: `${BevelWidth}px`,
          fontFamily: json["Font-name"],
          margin: `${BorderWidth}px`,
          fontSize: `${json["Font-size"]}px`,
          boxShadow:
            json.Shadow !== undefined
              ? "0px 1px 2px 0px rgba(0, 0, 0, 0.5)"
              : "0px",
        };

        style = Scrolling
          ? Object.assign(style, { overflowY: "auto" })
          : Object.assign(style, { overflowY: "hidden" });
        style = CheckAlignInChild(json)
          ? Object.assign(style, {
              display: "flex",
              flexFlow: "column wrap",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            })
          : Object.assign(style, { position: position });

        if (Scrolling) {
          childsContent = (
            <Scrollbars autoHide>
              {SubDataProcessing(json, {
                Path: jsonData.Path,
                flexBool: CheckAlignInChild(json),
                fontSize: Number(json["Font-size"]),
              })}
              {json.ShowCaption === "0" ? (
                <></>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    WebkitUserSelect: "none",
                    justifyContent: ConvertAlignment(json.Alignment),
                    MozUserSelect: "none",
                    width: "inherit",
                    height: "inherit",
                  }}
                >
                  {TextFromServerToBrowser(json, jsonData.keyName)}
                </div>
              )}
            </Scrollbars>
          );
        } else {
          childsContent = (
            <>
              {SubDataProcessing(json, {
                Path: jsonData.Path,
                flexBool: CheckAlignInChild(json),
                fontSize: Number(json["Font-size"]),
              })}
              {json.ShowCaption === "0" ? (
                <></>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: ConvertAlignment(json.Alignment),
                    WebkitUserSelect: "none",
                    MozUserSelect: "none",
                    width: "inherit",
                    height: "inherit",
                  }}
                >
                  {TextFromServerToBrowser(json, jsonData.keyName)}
                </div>
              )}
            </>
          );
        }

        if (FrameEdges !== undefined) {
          delete style.borderRadius;
          style = Object.assign(style, FrameEdges);
        }

        style = AnchorsUse(
          Anchors,
          style,
          Left,
          Top,
          jsonData.SubLabel === "TForm" ? false : true,
          Right,
          Bottom
        );

        if (jsonData !== undefined)
          style = jsonData.flexBool
            ? Object.assign(style, {
                position: "relative",
                minHeight: Height,
                minWidth: `${parseInt(style.minWidth) - BorderWidth}px`,
                width:
                  BorderWidth === undefined
                    ? "100%"
                    : `calc(100% - ${BorderWidth}px)`,
                top: "0px",
              })
            : style;
        if (json.Align === "целиком") {
          Object.assign(style, {
            flex: "0.98",
            minWidth: `${parseInt(style.minWidth) - BorderWidth}px`,
            minHeight:
              Path === undefined
                ? style.display === "flex"
                  ? json.Height + "px"
                  : "25px"
                : json.Height + "px",
          });
        }
        if (
          BorderWidth !== "0" &&
          json.Align === "нет" &&
          !CheckAlignInChild(json) &&
          json.Parent.substring(0, 4) === "Form"
        ) {
          RelativeObertka = {
            position: "relative",
            margin: `${Number(BorderWidth) * 2}px`,
            height:
              json.Shadow !== undefined
                ? `${parseInt(style.height) + 2}px`
                : style.height,
            overflow: "hidden",
            fontFamily: style.FontFamily,
            fontSize: style.fontSize,
          };
          // style = Object.assign(style,{position:"relative", top: 0, margin: `${Number(BorderWidth)*2}px`})
          style = Object.assign(style, {
            position: "absolute",
            top: 0,
            margin: `${0}px`,
          });
        }
        if (
          json.Anchors !== undefined &&
          json.Anchors.includes("право,верх") &&
          !json.Anchors.includes("лево")
        ) {
          delete style.left;
          Object.assign(style, { right: `${Right}px` });
        }
        let radio = "";
        for (const value of Object.values(json)) {
          if (value.Type === "TRadioButton") {
            radio = value.Name;
          }
        }
        if (radio !== "") {
          //RadioButtons
          let counterOfRadio = 0,
            defaultValueOfRadio = Name + ",";
          for (const [key, value] of Object.entries(json)) {
            if (key.substring(0, 11) === "RadioButton") {
              if (value.Checked === "1") {
                defaultValueOfRadio = jsonData.keyName + "," + counterOfRadio;
              }
              counterOfRadio += 1;
            }
          }
          ReturnComponent.push(
            <Grid keyName={jsonData.keyName} style={style}>
              {/* <FormControl>
                <RadioGroup
                  onChange={RadioChange}
                  defaultValue={radio}
                  value={radio}
                >
                  {SubDataProcessing(json, { Path: jsonData.Path })}
                </RadioGroup>
              </FormControl> */}
              <TRadioButton
                jsonData={jsonData}
                RadioChange={RadioChange}
                json={json}
                checked={defaultValueOfRadio}
                TextFromServerToBrowser={TextFromServerToBrowser}
              />
            </Grid>
          );
        } else if (jsonData.SubLabel === "TCategoryPanel") {
          ReturnComponent.push(
            <Grid
              namePopupMenu={json.NamePopupMenu}
              className={EventHandler ? "" : "threed"}
              keyName={jsonData.keyName}
              style={{
                height: Height,
                width: Width,
                left: `${Left}px`,
                top: `${Top}px`,
                position: "relative",
                overflowY: "auto",
                overflow: "hidden",
              }}
              onClick={EventHandler ? undefined : ClickFormElement}
            >
              {SubDataProcessing(json, {
                subElement: "TCategoryPanel",
                Path: jsonData.Path,
              })}
            </Grid>
          );
        } else {
          if (RelativeObertka !== undefined) {
            delete style.minWidth;
            // delete style.minHeight
            GradientPanel = (
              <div style={RelativeObertka}>
                <Grid
                  namePopupMenu={json.NamePopupMenu}
                  keyName={jsonData.keyName}
                  style={style}
                  className={EventHandler ? "" : "threed"}
                  onClick={EventHandler ? undefined : ClickFormElement}
                >
                  {childsContent}
                </Grid>
              </div>
            );
            // }  else if (
            //   json.ClientColor !== undefined &&
            //   json.ClientColor !== ""
            // ) {
            //   Object.assign(style, {borderColor: style.borderColor,borderStyle: "solid",borderWidth: `${BorderWidth}px`,})
            //   // delete style.borderRadius
            //   // delete style.borderColor
            //   // delete style.borderStyle
            //   GradientPanel = (
            //     <div
            //       style={style}
            //     >
            //       <Grid
            //         keyName={jsonData.keyName}
            //         style={{
            //           // position: position,
            //           // height: Height,
            //           // width: Width,
            //           // overflowX: "hidden",
            //           // display: Visability === "hidden" ? "none" : "grid",
            //           backgroundColor: BackColor(json.ClientColor),
            //           // borderRadius: `${Radius}px`,
            //           // borderColor: style.borderColor,
            //           // borderStyle: "solid",
            //           // borderWidth: `${BorderWidth}px`,
            //           // fontFamily: json["Font-name"],
            //           // // margin: `${BorderWidth}px`,
            //           // fontSize: `${json["Font-size"]}px`,
            //         }}
            //         onClick={
            //           json.EventHandler === "" ? undefined : ClickGradintPanel
            //         }
            //       >
            //         {childsContent}
            //       </Grid>
            //     </div>
            //   );
          } else {
            GradientPanel = (
              <Grid
                namePopupMenu={json.NamePopupMenu}
                keyName={jsonData.keyName}
                style={style}
                className={EventHandler ? "" : "threed"}
                onClick={EventHandler ? undefined : ClickFormElement}
              >
                {childsContent}
              </Grid>
            );
          }
          // ReturnComponent.push(<>{GradientPanel}</>);
          ReturnComponent.push(
            <SlideLocal animation={store.getState().theme.animations}>
              {GradientPanel}
            </SlideLocal>
          );
        }

        break;

      case "TBevel": //WITH SUB
        style = {
          position: "absolute",
          left: `${Left}px`,
          top: `${Top}px`,
          width: `${Width}px`,
          height: `${Height}px`,
          overflowY: "auto",
          overflowX: "hidden",
          display: Visability,
          backgroundColor: BGColor,
        };
        const Shape = json.Shape;
        let styleForBorders = {
          height: "inherit",
          borderColor: "#cbcbca",
          borderRadius: "1px",
          borderStyle: "solid",
          borderWidth: "2px",
        };
        Anchors = ShouldUseFullScreen(Anchors);
        Right = Anchors.w ? `${Left}px` : `${0}px`;
        Bottom = Anchors.h ? `${Number(Top) + Number(Height)}px` : `${0}px`;
        Height = `${Height}px`;
        Width = `${Width}px`;
        switch (Shape) {
          case "прямоугольник":
            styleForBorders = Object.assign(styleForBorders, {
              borderWidth: "2px",
            });
            break;
          case "сверху":
            styleForBorders = Object.assign(styleForBorders, {
              borderWidth: "2px 0px 0px 0px",
            });
            break;
          case "слева":
            styleForBorders = Object.assign(styleForBorders, {
              borderWidth: "0px 0px 0px 2px",
            });
            break;
          case "справа":
            styleForBorders = Object.assign(styleForBorders, {
              borderWidth: "0px 2px 0px 0px",
            });
            break;
          case "снизу":
            styleForBorders = Object.assign(styleForBorders, {
              borderWidth: "0px 0px 2px 0px",
            });
            break;
          case "рамка":
            styleForBorders = Object.assign(styleForBorders, {
              borderWidth: "3px",
            });
            break;
        }

        if (jsonData.ColsFromParent) {
          const jm = CalculateMargin(
            jsonData.ColsFromParent,
            json.Target,
            jsonData.widthFromParent,
            Number(Left)
          );
          style = Object.assign(style, { left: jm.ml, right: jm.mr });
        }
        if (Anchors.w && jsonData.SubLabel !== "TMarkingPanel") {
          delete style.width;
          style = Object.assign(style, { right: Right, marginRight: "2px" });
        }
        ReturnComponent.push(
          <Grid
            namePopupMenu={json.NamePopupMenu}
            keyName={jsonData.keyName}
            style={style}
          >
            <div style={styleForBorders}>{/* {SubDataProcessing(json)} */}</div>
          </Grid>
        );
        break;

      case "TRadioGroup":
        let PickList, Columns;
        PickList = GetParams(json, "PickList").split("\r\n");
        Text = GetParams(json, "PickList").split("\r\n");
        Columns = GetParams(json, "Columns");
        for (let key of PickList) {
          if (key !== "") {
            returnSub.push(
              <FormControlLabel value={key} control={<Radio />} label={key} />
            );
          }
        }
        ReturnComponent.push(
          <FormControl
            style={{
              position: "absolute",
              left: `${Left}px`,
              top: `${Top}px`,
              width: `${Width}px`,
              height: `${Height}px`,
            }}
          >
            <FormLabel>{Text[0]}</FormLabel>
            <RadioGroup
              row={Columns === "1" ? false : true}
              name="controlled-radio-buttons-group"

              // onChange={handleChange}
            >
              {returnSub}
            </RadioGroup>
          </FormControl>
        );

        break;

      case "TRadioButton":
        Text = GetParams(json, "Text");
        // let RadioValue =
        //   json.Parent +
        //   "," +
        //   `${Number(jsonData.keyName.substring(11, 12)) - 1}`;
        ReturnComponent.push(
          <FormControlLabel
            value={json.Name}
            control={
              <Radio
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 18,
                    color: "#4d4d4d",
                    "&.Mui-checked": {
                      color: "#4d4d4d",
                    },
                  },
                }}
              />
            }
            label={TextFromServerToBrowser(json)}
            style={{
              position: "absolute",
              left: `${Left}px`,
              top: `${Top}px`,
              width: `${Width}px`,
              height: `${Height}px`,
            }}
          />
        );
        break;

      case "TListBox":
        break;

      case "TMarkingPanel":
        style = {
          position: "absolute",
          left: `${Left}px`,
          right: `${Left}px`,
          top: `${Top}px`,
          height: `${Height}px`,
          overflowY: "auto",
          overflowX: "hidden",
          visibility: Visability,
          backgroundColor: BGColor,
        };

        ReturnComponent.push(
          <Grid keyName={jsonData.keyName} style={style}>
            {SubDataProcessing(json, {
              subElement: "TMarkingPanel",
              Cols: json.Cols,
              Path: jsonData.Path,
            })}
          </Grid>
        );
        break;

      case "TShape":
        style = {
          position: "absolute",
          left: `${Left}px`,
          top: `${Top}px`,
          width: `${Width}px`,
          height: `${Height}px`,
          overflowY: "auto",
          overflowX: "hidden",
          display: Visability,
          backgroundColor: BGColor,
          zIndex: 0,
        };
        style = AnchorsUse(Anchors, style, Left, Top);
        const BColor = BackColor(json.PenColor, json.Type, json.ClientColor);
        let styleForShape = { height: "inherit", backgroundColor: BColor };
        Height = `${Height}px`;
        Width = `${Width}px`;
        if (
          json.BrushColor.split(":").length === 3 &&
          json.PenColor === undefined
        ) {
          delete style.backgroundColor;
          style = {
            ...style,
            borderColor: BackColor(json.BrushColor),
            borderStyle: "solid",
            borderWidth: "1px",
            pointerEvents: "none",
            overflow: "hidden",
          };
        }
        ReturnComponent.push(
          <Grid
            namePopupMenu={json.NamePopupMenu}
            keyName={jsonData.keyName}
            style={style}
          >
            <div style={styleForShape}>{/* {SubDataProcessing(json)} */}</div>
          </Grid>
        );
        break;

      case "TMemo":
        style = {
          position: "absolute",
          left: `${Left}px`,
          top: `${Top}px`,
          width: `${Width}px`,
          height: `${Height}px`,
          overflowY: "auto",
          overflowX: "hidden",
          display: Visability,
          backgroundColor: BGColor,
          // outline: "none",
          // resize: "none",
          border: "1px solid #cccccc",
        };
        style = AnchorsUse(Anchors, style, Left, Top);
        // const BColor = BackColor(json.PenColor, json.Type, json.ClientColor);
        // let styleForShape = { height: "inherit", backgroundColor: BColor };
        Height = `${Height}px`;
        Width = `${Width}px`;
        ReturnComponent.push(
          // <Grid keyName={jsonData.keyName} style={style}>
          <div style={style}>
            <IMemo
              from="form"
              Text={json.Text}
              keyname={jsonData.keyName}
              showHint={ShowHint}
              hint={Hint}
              style={{
                outline: "none",
                resize: "none",
                border: "none",
                width: "100%",
                height: "98.6%",
                overflowY: "auto",
                overflowX: "hidden",
              }}
              onBlur={(event) => {
                if (event.target.value !== json.Text) {
                  let reqText = event.currentTarget.value;

                  // временный костыль
                  if (reqText.length > 1024) {
                    reqText = reqText.substring(0, 1025);
                  }

                  ClickFormElement(
                    {
                      currentTarget: {
                        getAttribute: function () {
                          return jsonData.keyName;
                        },
                        stopPropagation: function () {
                          return;
                        },
                        dataset: { value: reqText },
                      },
                    },
                    jsonData.keyName
                  );
                }
              }}
            />
          </div>
        );
        break;
    }
    return ReturnComponent;
  }

  function FormDataProcessing(json) {
    if (!isEmptyObject(json) && dataForms !== undefined && json !== undefined) {
      let val,
        returnAll = [];
      json = json.Form;
      for (const [key, value] of Object.entries(json)) {
        val = value;
        if (val.Type !== undefined) {
          try {
            returnAll.push(
              CheckAndReturnComponent(value, {
                SubLabel: "TForm",
                keyName: key,
                widthFromParent: json.Width,
                heightFromParent: json.Height,
              })
            );
          } catch (err) {}
        }
      }

      return (
        <div
          className={"TestForms" + props.id}
          style={{ position: "relative", height: "100%", width: "100%" }}
        >
          {returnAll}
        </div>
      );
    }
  }

  function SubDataProcessing(json, jsonData) {
    if (dataForms !== undefined) {
      let val,
        returnAll = [];
      const orderedArr = [];
      //json = json.Form;
      for (const [key, value] of Object.entries(json)) {
        val = value;
        if (val.Type !== undefined) {
          if (
            val.TabOrder !== undefined &&
            orderedArr[val.TabOrder] === undefined
          ) {
            orderedArr[val.TabOrder] = CheckAndReturnComponent(value, {
              SubLabel: jsonData.subElement,
              keyName: key,
              RCDATAFormParent: jsonData.RCDATA,
              widthFromParent: json.Width,
              heightFromParent: json.Height,
              ColsFromParent: jsonData.Cols,
              flexBool: jsonData.flexBool,
              Path: jsonData.Path,
              fontSize: jsonData.fontSize,
              ChevronAlignment: jsonData.ChevronAlignment,
            });

            continue;
          }

          returnAll.push(
            CheckAndReturnComponent(value, {
              SubLabel: jsonData.subElement,
              keyName: key,
              RCDATAFormParent: jsonData.RCDATA,
              widthFromParent: json.Width,
              heightFromParent: json.Height,
              ColsFromParent: jsonData.Cols,
              flexBool: jsonData.flexBool,
              Path: jsonData.Path,
              fontSize: jsonData.fontSize,
              ChevronAlignment: jsonData.ChevronAlignment,
            })
          );
        }
      }
      return [...orderedArr, ...returnAll];
    }
  }

  if (props.jsonFormData !== undefined && !isEmptyObject(props.jsonFormData)) {
    let val,
      returnAll = [];
    let json = props.jsonFormData.Form;
    for (const [key, value] of Object.entries(json)) {
      val = value;
      if (val.Type !== undefined) {
        try {
          returnAll.push(
            CheckAndReturnComponent(value, "TForm", {
              SubLabel: "TForm",
              keyName: key,
            })
          );
        } catch (err) {}
      }
    }

    return (
      <div
        className={"TestForms" + props.id + "Sub"}
        style={{
          ...props.style,
          position: "relative",
          height: "100%",
          width: "100%",
        }}
      >
        {props.SubDataProcessing == true
          ? SubDataProcessing(dataForms, dataForms)
          : FormDataProcessing(dataForms)}
      </div>
    );
  } else
    return (
      <div key={props.id}>
        {load ? (
          <></>
        ) : props.hideSectionTools ? (
          <></>
        ) : (
          <SectionToolsJS
            ID={props.id}
            buildForms={FormDataProcessing}
            setHeight={setHeight}
          />
        )}
        {/* <Fade in={!transition}> */}
        <Grid
          key={props.id}
          id={"mainForms" + props.id}
          style={{
            ...props.style,
            position: "relative",
            height: "100%",
            width: "100%",
            backgroundColor: "s",
          }}
        >
          <ContextMenu
            for={"popupmenu"}
            getContextMenuFunction={(func) => {
              ContextMenuFunc.current = func;
            }}
            onRightClick={() => {
              return popupDataRef.current;
            }}
            onMenuItemClick={ClickFormElement}
          >
            {load ? <LoadingMask /> : FormDataProcessing(dataForms)}
          </ContextMenu>
        </Grid>
        {/* </Fade> */}

        <Grid id="RenderFormsModal"></Grid>
        <Grid id="footerProgressNew"></Grid>
      </div>
    );
}
