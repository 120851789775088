import { Button, Grid } from "@mui/material";
import React from "react";
import { GridLoader } from "../../../rcsgrid/createGridTools";
import { tokenProcessingTest } from "../../../../TokenProcessing/TokenProcessing";
import { AxiosRequest } from "../../../../Url";
import {
  getCheckBoxes,
  getColumnAccount,
  getColumnBank,
  getColumnOrg,
} from "./HandleGroupingData";
import LinearProgress from "@mui/material/LinearProgress";
import { isEmptyObject } from "../../../Tools/Tools";
import { createGrid, createRecordSource } from "../../../rcsgrid/rcsgrid";

export default function HandleGrouping(props) {
  //хуки состояния чекбоксов
  const [orgGroup, setOrgGroup] = React.useState(false);
  const [orgName, setOrgName] = React.useState(false);
  const [orgINN, setOrgINN] = React.useState(false);
  const [orgKPP, setOrgKPP] = React.useState(false);
  const [bankName, setBankName] = React.useState(false);
  const [bankBIK, setBankBIK] = React.useState(true);
  const [accountGroup, setAccountGroup] = React.useState(false);
  const [oneOrgGroup, setOneOrgGroup] = React.useState(false);
  const [number20, setNumber20] = React.useState(false);
  //хуки для progressBar
  const [maxStep, setMaxStep] = React.useState(1000);
  const [step, setStep] = React.useState(1);
  const [isRun, setIsRun] = React.useState(false);
  //хук для блокировки кнопки Объединить
  const [joinSwitch, setJoinSwitch] = React.useState(true);
  //хук для отслеживания requestID для запроса resumeRequest
  const [requestID, setRequestId] = React.useState("");
  //ссылка на компонент для отрисовки таблицы
  const gridPanel = React.useRef();

  //хук состояния таблицы
  const [mainGrid, setMainGrid] = React.useState(undefined);
  const [data, setData] = React.useState();
  const [columns, setColumns] = React.useState();

  //карта с хуками для получения нужных чекбоксов (в handleGroupingData)
  function hookCheckBox() {
    return {
      Org: {
        Group: orgGroup,
        Name: orgName,
        INN: orgINN,
        KPP: orgKPP,
        setGroup: setOrgGroup,
        setName: setOrgName,
        setINN: setOrgINN,
        setKPP: setOrgKPP,
      },
      Bank: {
        Name: bankName,
        BIK: bankBIK,
        setName: setBankName,
        setBIK: setBankBIK,
      },
      Account: {
        Group: accountGroup,
        OneGroup: oneOrgGroup,
        Number: number20,
        setGroup: setAccountGroup,
        setOneGroup: setOneOrgGroup,
        setNumber: setNumber20,
      },
    };
  }
  //получение чекбоксов из handleGroupingData
  const content = getCheckBoxes(props, hookCheckBox());

  /////////////////////////////////////////////////////////////////////////////////
  /////////////////////////отрисовка Таблицы///////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////

  //функция для получения нужных полей
  function getColumn() {
    switch (props.module) {
      case "Объединить организации":
        setData({ ObjType: "2310" });
        return getColumnOrg();
      case "Объединить банки":
        setData({ ObjType: "2330" });
        return getColumnBank();
      case "Объединить Счета":
        setData({ ObjType: "2320" });
        return getColumnAccount();
    }
  }

  //функция для создания Таблицы
  function setMainGridLoader(data) {
    setMainGrid(
      GridLoader(
        gridPanel.current,
        {
          Module: "organizations_group",
        },
        { ...data, Columns: columns }
      )
    );
  }

  async function firstMainLoad() {
    let grid;
    let source;
    if (gridPanel.current !== null) {
      gridPanel.current.innerHTML = "";
      gridPanel.current.grid = new createGrid(gridPanel.current);
      grid = gridPanel.current.grid;
      source = new createRecordSource();
      grid.setColumns(columns);
      await grid.setSource(source);
      setMainGrid({ grid, source });
    }
  }
  //функция для обновления Таблицы
  function updateGridSize() {
    mainGrid.grid.updateGridSize();
  }
  React.useEffect(() => {
    setColumns(getColumn());
  }, []);
  //первичная отрисовка таблицы
  React.useEffect(() => {
    if (!mainGrid && columns) {
      firstMainLoad();
    } else if (columns) {
      setMainGridLoader(data);
    }
  }, [columns]);
  //обновление таблицы
  React.useEffect(() => {
    if (mainGrid) updateGridSize();
  }, [mainGrid]);

  /////////////////////////////////////////////////////////////////////////////////
  /////////////////////////Запросы/////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////

  //Выбор запроса для поиска одинаковых записей
  function findDuplicatesReq() {
    const params = new Map();
    params.set("prefix", "organizations").set("comand", "FindDuplicates");
    switch (props.module) {
      case "Объединить организации":
        params
          .set("ObjType", "2310")
          .set("INN", orgINN ? "1" : "0")
          .set("KPP", orgKPP ? "1" : "0")
          .set("Name", orgName ? "1" : "0");
        if (orgGroup) {
          params.set("Group", "2310").set("SectionID", "100");
        }
        params.set("WSM", "1");
        return AxiosRequest(true, params);
      case "Объединить банки":
        params
          .set("ObjType", "2330")
          .set("bik", "1")
          .set("name", bankName ? "1" : "0")
          .set("WSM", "1");
        return AxiosRequest(true, params);
      case "Объединить Счета":
        params
          .set("ObjType", "2320")
          .set("OnlyOrg", oneOrgGroup ? "1" : "0")
          .set("Only20", number20 ? "1" : "0")
          .set("WSM", "1");
        if (accountGroup) {
          params.set("Group", "2310").set("SectionID", "100");
        }
        return AxiosRequest(true, params);
    }
  }
  //запрос на объединение одинаковых записей
  function joinDuplicates() {
    const params = new Map();
    params
      .set("prefix", "organizations")
      .set("comand", "JoinDuplicates")
      .set("ObjType", data.ObjType)
      .set("WSM", "1");
    return AxiosRequest(true, params);
  }

  /////////////////////////////////////////////////////////////////////////////////
  /////////////////////////Кнопки и ProgressBar////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////

  //кнопка Поиска одинаковых записей
  async function onClickSearch() {
    const json = await findDuplicatesReq();
    tokenProcessingTest(json);
    setMainGridLoader(data);
    setJoinSwitch(false);
    setIsRun(false);
    setStep(1);
    updateGridSize();
  }
  //проверка json  на наличие необходимых параметров
  function checkJoinJson(json) {
    if (isEmptyObject(json)) return false;
    if (!json.Params) return false;
    if (!json.Params.MaxNumber) return false;
    if (!json.Params.Value) return false;
    return true;
  }
  //кнопка Объединения одинаковых записей()
  async function onClickJoin() {
    const json = await joinDuplicates();
    if (json && json.Token) {
      tokenProcessingTest(json);
      mainGrid.grid.refreshSource();
    }
    if (checkJoinJson(json)) {
      setMaxStep(json.Params.MaxNumber);
      setStep(json.Params.Value);
      setIsRun(!isRun);
      setJoinSwitch(!joinSwitch);
      setRequestId(json.Params.RequestID);
      // tokenProcessingTest(json, setStep);
      updateGridSize();
    }
  }
  //функция для последовательного объединения записей
  async function resumeRequest(RequestID) {
    const params = new Map();
    params.set("prefix", "project");
    params.set("comand", "ResumeRequest");
    params.set("RequestID", RequestID);
    params.set("WSM", "1");

    await AxiosRequest(false, params)
      .then((json) => {
        if (checkJoinJson(json)) {
          setStep(json.Params.Value);
        }
      })
      .catch((err) => {
       
      });
  }
  //форматирование для ProgressBar(приводит значение к 100 / Максимальное количество шагов(maxStep) * Текущий шаг(step))
  const normalize = (value, max) => {
    return ((value - 1) * 100) / (max - 1);
  };
  //прогресс ProgressBar
  React.useEffect(() => {
    setTimeout(() => {
      if (isRun) {
        if (step < maxStep) {
          resumeRequest(requestID);
        } else {
          mainGrid.grid.refreshSource();
          setIsRun(!isRun);
          setStep(1);
        }
        //Функция для проверки работы ProgressBar
        // const timer = setInterval(() => {
        //   setStep((step) => {
        //     if (step < maxStep) {
        //       return step + 1;
        //     } else {
        //       setIsRun(!isRun);
        //       setStep(1);
        //       return maxStep;
        //     }
        //   });
        // }, 1);
        // return () => {
        //   clearInterval(timer);
        // };
      }
    }, 1);
  }, [isRun, step]);

  return (
    <>
      {/* {Основной контейнер} */}
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ height: "100%", width: "100%" }}
      >
        {/* {Контейнер для Таблицы} */}
        <Grid
          id={"rcgrid_panel"}
          ref={gridPanel}
          item
          style={{
            height: "calc(100% - 40px - 58px - 21px)",
            width: "100%",
            border: "1px solid #C8B58F",
            position: "relative",
          }}
        ></Grid>
        {/* {Контейнер для Чекбоксов} */}
        <Grid
          item
          style={{
            height: "58px",
            width: "100%",
            border: "1px solid #C8B58F",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            {content}
          </Grid>
        </Grid>
        {/* {Контейнер для ProgressBar и Кнопок} */}
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          {/* {ProgressBar} */}
          <Grid
            sx={{ m: 1 }}
            style={{
              border: "3px solid #eeeeee",
              height: "20px",
              width: "calc(100% - 266px)",
              borderRadius: "3px",
              display: isRun ? "" : "none",
            }}
          >
            <LinearProgress
              color="success"
              style={{ height: "100%" }}
              variant="determinate"
              value={normalize(step, maxStep)}
            />
          </Grid>
          {/* {Кнопки} */}
          <Grid item style={{ height: "40px", width: "250px" }}>
            <Button
              size="small"
              variant="outlined"
              style={{
                textTransform: "none",
                marginTop: "5px",
                marginRight: "5px",
              }}
              value="-1"
              onClick={onClickSearch}
            >
              Найти
            </Button>
            <Button
              size="small"
              variant="outlined"
              style={{
                textTransform: "none",
                marginTop: "5px",
                marginRight: "5px",
              }}
              value="-1"
              onClick={onClickJoin}
              disabled={joinSwitch}
            >
              Объединить
            </Button>
            <Button
              size="small"
              variant="outlined"
              style={{
                textTransform: "none",
                marginTop: "5px",
                marginRight: "5px",
              }}
              value="-1"
              className="button_Modal_Close"
            >
              Закрыть
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
