import axios, { AxiosRequestConfig } from "axios";
import React from "react";
import setHost from "./SetHost.js";
import { isEmptyObject } from "./MainPage/Tools/Tools";
import {
  RenderMessageBox,
  SetLoadingCursor,
  SetLoadingCursorAxios,
} from "./TokenProcessing/TokenProcessing.js";
import queuedAPI from "./api.js";
import { getUrlSearh } from "./MainPage/Tools/urlParams.js";
// import { useNavigate } from "react-router-dom";
// import { store } from "../store/index.js";

var enter = false,
  userOptions = GetOptionsForUser(),
  PingMins: number =
    userOptions && userOptions.PingMins ? userOptions?.PingMins : 300000,
  intervalId: any,
  returnNotification: any = undefined,
  IPs: any = userOptions?.IP,
  setHeadUpdate: any = null;
// navigate = useNavigate();

declare global {
  interface Window {
    BASE_SUB_URL?: any;
    MultiSection?: any;
    hideEnter?: any;
    sub_url?: string | undefined;
    do_esia_logout?: any;
    ping?: any;
    SignCryptoButton?: string | undefined;
    LkButton?: string | undefined;
    StimateVersion?: string | undefined;
  }
}

declare global {
  // LK config
  interface Window {
    showMobileQR?:any,
    hideEsiaButton?:any,
    signIn_url?: any;
    BASE_LK?: any;
    BASE_PASS_RECOVERY: any;
    signUP_url?: any;
    BASE_CAPTCHA?: any;
    BASE_SNILS_OR_FIO?: any;
    BASE_UMI?: any;
    ChangePassword_url?: any;
    RestorePassword_url?: any;
    BASE_width_logo?: any;
    BASE_LINK_TEXT?: any;
    do_esia_auth?: any;
    titleText?: any;
    hideLoginAndPassword?: any;
    agreeToProcessPersonalDataLink?: any;
    pluginLinks: any;
    webkcrypt:any;
  }
}

export function removeParam(key: any, sourceURL: string) {
  var splitUrl = sourceURL.split("?"),
    rtn = splitUrl[0],
    param,
    params_arr = [],
    queryString = sourceURL.indexOf("?") !== -1 ? splitUrl[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    rtn = rtn + "?" + params_arr.join("&");
  }
  return rtn;
}

export function getLastLogin() {
  const SLL = sessionStorage.getItem("LastLogin"); //SessionStorage
  const CLL = get_cookie("LastLogin");
  return SLL === null || SLL === undefined || SLL === "" ? CLL : SLL;
}

export function getURLparam(key: any) {
  let search: any = getUrlSearh();
  const urlParams = new URLSearchParams(search);
  const param: any = urlParams.get(key);
  return param;
}

export function hasURLparam(key: any) {
  let search: any = getUrlSearh();
  const urlParams = new URLSearchParams(search);
  return urlParams.has(key);
}

export function UpdateOptionsForUser(data: any) {
  const currentuser = getLastLogin().split(",");
  let jsoncrrsec: any = get_cookie("CurrentSecJSON"),
    drx = currentuser[0],
    user = currentuser[1],
    workplace = currentuser[2];
  jsoncrrsec = jsoncrrsec === "" ? {} : JSON.parse(jsoncrrsec);
  let newSelectedJson = {
    ...jsoncrrsec,
    [drx]: {
      ...jsoncrrsec?.[drx],
      [user]: {
        ...jsoncrrsec?.[drx]?.[user],
        [workplace]: {
          ...jsoncrrsec?.[drx]?.[user]?.[workplace],
        },
      },
    },
  };
  for (const [key, value] of Object.entries(data)) {
    Object.assign(newSelectedJson?.[drx]?.[user]?.[workplace], {
      [key]: value,
    });
  }
  CreateCokies("CurrentSecJSON", JSON.stringify(newSelectedJson));
}

export function GetOptionsForUser() {
  //
  const currentuser = getLastLogin().split(",");
  let jsoncrrsec: any = get_cookie("CurrentSecJSON"),
    drx = currentuser[0],
    user = currentuser[1],
    workplace = currentuser[2];

  jsoncrrsec = jsoncrrsec === "" ? {} : JSON.parse(jsoncrrsec);
  return jsoncrrsec?.[drx]?.[user]?.[workplace];
}

export function CreateCokies(name: string, value: any) {
  document.cookie = name + "=" + value;
  localStorage.setItem(name, value);
}
export function get_cookie(cookie_name: any): any {
  var results = localStorage.getItem(cookie_name);
  // return localStorage.getItem("cookie_name");
  if (results) return results;
  else return "";
}
function getRandomArbitrary(min: number, max: number) {
  var res = Math.floor(Math.random() * (max - min) + min);
  return res;
}

const getInterval = () => {
  return setInterval(
    function () {
      PingServer();
    },
    PingMins
    // 6000
  );
};

export const PingServer = (setNotifications?: any, HeadUpdate?: any) => {
  if (window.ping !== false) {
    if (returnNotification === undefined && setNotifications !== undefined) {
      returnNotification = setNotifications;
    }
    const loadMasks: any = document.querySelectorAll("[loadmask]");

    if (loadMasks.length > 0) {
      for (const val of loadMasks) {
        val.parentElement.remove();
      }
    }

    if (HeadUpdate) setHeadUpdate = HeadUpdate;
    let params = new Map();
    params.set("prefix", "project").set("comand", "ping").set("smart", "1");
    return AxiosRequest(true, params).then((json) => {
      if (!isEmptyObject(json) && returnNotification) returnNotification(json);
      return json;
    });
  }
};

function CheckPingData(command: string) {
  if (command === "GetSectionList") {
    enter = true;
  } else if (command === "leave") {
    enter = false;
  }
}

export function checkGenLicGuid(LicGuid: any, createNew?: boolean) {
  //Проверка|Генерация LicGuid
  let LicGUID = LicGuid;
  if (LicGUID === "" || LicGUID === null || createNew) {
    var s = "0123456789ABCDEFGHIKLMNOPQRSTVXYZ";
    for (var n = 0; n <= 31; n++) {
      if (n === 0) {
        LicGUID = s[getRandomArbitrary(1, 31)];
      } else {
        LicGUID = LicGUID + s[getRandomArbitrary(1, 31)];
      }
    }
    sessionStorage.setItem("LicG", LicGUID);
  }
  return LicGUID;
}

function getUrlForRequest(params: any, LicGUID: string) {
  //Создание ссылки запроса
  let attachment: any = new Map(params);
  attachment.delete("prefix");
  attachment.delete("comand");

  let result = "";

  const keys = Array.from(attachment.keys()); //разбор карты вложений на ключи
  for (let key of keys) {
    if (attachment.get(key) !== undefined) {
      result += `&${key}=${`${attachment.get(key)}`.replaceAll("/", "%2f")}`;
    }
  }
  return `${setHost()}/web~${
    params.get("prefix") === undefined ? "project" : params.get("prefix")
  }/${params.get("comand")}?LicGUID=${LicGUID}${result}`;
}

export function XMLrequest(params: any, postData?: any) {
  var LicGUID: any,
    request = new XMLHttpRequest(),
    res = "",
    method;

  if (params.has("LicGUID")) {
    LicGUID = params.get("LicGUID");
    params.delete("LicGUID");
  } else {
    LicGUID = sessionStorage.getItem("LicG");
    LicGUID = checkGenLicGuid(LicGUID);
  }

  const url = getUrlForRequest(params, LicGUID);
  postData === undefined ? (method = "GET") : (method = "POST");
  request.open(method, url, false);
  // request.setRequestHeader("Access-Control-Allow-Origin", "*")
  // request.setRequestHeader("Access-Control-Allow-Methods", "GET,PUT,POST,DELETE,PATCH,OPTIONS")
  request.onload = function () {
    res = request.responseText;
  };
  if (postData) {
    let json = JSON.stringify(postData);
    request.send(json);
  } else request.send();
  CheckPingData(params.get("comand"));
  intervalId && clearInterval(intervalId);
  if (enter === true) {
    intervalId = getInterval();
  }
  try {
    let parsedJSON = JSON.parse(res);
    if (parsedJSON.error && params.get("comand") !== "enter") {
      const Message = `Ошибка выполнения операции на сервере: ${parsedJSON.error.content}`;
      // ReactDOM.render(<ModalContainer dlgType={"Ошибка"} content={Message} />, document.getElementById('footerProgress'));
      RenderMessageBox(1, 1, Message);
    }
    FormInHeader(parsedJSON);
    parsedJSON = CheckMessages(parsedJSON);
    return parsedJSON;
  } catch (err) {
    console.log(err);
  }
}
export default function URL(params: any, postData?: any) {
  var LicGUID: any;
  LicGUID = sessionStorage.getItem("LicG");
  LicGUID = checkGenLicGuid(LicGUID);
  return getUrlForRequest(params, LicGUID);
}

function FormInHeader(json: any) {
  if (json.Head === "1") {
    getHeadForm("GetSectionHead");
  } else if (
    json.Token &&
    (json.Token === "CommitFormOnHead" || json.Token === "ClearFormOnHead")
  ) {
    switch (json.Token) {
      case "CommitFormOnHead":
        getHeadForm("GetUpdateHeadForm");
        break;
      case "ClearFormOnHead":
        getHeadForm("GetSectionHead");
        break;
    }
  }
}

async function getHeadForm(command: string) {
  let params = new Map();
  params.set("prefix", "forms").set("comand", command).set("height", "48px");
  // const headData:any =await AxiosRequest(true,params);
  const headData: any = await AxiosRequest(true, params);
  setHeadUpdate(headData.Head ? headData.Head : headData);
}

function CheckIsEmptyAnswer(json: any) {
  if (isEmptyObject(json) || json === undefined || json === null) {
    CreateCokies("LicG", "");
    if (GetOptionsForUser()?.from && window.hideEnter === true) {
      window.location.href = GetOptionsForUser()?.from.replaceAll("@", "/");
    } else {
      window.location.href =
        window.location.hostname + window.location.pathname;
    }
  }
}

function CheckMessages(json: any) {
  if (json.Message?.content) {
    RenderMessageBox(2, 1, json.Message.content);
    delete json.Message;
  }
  if (json.error && json.error.NeedNewPW === undefined) {
    RenderMessageBox(1, 1, json.error.content);
    delete json.error;
  }
  return json;
}

export async function AxiosRequest(
  loadMask: boolean,
  params: any,
  postData?: object | AxiosRequestConfig
) {
  let LicGUID: any, res, mask: HTMLDivElement | undefined, url: any;
  // let res = Object;
  LicGUID = sessionStorage.getItem("LicG");
  if (params.has("LicGUID")) {
    LicGUID = params.get("LicGUID");
  } else {
    LicGUID = checkGenLicGuid(LicGUID);
  }
  url = getUrlForRequest(params, LicGUID);
  // const login = localStorage.getItem("login")
  // if(login)
  CheckPingData(params.get("comand"));
  intervalId && clearInterval(intervalId);
  if (enter === true) {
    intervalId = getInterval();
  }
  if (loadMask) mask = SetLoadingCursorAxios(loadMask);
  if (params.get("IP")) {
    IPs = params.get("IP");
  }
  try {
    if (!postData) {
      return queuedAPI.get(url).then((response) => {
        if (params.get("comand") === "SetClientInfo" && response.PingMins) {
          PingMins = Number(response.PingMins) * 60 * 1000;
          UpdateOptionsForUser({ PingMins: PingMins });
        }
        res = response;
        res = CheckMessages(res);
        // removeLoadingCursor(loadMask);
        // SetLoadingCursor(loadMask);
        mask?.remove();
        FormInHeader(res);
        return res;
      });
    } else {
      return queuedAPI.post(url, postData).then((response) => {
        res = response;
        res = CheckMessages(res);
        // removeLoadingCursor(loadMask);
        // SetLoadingCursor(loadMask);
        mask?.remove();
        FormInHeader(res);
        return res;
      });
    }
  } catch {}
  //(res)
  return res;

  queuedAPI.get(url).then((response) => {});
}

function removeLoadingCursor(loadMask: any) {
  SetLoadingCursor(loadMask, undefined, true);
}

// export async function AxiosRequest1(
//   loadMask: boolean,
//   params: any,
//   postData?: object | AxiosRequestConfig
// ) {
//   let LicGUID: any, res;
//   // let res = Object;
//   LicGUID = sessionStorage.getItem("LicG");
//   LicGUID = checkGenLicGuid(LicGUID);

//   const url = getUrlForRequest(params, LicGUID);

//   CheckPingData(params.get("comand"));
//   intervalId && clearInterval(intervalId);
//   if (enter === true) {
//     intervalId = getInterval();
//   }
//   if (params.get("IP")) {
//     IPs = params.get("IP");
//   }
//   SetLoadingCursor(loadMask);
//   try {
//     if (!postData) {
//       await axios.get(url).then((response) => {
//         if (
//           params.get("comand") === "SetClientInfo" &&
//           response.data.PingMins
//         ) {
//           PingMins = Number(response.data.PingMins) * 60 * 1000;
//           UpdateOptionsForUser({ PingMins: PingMins });
//         }
//         res = response.data;
//         res = CheckMessages(res);
//         SetLoadingCursor(loadMask);
//         FormInHeader(res);
//       });
//     } else {
//       await axios.post(url, JSON.stringify(postData)).then((response) => {
//         res = response.data;
//         res = CheckMessages(res);
//         SetLoadingCursor(loadMask);
//         FormInHeader(res);
//       });
//     }
//   } catch {}
//   return res;
// }

export function ImgURL(
  attachment?: any,
  style?: React.CSSProperties,
  path?: string
) {
  let origin = `${setHost()}/web~${attachment}`;
  if (IPs !== undefined) {
    origin += "?ip=" + IPs;
  }
  return <img key={path} alt="" style={style} src={origin} />;
}

export function ImgURLSRC(attachment?: any) {
  if(attachment){
    let origin = `${setHost()}/web~${attachment}`;
    if (IPs !== undefined) {
      origin += "?ip=" + IPs;
    }
    return origin;
  }
}

export function ImgBASE64(attachment?: any, path?: string) {
  return <img key={path} alt="" src={`data:image/png;base64,${attachment}`} />;
}
