import { Box, Button, Grid, TextField } from "@mui/material";
import React from "react";
import { AxiosRequest } from "../../../Url";
import { closeModal } from "../../Tools/modalManager";
import TestTree from "../../Windows/ViewData/Tree/testTree";

function getCreateObject() {
  const requests = {
    default: (data) => {
      const params = new Map();
      params
        .set("prefix", data.Module)
        .set("comand", "CreateObject")
        .set("ObjType", data.ObjType)
        .set("GroupID", data.GroupID ? data.GroupID : "0")
        .set("Name", data.nameObj);
      return AxiosRequest(true, params);
    },
  };
  return requests.default;
}

export default function DialogCreateObj(props) {
  //отрисовка дерева
  const [tree, setTree] = React.useState();
  //хук выбора элемента дерева
  const [groupId, setGroupID] = React.useState();
  //введенное имя объекта
  const [nameObj, setNameObj] = React.useState(props.inputNameObj);

  function handleKeyDown(ev) {
    if (document.body.contains(ev.target)) {
      switch (ev.key) {
        case "Enter":
          onEdit();
          break;
        case "Escape":
          closeModal();
          break;
        default:
          break;
      }
    }
  }

  React.useEffect(() => {
    if (props.groups)
      setTree(
        <TestTree
          props={{
            CLSID: props.CLSID,
            multiCheck: false,
            ObjType: props.groups.ObjType,
            Module: props.groups.Module,
            ObjRef: props.groups.ObjRef ? props.groups.ObjRef : "0",
            UsedDate: "0",
            selectID: setGroupID,
          }}
        />
      );
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  async function onEdit() {
    await getCreateObject()({
      Module: props.Module,
      ObjType: props.ObjType,
      GroupID: groupId,
      nameObj: nameObj,
    }).then((res) => {
      if (props.onEdit)
        props.onEdit({
          record: props.record,
          textchanged: "0",
          tag: res.ObjRef,
          requestId: props.RequestID,
          CheckState: "0",
        });
      closeModal();
    });
    return;
  }

  return (
    <>
      <Box style={{ width: "100%", height: "calc(100% - 40px)" }}>
        <Grid item style={{ margin: "8px", width: "calc(100% - 10px)" }}>
          <span style={{ width: "46px" }}>
            {props.spanText === undefined
              ? "Наименование объекта:"
              : props.spanText}
          </span>
          <TextField
            size="small"
            id="name-object"
            autoComplete="off"
            variant="outlined"
            defaultValue={nameObj}
            fullWidth={true}
            error={nameObj === "" ? true : false}
            helperText={nameObj === "" ? "Не указано наименование объекта" : ""}
            onChange={(ev) => {
              setNameObj(ev.target.value);
            }}
          />
        </Grid>
        {props.groups ? (
          <Grid item style={{ width: "100%", height: "73%" }}>
            {tree}
          </Grid>
        ) : null}
      </Box>
      <Box style={{ height: "10px", width: "100%" }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            size="small"
            variant="outlined"
            style={{ textTransform: "none", marginRight: "10px" }}
            value="1"
            className="button_Modal_Select"
            disabled={nameObj === "" ? true : false}
            onClick={(ev) => {
              onEdit();
            }}
          >
            Выбрать
          </Button>
          <Button
            size="small"
            variant="outlined"
            style={{ textTransform: "none" }}
            value="2"
            className="button_Modal_Close"
            onClick={() => closeModal()}
          >
            Отмена
          </Button>
        </Grid>
      </Box>
    </>
  );
}
