import { XMLrequest } from "../../../../Url";

export async function organizationsGrid(grid, source, props, data) {
  // loadBookState(props.Module);
  // source.onHandleRequest = handleTable(
  //   props.Module,
  //   props.ObjType,
  //   data.GroupID,
  //   data.selectedRecordID,
  //   props.SectionID
  // );
  // source.open();
  // if (data.ReturnRecord) {
  //   source.onRecordIndexChanged = function (source) {
  //     data.ReturnRecord(source.getFieldText("ID"));
  //   };
  // }
  // grid.updateGridSize();
}

function loadBookState(module) {
  const params = new Map();
  params.set("prefix", module).set("comand", "LoadBookState");
  return XMLrequest(params);
}
