import React from "react";
import { Button, Grid } from "@mui/material";
import SectionDocuments from "../../Sections/ElementsSections/SectionDocuments/SectionDocuments";
import { InputButton } from "../../../TokenProcessing/TokenProcessing";
import { formEdit } from "../../Tools/Tools";
import { closeModal } from "../../Tools/modalManager";

export default function DialogDoc({ props }) {
  const buttonsRef = React.useRef();
  const documents = React.useRef();

  async function onSelect() {
    const gridObject = documents.current.querySelector("[rcsgrid_panel]").grid;
    if (!gridObject) return;
    if (props.onEdit && props.setValue && props.setInputValue) {
      props.onEdit({
        value: "",
        tag: gridObject.source.getFieldTextSync("ID"),
        name: props.Name,
        record: props.record,
        textchanged: "0",
        setValue: props.setValue,
        setInputValue: props.setInputValue,
        type: props.Module,
        requestId: props.RequestID,
        addInfo: props.addInfo,
        docID: true,
      });
      if (props.NewModalWindow) closeModal();
      return;
    }

    if (props.RequestID) {
      await formEdit(
        "1",
        props.func,
        { Name: "ObjRef", Value: gridObject.source.getFieldTextSync("ID") },
        props.RequestID,
        props.from
      );
    }
  }
  return (
    <>
      {
        //главный контейнер
      }
      <Grid
        id={"mainElement"}
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        style={{ position: "relative", height: "100%" }}
      >
        {
          //Шапка
        }
        <Grid
          item
          style={{
            border: "1px solid #C8B58F",
            height: "40px",
          }}
        >
          <Grid
            id={"mainElement"}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{ position: "relative", height: "100%" }}
          >
            {
              //кнопки шапки
            }

            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              ref={buttonsRef}
              style={{ width: "100%", height: "100%" }}
            ></Grid>
          </Grid>
        </Grid>
        {
          //Контейнер с разделителями
        }
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          style={{
            height: "calc(100% - 40px - 42px - 21px)",
            width: "100%",
          }}
        >
          {
            <Grid
              style={{ height: "100%" }}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                ref={documents}
                style={{
                  height: "100%",
                  width: "100%",
                  borderLeft: "1px solid #C8B58F",
                  borderRight: "1px solid #C8B58F",
                }}
                item
              >
                <SectionDocuments
                  id={props.SectionID}
                  DocCfgID={props.DocCfgID ? props.DocCfgID : props.ObjType}
                  HideParams={
                    props.CLSID === "{03338F11-FC1E-11D4-A605-0000E8D9CBDB}"
                      ? false
                      : true
                  }
                  SectionToolsJS={false}
                  PlaneView={props.PlaneView}
                  collapsedParams={props.PlaneView === "1" ? true : false}
                  setRecorcCount={() => {}}
                  footerID={`footerData_${props.SectionID}`}
                  buttonsContainerRef={buttonsRef}
                  disiableState={props.disiableState}
                  ObjRef={
                    props.ObjRef
                      ? props.ObjRef
                      : props.DocID
                      ? props.DocID
                      : undefined
                  }
                  tree={{
                    CLSID: props.CLSID,
                    multiCheck: false,
                    Module: props.Module,
                    ObjType: props.ObjType,
                    SectionID: props.SectionID,
                    ObjRef: props.ObjRef
                      ? props.ObjRef
                      : props.DocID
                      ? props.DocID
                      : "0",
                    Info: "0",
                    UsedDate: "0",
                    Internal: "0",
                  }}
                  // updateTree={setTree}
                />
              </Grid>
            </Grid>
          }
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            style={{
              height: "42px",
              width: "100%",
              border: "1px solid #C8B58F",
              display: props.hiddenFutter ? "none" : "",
            }}
          >
            <Grid item style={{ width: "fit-content", marginRight: "5px" }}>
              <Grid
                container
                item
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                style={{ height: "100%", width: "100%" }}
              >
                {/* {Кнопка Выбрать} */}
                <Button
                  onClick={onSelect}
                  size="small"
                  variant="outlined"
                  style={{
                    textTransform: "none",
                    marginRight: "10px",
                    display:
                      props.Buttons && (props.Buttons & 1) === 0 ? "none" : "",
                  }}
                  value="1"
                  className="button_Modal_Select"
                >
                  Выбрать
                </Button>
                {/* {кнопка Закрыть} */}
                <Button
                  size="small"
                  variant="outlined"
                  style={{ textTransform: "none" }}
                  value="2"
                  className="button_Modal_Close"
                  onClick={() => {
                    if (props.NewModalWindow) closeModal();
                  }}
                >
                  Закрыть
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            id={`footerData_${props.SectionID}`}
            className="css-11vzlf0-MuiGrid-root"
            style={{
              width: "100%",
              paddingTop: "2px",
              paddingLeft: "15px",
              border: "1px solid #C8B58F",
              borderTop: "0px",
              height: "21px",
            }}
          ></Grid>
        </Grid>
      </Grid>
    </>
  );
}
